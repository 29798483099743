.videoIcon{
    font-size: 50px !important;
    color: #272262;
}

.reactPlayerContainer{
    object-fit: contain;
    width: 100%;
    height: 100%;
    position: relative;
    padding-top: 56.25%;
}

.reactPlayer{
    position: absolute;
    top: 0;
    left: 0;
    object-fit: contain;
    border-radius: 8px;

}