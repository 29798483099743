.ReactPlayer{
    width: 33.33%;
    height: 180px;
}

.uploadVideoContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    background-color: darkgray;
    height: 180px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
}

.circleIcon{
    height: 32px;
    width:32px;
}

.RemoveVideoText{
    color: red;
    text-align: center;
    cursor: pointer;
}