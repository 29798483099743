$primary: #272262; // Please also change primary color in config.js
$secondary-color: #2C2C2B;
$alternate-color: #0034BB;
$border-focus: lighten($primary,20%);
$hover-light-primary: #d6d3fc;
$profile-upload-bg: #E8E6F7;
$profile-upload-border: #FF961E;
$profile-border: #FF961E;
$black: rgb(0,0,0);
$white: rgb(255,255,255);
$bg-header : #fafafa;
$shadow-grey: #f1f0f0;
$light-grey: #a2a0a0;
$text-grey: #929191;
$text-black: #000000;
$icon-active :#87BF40;
$color-warning: #F08C21;
$bg-grey: #919191;
$bg-red: #DD2741;
$bg-blue: #4A7FD0;
$primary-color: #3D3C40;
$light-black: #606060;
$camera-icon: #1A4445;
$border-color: #cacaca;
$chip-bg-grey: #E7E7E7;
$text-black-muted: #444;
$notification-color: #E71B1B;

$table-row-bg-color:#F6F6F6;
$table-td-border-color:#d6d6d6;
$card-bg-white: #FDFDFD;
$search-border: #E6E6E6;
$bg-light-grey: #F5F5F5;
$color-blue: #216eb6;
$dark-orchid:#812FC2;
$chips-light: #F7F7F7;
$radio-inactive: #939393;
$stars-color: #FAB12C;

$font-family-base: 'Inter', sans-serif;
$font-strongbold: 'Inter_black';
$font-bold: 'Inter_bold';
$font-italic-bold: 'Inter_bold_italic';
$font-medium: 'Inter_med';
$font-light: 'Inter_light';
$font-thin: 'Inter_thin_extra';
$font-regular-italic: 'Inter_italic';
$font-light-italic: 'Inter_light_italic';
$font-semi-bold: 'Inter_semi_bold';
$font-med-italic: 'Inter_med_italic';
$font-placeholder: $font-light-italic;

//media query breakpoints
$mobile: 575px;
$tab: 768px;
$hd-tab: 1199px;
$fhd-tab: 1199px;
$desktop: 1200px;
$hd-desktop: 1440px;
$desktop-lg: 1300px;

$btn-lg-size: 16px;
$btn-lg-height: 58px;
$btn-lg-letter-spacing: 1px;

$phoneCountry-margin: 14px;
$input-border-radius: 6px;
$input-border-color: #cacaca;
$input-box-shadow: 0 0 0 0 rgba(60, 127, 209, 0);
$input-height: 50px;
$input-text: 18px;
$input-placeholder-text: 16px;
$input-padding-x: 0.75rem;
$input-padding-y: 0.375rem;
$input-phone-padding: $input-padding-x;

$btn-border-radius: 2px;
$btn-text-transform: capitalize;
$btn-box-shadow: none;

$form-bottom-margin: 16px;
$padding-country-code: 6px 12px;

//changing in PWA
$pwa-input-border-radius: 15px;
$pwa-input-border-color: #D6D4E8;
$pwa-input-box-shadow: 0 12px 19px 0 rgba(60,128,209,0.09);
$pwa-input-height: 70px;
$pwa-input-text: 20px;
$pwa-input-placeholder-text: 20px;
$pwa-input-padding-x: 1.5rem;
$pwa-input-padding-y: 1.875rem;

$pwa-btn-border-radius: 25px;
$pwa-btn-text-transform: uppercase;
$pwa-btn-box-shadow: 0 4px 8px 0 rgba(84,104,255,0.30);

$pwa-form-bottom-margin: 20px;
$pwa-padding-country-code: 6px 10px;
$pwa-sub-cat-margin: -23px 0 0 -11px;
$pwa-error-margin: 10px;

$pwa-select-arrow-position: calc(100% - 20px) calc(1em + 12px),calc(100% - 15px) calc(1em + 2px),calc(100% - .5em) .5em;
$pwa-select-arrow-size: 20px;

$pwa-web-placeholder-text: 18px;

$nav-item-border-color: #C6C5DB;

$light-grey-bg: #E8E6F7;

$sh-blue:#d9edf6;
$sh-text-dark:#4b4b4b;