.search-item {
  margin-top: 6px;

  .shar-search {
    padding-left: 40px;
  }

  .icon-position {
    position: absolute;
    left: 12px;
    top: 2px;
    color: #848282;
    font-size: 20px;
  }
}

.tool {
  &-info {
    max-width: 160px;
  }

  &-image {
    img {
      width: 110px;
      height: 110px;
      object-fit: contain;
    }
  }

  &-search-container {
    @media (max-width: $mobile) {
      width: 100%;
      justify-content: center;
      padding: 16px 0 20px;
      position: relative;

      .side-modal-close {
        position: absolute;
        right: 0;
        top: -45px;
      }
    }
  }
}

.download-white-icon {
  transform: scale(0.2);
  position: absolute;
  top: -30px;
  margin-left: 0 !important;
  left: -24px;
  
  stop {
    stop-color: currentColor;
  }
}

.btn.btn-with-download-icon {
  min-width: 250px !important;
}