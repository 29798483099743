.overview {
  color: $primary-color;

  .font-16-medium {
    font-size: 16px;
    font-family: $font-medium;
  }

  .clickable{
    .card-body {
      padding-right: 38px;
      cursor: pointer;
    }
  }

  .font-16-light-italic {
    font-size: 16px;
    font-family: $font-light-italic;
  }

  .font-20-bold {
    @extend .display-6;
    font-family: $font-bold;
  }

  .font-20-medium {
    @extend .display-6;
    font-family: $font-medium;
  }

  .text-revenue {
    font-size: 21px;
    font-family:$font-bold;
    color: #28A569;
  }

  select {
   -webkit-appearance: none;
   -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    border-radius: 6px;
  }

  select.round {
    background-image: url(../assets/images/chevron_down.svg);
    background-position:
    calc(100% - 20px) calc(.8em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - .5em) .5em;
    background-size: 12px;
    margin-right: 30px;
    background-repeat: no-repeat;
  }

  select.round:focus {
    background-image: url(../assets/images/chevron_up.svg);
    background-position:
    calc(100% - 20px) calc(.8em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - .5em) .5em;
    background-size: 12px;
    background-repeat: no-repeat;
  }

  .card {
    border: 1px solid #F3F4F6;
    border-radius: 8px;
    box-shadow: 0px 2px 4px #bcbdbf;

    .card-body {
      padding-right: 38px;
    }
  }

  @media screen and (max-width: 360px) {
    .card-body {
      padding-right: 20px;
    }
  }
}

.icon-body {
  margin: 8px 8px;
}

.overview-cards-data {
  max-width: 900px;
}
