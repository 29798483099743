.sign-upform {
	font-family: $font-medium;
	.mt-58 {
		margin-top: 58px;
	}

	.font-28 {
		font-size: 28px;
	}

	.font-24 {
		font-size: 24px;
	}

	.text-black-500 {
		color: #1b4546;
	}

	.text-grey {
		color: #819a9f;
	}

	.form-control {
		border-width: 2px;
		border-style: solid;
		border-color: rgba(129, 154, 159, 0.4);
		color: #495057;

		&:focus {
			color: #495057;
			border: 2px  solid #2cc3f1;
			background-color: white;
			outline: 0;
			box-shadow: none;
		}
	}

	.checkbox-position {
		position: relative;
		top: -2px;
		left: 19px;
	}

	.checkbox-text-info {
		line-height: 1.8;
		color: #819a9f;
		color: #819a9f;
	}

	.text-url {
		text-decoration: none;
		color: #2ac4f3;
		font-style: italic;
		&:hover {
			text-decoration: underline;
		}
	}

	.btn:not(:disabled):not(.disabled) {
		cursor: pointer;
	}

	.btn-primary {
		&:hover {
			-webkit-box-shadow: 1px 1px 2px #ccc;
			box-shadow: 1px 1px 2px #ccc;
			border-color: transparent;
		}
	}

	.influence-image {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-end;
		justify-content: center;
	}

	::placeholder {
		color: #819a9f;
	}

	.dashboard-pagination li {
		width: 15px;
		height: 15px;
		background: transparent;
		border: 3px solid #e6e4e4;
		display: inline-block;
		border-radius: 50%;
		cursor: pointer;
		margin: 0 4px;
	}

	.dashboard-pagination li:hover {
		border-color: #ef8b20;
	}

	.dashboard-pagination .active {
		background-color: #ef8b20;
		border-color: #ef8b20;
	}

	.client-goals {
		.goals-heading {
			font-family: $font-bold;
			font-size: 20px;
		}
	}

	.multiple-select .multi-header {
		border: 2px solid #efefef;
	}

	.multiple-select .multi-body {
		border: 2px solid #efefef;
		border-top: none;
	}

	.multiple-select .multi-body li {
		color: #999;
		font-size: 14px;
		padding-bottom: 5px;
		position: relative;
		padding-left: 25px;

	}

	.camel-case {
		text-transform: capitalize;
	}

	.multiple-select .ml-scroll {
		max-height: 280px;
		overflow: auto;
	}

	.multiple-select .multi-header li {
		display: inline-block;
		vertical-align: middle;
		margin: 2px;
	}

	.multiple-select .multi-header li .ml-list {
		display: inline-block;
		padding: 1px 7px;
		background: #26bde2;
		color: #fff;
		box-shadow: inset 1px 1px 2px #18b2d8, inset -1px -1px 2px #18b2d8;
		border: 1px solid #18b2d8;
		color: rgba(255, 255, 255, 0.76);
		font-size: 13px;
		border-radius: 4px;
		line-height: 20px;
	}

	.multiple-select .ml-close {
		color: #fff;
		font-size: 18px;
		margin-right: 10px;
	}

	.text-orange {
		color: #f18f2a;
	}

	.shar-list-new-view {

		.custom-control {
			padding-left: 0;
		}

		label[type="checkbox"] {
			background: $chips-light;
			padding: 12px 24px;
	    width: 100%;
			color: $text-black-muted;
			position: relative;
			border-radius: 4px;
			transition: 0.2s ease-in-out;

			&:after{
				display: none;
			}

			&:before {
				background-color: transparent;
				left: auto;
				left: auto;
		    right: 18px;
		    top: 18px;
		    width: 14px;
		    height: 14px;
		    border: 5px solid $radio-inactive;
				transition: 0.2s ease-in-out;
			}
		}

		.custom-control-input[type="radio"]:checked {
			+ label{
				background: $primary;
				color: $white;

				&:before {
					border-color: currentColor;
				}
			}
		}
	}

}

.fade-in{
	animation: fadeinAnimation linear 1s;
}

@-webkit-keyframes fadeinAnimation {
	0%   { opacity: 0; }
	100% { opacity: 1; }
}

@-moz-keyframes fadeinAnimation {
	0%   { opacity: 0; }
	100% { opacity: 1; }
}

@-o-keyframes fadeinAnimation{
	0%   { opacity: 0; }
	100% { opacity: 1; }
}

@keyframes fadeinAnimation {
	0%   { opacity: 0; }
	100% { opacity: 1; }
}


div.m-auto.pb-4 {
	text-align: center;

	img[alt="partner-logo"] {
		max-width: 310px;
	}
}

.register-form-padding {
	padding-right: 80px;

	@media (max-width: $hd-tab) {
		padding: 25px;
	}
}

.web-otp-inputs {
	max-width: 420px;

	.form-control {
		max-width: 56px;
		text-align: center;
		margin: 0 10px 20px 0;
	}
}

.cursor-pointer {
	cursor: pointer;
}
