.new-landing-theme {
  .w-75 {
   width: 75%;
  }

  .text-orange {
    color: #f18f2a;
  }

  .welcome-font {
    @extend .display-4;
    font-family: $font-family-base;
  }

  .mxh-70 {
    max-height: 70px;
  }

  .mxw-65 {
    width: 65%;
  }

  .founder-names {
    @extend .text-grey;
    font-family: $font-italic-bold;
  }

  .text-grey {
    color: #819a9f;
  }

  ~ .form-element-outer + .form-group {
    width: 325px;
    text-align: left;
    margin: auto;
    margin-top: 30px;
  }
}
