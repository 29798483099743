.shar-modal {

  .modal-lg{
    max-width: 650px;
  }
  .close {
    position: absolute;
    right: 16px;
    padding: 0 10px;
    font-size: 40px;
    font-weight: 100;
    opacity: 0.8;
  }

  .upload-plus-icon {
    border: 2px solid $black;
    width: 20px;
    height: 20px;

    &:after {
      content: '+';
      font-size: 20px;
      position: absolute;
      top: -8px;
      right: 2px;
    }
  }

  .shar-img-upload {
    width: 100%;
    min-height: 120px;
    border-radius: 8px;
    background-image: repeating-linear-gradient(to right, $border-color 0%, $border-color 50%, transparent 50%, transparent 100%),
                      repeating-linear-gradient(to right, $border-color 0%, $border-color 50%, transparent 50%, transparent 100%),
                      repeating-linear-gradient(to bottom, $border-color 0%, $border-color 50%, transparent 50%, transparent 100%),
                      repeating-linear-gradient(to bottom, $border-color 0%, $border-color 50%, transparent 50%, transparent 100%);
    background-position: left top, left bottom, left top, right top;
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 16px 1px, 16px 1px, 1px 16px, 1px 16px;
  }

  .upload-drag-file {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  .photo-thumb {
    width: 75px;
    height: 75px;
    background: $chip-bg-grey;

    img {
      max-width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .more-btn {
    button {
      padding: 0;
    }
  }

  .edit-img {
    img {
      width: 190px;
      height: 190px;
      object-fit: cover;
    }
  }

  .resizer-slider {
    width: 300;
    padding: '22px 0px';
  }
}
