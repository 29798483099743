.display-3 {
  font-size: 2.5rem; //40px
}

.display-4 {
  font-size: 2.25rem; //36px
}

.display-5 {
  font-size: 1.5rem; //24px
}

// creating display classes from 20 px to 10px  (use 6 to 11 postfix to use these)
$size: 1.25;

@for $i from 6 through 11 {
  .display-#{$i} {
    //.display-6{
    font-size: #{$size}rem !important; //   font-size: 1.25 rem (20px);
    $size: $size - 0.125; //   1.25rem - 2px;
  }
}

//keeping these classes beacuse already used in several files
.font-14 {
  @extend .display-9;
}

.font-12 {
  @extend .display-10;
}

.font-22 {
  font-size: 1.375rem;
}

.font-20 {
  font-size: 1rem;
}

.font-15 {
  font-size: 0.9375rem !important;
}

.font-13 {
  font-size: 0.8125rem;
}

.font-45 {
  font-size: 2.8125rem;
}

//creating font with bold classes

$sizes: 32, 30, 21, 20, 18, 16, 28, 24, 11;

@each $size in $sizes {
  .font-#{$size}-bold {
    font-size: #{$size}px;
    @extend .font-bold;
  }
}

//creating font with medium classes from 18 to 14 classes
$font: 18;
@for $i from 1 through 3 {
  .font-#{$font}-medium {
    font-size: #{$font}px;
    @extend .font-medium;
    $font: $font - 2;
  }
}

.font-16-light-italic {
  @extend .display-8;
}

.font-16-regular {
  @extend .display-8;
  @extend .font-regular;
}

.font-regular {
  font-family: $font-family-base !important;
}

.font-medium {
  font-family: $font-medium;
}

.font-bold {
  font-family: $font-bold !important;
}

.font-light {
  font-family: $font-light !important;
}

.font-light-italic {
  font-family: $font-light-italic;
}

.font-italic {
  font-family: $font-regular-italic;
}

.font-italic-bold {
  font-family: $font-italic-bold;
}

.font-semi-bold {
  font-family: $font-semi-bold;
}

.font-medium-italic {
  font-family: $font-med-italic !important;
}

h1,
h2,
h3,
h4,
h6 {
  font-family: $font-bold;
}

h5 {
  font-family: $font-medium;
}

.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 200;
}

.w-50 {
  width: 50%;
}

.w-40 {
  width: 40% !important;
}

.h-70 {
  height: 70%;
}

.d-flex-align-center {
  @extend .d-flex;
  @extend .align-items-center;
  @extend .justify-content-center;
}

.color-primary {
  color: $primary-color;
}

.text-black {
  color: $text-black;
}

.icon-active {
  height: 9px;
  width: 9px;
  background-color: $icon-active;
  border-radius: 50%;
}

.icon-offline {
  height: 9px;
  width: 9px;
  background-color: #dad7d7;
  border-radius: 50%;
}

.text-offline {
  color: #d6d5d5;
}

.text-networks {
  color: $primary-color;
}

.text-light-grey {
  color: $light-grey;
}

.text-black-muted {
  color: $text-black-muted;
}

.text-light-black {
  color: $light-black;
}

.text-sm-secondary {
  color: $secondary-color;
}

.round-circle-sm {
  top: -3px;
  right: -6px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: $color-warning;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $font-bold;
  @media (max-width: $mobile) {
    width: 15px;
    height: 15px;
    font-size: 0.625rem; //10px;
  }
}

.sm-round-circle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: $secondary-color;
}

.round-circle {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: $secondary-color;
  @media (max-width: $hd-desktop) {
    width: 30px;
    height: 30px;
  }
}

.round-circle-medium {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #1a4445;
  max-width: 50px;
  min-height: 50px;
  object-fit: cover;

  @media (max-width: $hd-desktop) {
    width: 44px;
    height: 44px;
    max-width: 44px;
    min-height: 44px;
  }

  @media (max-width: $mobile) {
    width: 35px;
    height: 35px;
    max-width: 35px;
    min-height: 35px;
  }
}

.chat-circle-medium {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: #1a4445;
}

.bg-grey {
  background-color: $bg-grey;
}

.bg-red {
  background-color: $bg-red;
}

.bg-blue {
  background-color: $bg-blue;
}

.bg-alternate {
  background-color: $alternate-color;
}

.min-h {
  min-height: calc(100vh - 96px);
}

.error {
  font-size: 16px;
  margin-top: 5px;
  color: #bf071c;
  margin-left: $pwa-error-margin;

  @media (min-width: $mobile) {
    max-width: 325px;
  }
}
@media (min-width: $hd-tab) {
  .scroll-y::-webkit-scrollbar {
    width: 0.4em;
  }

  .scroll-y::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .scroll-y::-webkit-scrollbar-thumb {
    background-color: $primary;
    outline: 1px solid #ccc;
  }
}

.scroll-y {
  overflow: auto;
}

@media (min-width: 992px) {
  .h-lg-100 {
    height: 100%;
  }
}

.blue-primary {
  color: $primary;
}

.p-20 {
  @include padding(20px, 20px, 20px, 20px);
}

.width-30 {
  width: 30px;
  height: 30px;
}

.width-20 {
  width: 20px;
  height: 20px;
}

.width-10 {
  width: 10px;
  height: 10px;
}

//Decrease sizes of font for smaller devices
@media (max-width: $mobile) {
  h2 {
    font-size: 1.5rem;
  }
  .font-20-bold {
    font-size: 1rem;
  }

  .display-sm-7 {
    font-size: 1.25rem;
  }

  .display-sm-8 {
    font-size: 1rem;
  }

  .display-sm-9 {
    font-size: 0.875rem;
  }
}

@media (max-width: $hd-tab) {
  .display-md-6 {
    font-size: 1.25rem !important;
  }
}

//fonts for larger devices

@media (min-width: $hd-desktop) {
  .display-xl-5 {
    font-size: 1.5rem;
  }

  .display-xl-6 {
    font-size: 1.25rem;
  }

  .display-xl-7 {
    font-size: 1.125rem;
  }

  .display-xl-8 {
    font-size: 1rem;
  }
}

.network-item-header {
  .font-30-bold {
    @media (max-width: $hd-desktop) {
      font-size: 1.625rem;
    }

    @media (max-width: $mobile) {
      font-size: 1.5rem;
    }
  }
}

.link-primary {
  color: $primary !important;
  transition: 0.2s ease-in-out;
  opacity: 1 !important;

  &:hover {
    color: $alternate-color !important;
  }
}

.link-alternate {
  color: $alternate-color;
  transition: 0.2s ease-in-out;

  &:hover {
    color: $primary;
  }
}

.font-30-bold {
  @media (max-width: $mobile) {
    font-size: 1.5rem;
  }
}

.primary-link {
  &:hover {
    color: $primary;
  }
}

.my-55 {
  margin: 5rem 0 5rem 0;
}
