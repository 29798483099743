@mixin for-tablet-portrait-up {
  @media (max-width: 1200px) { @content; }
}
.pwa-menu {
    &,
    &-btn {
        display: none;
    }
}

.dashboard {
  &-header {
    &-outer {
      background-image: url(../assets/images/profile/bg-profile.jpg);
      background-size: cover;
      background-color: rgba(0, 0, 0, 0.4);
      background-blend-mode: color;
    }

    &-img {
      img {
        min-width: 55px;
        height: 55px;
        border: 5px solid $primary;
      }
    }
  }

  &-card {
    background-image: linear-gradient(200deg, rgba(13,47,90,0.62) 64%, rgba(45,45,45,0.30) 97%);

    &-img {
      min-width: 40px;
      width: 40px;
    }
  }
}

.icon-heading-img {
    width: 40px;

  &-add {
    width: 16px;
  }
}

.community-card {
  width: 190px;
  box-shadow: 0 4px 4px 2px rgba(69,69,69,0.20);
  border-radius: 8px;
  border-top: 5px solid $primary;

  &-img {
    width: 75px;
    height: 75px;
    border-radius: 50%;
  }
}

@media (max-width: $fhd-tab) {
  body {
      .header {
          background-color: $primary;
          border-radius: 0 0 1rem 1rem;
          box-shadow: none;
          border: none;
      }

      .dashboard-page-container {
          padding: 1rem;
      }

      .dashboard-header-outer {
          border-radius: 15px;
          margin-bottom: 1rem;
          box-shadow: 2px 9px 13px 0 rgba(39,34,98,0.22);
      }

      .navbar-toggler {
          border: none;
      }

      .navbar-dark .navbar-toggler-icon {
        filter: invert(1);
        height: 20px;
        position: relative;
        top: 4px;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='15' xmlns:v='https://vecta.io/nano'%3E%3Cpath d='M.75 8.75h17.5v-2.5H.75v2.5zm0 6.25h17.5v-2.5H.75V15zm0-15v2.5h17.5V0H.75z' fill='%23fff'/%3E%3C/svg%3E");
      }

      .dashboard-card {
          min-width: 300px;
          border-radius: 15px;
      }

      .community-card {
          min-width: 200px;
      }

      .pwa-menu {
          display: block;
          position: fixed;
          width: 100%;
          bottom: 0;
          left: 0;
          padding: 10px;
          background: #272262;
          box-shadow: 0 5px 26px 0 rgba(39,34,98,0.48);
          // border-radius: 15px 15px 0 0;
          z-index: 100;
          transition: 0.3s ease-in-out;
          &-icon {
              img {
                  width: 25px;
              }
          }

          &-btn {
              display: block;
          }

          &.footer-unpin {
            transform: translateY(100%);
          }
      }

      .sidebar {
          box-shadow: none;
          margin-top: -1px;
          height: calc(100% - 47px);
          background: rgba(0, 0, 0, 0);
      }

      .side-navbar {
        background-color: #fff;

        .text-dark {
          color: #000 !important;
        }

        li {
          &:first-child {
            span {
              position: relative;
              top: 4px;
            }
          }
          &.active {
            .text-dark {
              color: $primary !important;
            }
            
            a .fill-black {
              fill: $primary !important;
            }
          } 
        }
      }
  }
}

@media (max-width: $hd-tab) {
  body {
      .header {
          background-color: $primary;
          border-radius: 0 0 1rem 1rem;
          box-shadow: none;
          border: none;
          @include for-tablet-portrait-up{
            border-radius: 0px;
          }
      }

      .dashboard-page-container {
          padding: 1rem;
      }

      .dashboard-header-outer {
          border-radius: 15px;
          margin-bottom: 1rem;
          box-shadow: 2px 9px 13px 0 rgba(39,34,98,0.22);
      }

      .navbar-toggler {
          border: none;
      }

      .navbar-dark .navbar-toggler-icon {
        filter: invert(1);
        height: 20px;
        position: relative;
        top: 4px;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='15' xmlns:v='https://vecta.io/nano'%3E%3Cpath d='M.75 8.75h17.5v-2.5H.75v2.5zm0 6.25h17.5v-2.5H.75V15zm0-15v2.5h17.5V0H.75z' fill='%23fff'/%3E%3C/svg%3E");
      }

      .dashboard-card {
          min-width: 300px;
          border-radius: 15px;
      }

      .community-card {
          min-width: 200px;
      }

      .pwa-menu {
          display: block;
          position: fixed;
          width: 100%;
          bottom: 0;
          left: 0;
          padding: 10px;
          background: #FFFFFF;
          box-shadow: 0 5px 26px 0 rgba(39,34,98,0.48);
          // border-radius: 15px 15px 0 0;
          z-index: 100;
          transition: 0.3s ease-in-out;
          &-icon {
              img {
                  width: 25px;
              }
          }

          &-btn {
              display: block;
          }

          &.footer-unpin {
            transform: translateY(100%);
          }
      }

      .sidebar {
          box-shadow: none;
          margin-top: -1px;
          height: calc(100% - 47px);
          background: rgba(0, 0, 0, 0);
      }

      .side-navbar {
        background-color: #fff;

        .text-dark {
          color: #000 !important;
        }

        li {
          &:first-child {
            span {
              position: relative;
              top: 4px;
            }
          }
          &.active {
            .text-dark {
              color: $primary !important;
            }
            
            a .fill-black {
              fill: $primary !important;
            }
          } 
        }
      }
  }
}

@media (max-width: $fhd-tab) {
  .pwa-menu-position {
    > .container-fluid {
      margin-top: -14px;
    }
  }
}

@media (max-width: $hd-tab) {
  .pwa-menu-position {
    > .container-fluid {
      margin-top: -14px;
    }
  }
}



.pwa-profile-comm-icons {
  min-height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mr-last-4:not(:last-child) {
  margin-right: 24px;
}
