@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Tangerine");

@mixin for-tablet-portrait-up {
  @media (max-width: 1200px) {
    @content;
  }
}

$grey-100: #cbcbf1;
$body-text: #393026;
$header-text: #383838;
$text-color: #272262;

.left-0 {
  left: 0 !important;
}

.top-65 {
  top: 65%;
}

.mt-7px {
  margin-top: 7px;
}

.mt-13px {
  margin-top: 13px;
}

.mr-13px {
  margin-right: 13px;
}

.b-300 {
  font-weight: 300;
}

.b-400 {
  font-weight: 400;
}

.b-500 {
  font-weight: 500;
}

.b-600 {
  font-weight: 600;
}

.b-700 {
  font-weight: 700;
}

.b-900 {
  font-weight: 900;
}

.font-45 {
  font-size: 2.8125rem;

  @media (max-width: 767px) {
    font-size: 1.8rem;
  }
}

.font-70 {
  font-size: 4.5rem;
  line-height: 1;

  @media (max-width: 102467px) {
    font-size: 3rem;
  }

  @media (max-width: 767px) {
    font-size: 2.5rem;
  }
}

.gil-grey-bg {
  background-color: $grey-100;
}

.gil-header {
  nav.shar-navbar {
    background-color: transparent;

    @media (min-width: 1200px) {
      box-shadow: none;
    }

    ul {
      li {
        a {
          color: $body-text;
          font-size: 1rem;

          &:hover {
            color: $primary;
          }

          .nav-link-active {
            color: $primary !important;
          }
        }

        a.mr-xl-4.mr-lg-3 {
          @media (min-width: 1280px) {
            margin-right: 2.8125rem !important;
          }
        }
      }
    }

    @media (max-width: 991px) {
      .navbar-outer {
        border-color: $color-warning;
      }
    }
  }

  .btn-getstarted {
    min-width: 157px;
  }
}

.dot-list {
  list-style: none;

  li {
    position: relative;
    padding-left: 1rem;

    &::before {
      content: "";
      position: absolute;
      width: 6px;
      height: 6px;
      background-color: $primary;
      left: 0;
      border-radius: 50%;
      top: 7px;
    }
  }
}

.gil-main-container {
  * {
    font-family: "Roboto", sans-serif;
  }

  .gil-sub-title {
    @extend .display-8;
    @extend .b-700;
    color: $primary;

    @media (max-width: 767px) {
      font-size: 0.8rem !important;
    }
  }

  .gil-title {
    font-size: 2.8125rem;
    line-height: 1.2;
    @extend .b-700;

    @media (max-width: 1024px) {
      font-size: 2rem;
    }

    @media (max-width: 767px) {
      font-size: 1.6rem;
    }

    &-small {
      font-size: 24px;
      margin: 10px auto;
      padding-bottom: 40px;
      max-width: 850px;
      line-height: 1.3;
      font-weight: 500;
    }
  }

  .btn-inverce {
    color: $primary;

    &::after {
      transform: scaleY(1);
      transform-origin: bottom;
    }

    &:hover {
      color: $white !important;

      &::after {
        transform: scaleY(0);
      }
    }
  }

  .btn.btn-lg {
    border-radius: 2px;
    @extend .display-7;

    @media (min-width: 1280px) {
      height: 55px;
    }
  }

  .subtract {

    &-right-section,
    &-left-section {
      background-repeat: no-repeat;
      background-position: center 25px;
      background-size: contain;

      @media (min-width: 1680px) {
        background-size: 100%;
      }
    }

    &-left-section {
      background-image: url(../assets/images/gil/subtract-left.svg);
    }

    &-right-section {
      background-image: url(../assets/images/gil/subtract-right.svg);
    }
  }

  .card-radius {
    margin-left: auto;
    margin-right: auto;

    &.left-radius-0 {
      border-radius: 50% 50% 50% 0px;
    }

    &.top-radius-0 {
      border-radius: 0px 50% 50% 50%;
    }

    &.bottom-radius-0 {
      border-radius: 50% 50% 0px 50%;
    }

    &.right-radius-0 {
      border-radius: 50% 0px 50% 50%;
    }
  }

  .card-default-radius {
    @extend .card-radius;
    width: 120px;
    height: 120px;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .journey-pasport-section {
    color: $body-text;

    .journey-icon {
      width: 120px;
      height: 120px;
      line-height: 120px;
      background-color: $grey-100;
      @extend .card-radius;
    }
  }

  .gil-list {
    li {
      position: relative;
      padding-left: 35px;
      @extend .b-300;
      @extend .display-8;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 10px;
        width: 20px;
        height: 4px;
        background-color: $primary;
      }
    }
  }

  .peer-learning-card {
    border-radius: 10px;
    position: relative;

    img {
      border-radius: 10px;
    }

    .peer-learning-content {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: $white;
      transition: 0.3s ease-in-out;
      border-radius: 10px;

      .peer-learning-overlay {
        opacity: 0;
        transform-origin: bottom;
        transition: all ease-in-out 0.5s;
        height: 0px;
      }

      .display-5 {
        transition: all ease-in-out 0.5s;
      }
    }

    &:hover {
      .peer-learning-content {
        background-image: linear-gradient(0deg,
            rgba(39, 34, 98, 0.7),
            rgba(39, 34, 98, 0.7));

        .peer-learning-overlay {
          opacity: 1;
          height: 50%;

          @media (max-width: 767px) {
            height: 80%;
          }
        }

        .display-5 {
          font-size: 2.25rem;
        }
      }
    }
  }
}

.our-purpose-img {
  max-width: 580px;
  padding-right: 4rem;
  padding-bottom: 3.2rem;
  height: 100%;
  position: relative;
  border-radius: 0px 200px;
  background: url(../assets/images/gil/rectangle-dot.svg) no-repeat bottom right;
  min-height: 640px;

  img {
    border-radius: 0px 200px;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .our-purpose-caption {
    max-width: 380px;
    position: absolute;
    right: 25px;
    bottom: 7rem;
    background-color: $primary;
    color: $white;
    border-radius: 0px 40px;
    font-style: italic;
    padding: 2rem;
  }
}

.our-gil-section {
  .our-purpose-img {
    padding-right: 0;
    padding-left: 4rem;
    background-position: bottom left;
    border-radius: 200px 0px;

    img {
      border-radius: 200px 0px;
    }

    .our-purpose-caption {
      left: 25px;
      border-radius: 40px 0;
    }
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba($primary, 0.7);
  }

  70% {
    box-shadow: 0 0 0 15px rgba($primary, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba($primary, 0);
  }
}

.play-section {
  .btn-play {
    width: 62px;
    height: 62px;
    border-radius: 50%;
    box-shadow: 0 0 0 15px rgba($primary, 0.2);
    background-color: $primary;
    color: $white;
    border: none;
    animation: pulse 2s infinite;

    @media (max-width: 767px) {
      width: 45px;
      height: 45px;
      box-shadow: 0 0 0 10px rgba($primary, 0.2);
    }
  }

  .play-section-text {
    text-transform: uppercase;
    color: $primary;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: -15px;
      top: 12px;
      width: 50px;
      height: 1px;
      background-color: $primary;

      @media (max-width: 767px) {
        width: 35px;
        left: 0px;
      }
    }
  }
}

// Gil Hero Section
.gil-sec-hero {
  background-color: $grey-100;
  overflow: hidden;

  .display-5 {
    font-size: 1.6rem;
  }

  &.subtract-left-section {
    background-image: url(../assets/images/gil/subtract-left-2.svg);
  }

  .gil-mobile-view {
    position: relative;
    padding-bottom: 0rem;
    padding-left: 0rem;

    &::before {
      content: "";
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 100%;
      background-image: url(../assets/images/gil/rectangle-dot.svg);
      background-position: left 90%;
      background-repeat: no-repeat;
      left: -5%;
      bottom: -1.5rem;
    }

    .mobile-img-1 {
      position: relative;
      z-index: 2;
      left: -7%;
    }

    .overlay-mobile-img {
      position: absolute;
      top: 0;
      z-index: 0;
      right: -25%;
      width: 100%;
    }
  }
}

// Our Member Section
.our-member-section {
  background-color: $grey-100;

  .journey-icon {
    width: 200px;
    height: 200px;
    line-height: 200px;
    background: $white;
    @extend .card-radius;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .dot-bg {
    position: relative;

    &::before,
    &::after {
      content: "";
      position: absolute;
      background-image: url(../assets/images/gil/rectangle-dot-3.svg);
      background-repeat: no-repeat;
      width: 19%;
      height: 112%;
    }

    &::before {
      left: -8%;
      top: -30px;
    }

    &::after {
      right: -8%;
      top: -30%;
      z-index: -1;
    }
  }
}

// Leadership Section
.leadership-topics-sec {
  position: relative;
  background-color: $grey-100;

  .container {
    position: relative;
    z-index: 1;
    border-radius: 100px 0px;
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    height: 50%;
    background-color: $white;
    width: 100%;
  }
}

// Upcoming Section
.upcoming-event-sec {
  .card {
    box-shadow: 0px 0px 40px rgba($black, 0.05);
    border-radius: 10px;
    border: none;

    .event-date-field {
      color: #c4c4c4;
    }

    p {
      font-weight: bold;
      font-size: 20px;
      line-height: 1.3;
    }
  }
}

// Newsletter Section
.newsletter-panel {
  .form-control {
    background-color: transparent;
    border-radius: 5px 0 0 5px;
    height: 60px;
    color: $white;

    &:focus {
      border-color: $white;
    }

    &::placeholder {
      font-family: "Roboto", sans-serif;
      font-style: normal !important;
      color: $white;
    }
  }

  .btn {
    height: 60px;
    border-radius: 0 5px 5px 0;
    background-color: $white;
    color: $primary;
    width: 180px;
  }
}

// footer Section / start
footer {
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000000;
  overflow: hidden;

  .footer-nav {
    padding: 0px;
    margin: 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    li {
      a {
        padding-left: 1rem;
        padding-right: 1rem;
        color: white;
        font-weight: 300;
        line-height: 19px;
        opacity: 0.75;

        &:hover {
          text-decoration: underline;
          color: $primary;
        }
      }
    }
  }

  .copy-right-text {
    color: white;
    opacity: 0.75;
    font-family: sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
  }
}

.line-height-38 {
  line-height: 38px;
}

.text-transform-inh {
  text-transform: inherit;
}

.line-height-5 {
  line-height: 29px;
}

.video-fit-screen {
  video {
    height: calc(100vh - 85px) !important;
    margin-top: 8px;
  }
}

.landing-page-video-close {
  position: absolute;
  top: 90px;
  right: 20px;
  padding: 20px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.3);
}

.owl-dots {
  display: none;
}

.owl-nav {

  .owl-prev,
  .owl-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: 0px !important;
    z-index: 10;

    span {
      font-size: 60px;
      color: #26273b;
    }

    &:hover {
      background: transparent !important;
      border: none;
    }

    &:focus {
      outline: none;
    }
  }

  .owl-prev {
    left: -25px;
  }

  .owl-next {
    right: -25px;
  }
}

.btn {

  &.btn-login,
  &.btn-theme {
    min-height: 50px;
  }
}

.client-profile-toggle .btn-primary.dropdown-toggle[aria-expanded="false"] {
  // box-shadow: 1px 2px 6px 1px rgba(39, 34, 98, 0.22) !important;
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.5) !important;

  .toggle-icon img {
    filter: invert(1) brightness(2);
  }
}

.activeProfile {
  background-image: linear-gradient(180deg,
      #4f479b 0%,
      #272262 100%) !important;

  .btn-primary .toggle-icon {
    filter: none !important;
    color: #fff !important;
  }

  .client-profile-dropdown {
    color: #fff !important;

    .drop-userName {
      color: #272262;
    }
  }

  box-shadow: 1px 2px 6px 1px rgba(39, 34, 98, 0.22) !important;
}

.inActiveProfile {
  background-color: #fff;

  .client-profile-dropdown {
    color: #272262 !important;
  }

  .btn-primary .toggle-icon {
    filter: none !important;
    color: #272262 !important;
  }
}

.dropdown-toggle .client-profile-dropdown-img {
  box-shadow: none !important;
  border: 3px solid #ff9900;
}

.mcq-set-main {
  background-image: linear-gradient(180deg, #ffffff 0%, #909cd2 100%);

  .rounded-circle {
    background-image: linear-gradient(180deg, #77549f 0%, #422a66 100%);
  }
}

.mcq-set-light {
  background-image: linear-gradient(180deg, #77549f 0%, #909cd2 100%);
}

.mcq-set-dark {
  background-image: linear-gradient(180deg,
      #77549f 0%,
      #422a66 100%) !important;
  color: #fff !important;
}

.text-primary-light {
  color: #7919c5;
}

.download-btn-color-1 {
  stop-color: #77549f;
}

.download-btn-color-2 {
  stop-color: #422a66;
}

.correct-answer-bg {
  background-image: linear-gradient(180deg,
      #77549f 0%,
      #909cd2 100%) !important;
}

@media (max-width: 992px) {
  .profile-edit-verticle {
    top: 30px;
  }
}

.article-section-outer {
  .article-outer {
    .px-3.py-4 {
      display: flex;
      flex-direction: column;
      height: 100%;
      max-height: calc(100% - 115px);

      .d-flex.mt-3 {
        margin-top: auto !important;
        padding-top: 16px;
      }
    }
  }
}

.line-divider {
  max-width: 500px;
}

// @media (min-width: $fhd-tab) {
//   .notifications-outer {
//     margin-right: 390px;

//     .notifications-outer {
//       width: 440px;
//     }
//   }
// }

// @media (min-width: $hd-tab) {
//   .notifications-outer {
//     margin-right: 395px;

//     .notifications-outer {
//       width: 440px;
//     }
//   }

// }

.page-sidebar-head {
  padding: 16px 12px;
}

.sidebar-form {
  .btn-theme {
    @extend .btn-primary-pwa;
    // min-height: auto !important;
    font-size: 14px !important;
  }
}

@media (max-width: $fhd-tab) {
  .gil-header nav.shar-navbar .navbar-outer {
    background: #282c62;
    box-shadow: 1px 13px 11px 3px rgba(0, 0, 0, 0.36);
    z-index: 10000;
    position: fixed;

    .navbar-menu-list {
      li {
        a {
          color: #fff;
        }
      }
    }
  }

  .gil-header nav.shar-navbar {
    background-color: ghostwhite;

    .home-nav-logo {
      margin-left: calc(50% - 90px);
    }
  }

  .shar-navbar-links {
    .btn-getstarted {
      background-color: #fff;
      color: #282c62;
    }

    >a.font-bold {
      color: #fff;
    }
  }

  .create-card-pwa-outer .page-sidebar-body {
    .create-card-pwa {
      height: auto;

      .card-body {
        display: flex;
        align-items: center;
      }
    }
  }

  .header-admin-dropdown.dropdown-toggle {
    overflow: visible;
  }

  #header+.px-0.h-100.container-fluid .side-navbar {
    background-color: #282c62;
  }

  #header~.px-0.h-100.container-fluid .navbar-dark .collapsed .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20px' height='18px' viewBox='0 0 20 18'%3E%3Cdefs%3E%3Cpath d='M19.2,13.8461538 L19.2,18 L0,18 L0,13.8461538 L19.2,13.8461538 Z M19.2,6.92307692 L19.2,11.0769231 L0,11.0769231 L0,6.92307692 L19.2,6.92307692 Z M19.2,0 L19.2,4.15384615 L0,4.15384615 L0,0 L19.2,0 Z' id='path-1'/%3E%3C/defs%3E%3Cg id='PWA-2021' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='5.0-Home---Get-Started' transform='translate(-20.000000, -76.000000)'%3E%3Cg id='Group-2'%3E%3Cg id='Group-3' transform='translate(20.000000, 62.000000)'%3E%3Cg id='Group'%3E%3Cg id='Icon/General/Hamburger' transform='translate(0.000000, 14.000000)'%3E%3Cmask id='mask-2' fill='white'%3E%3Cuse xmlns:xlink='http://www.w3.org/1999/xlink' xlink:href='%23path-1'/%3E%3C/mask%3E%3Cuse id='Mask' fill='%23FFFFFF' xmlns:xlink='http://www.w3.org/1999/xlink' xlink:href='%23path-1'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }

  #header~.px-0.h-100.container-fluid .navbar-dark .navbar-toggler-icon {
    filter: none;
  }

  #header .header-logo {
    max-width: calc(100% - 175px);
    margin-left: 50px;
  }

  .client-profile-toggle-outer {
    height: auto !important;
  }
}

@media (max-width: $hd-tab) {
  .gil-header nav.shar-navbar .navbar-outer {
    background: #282c62;
    box-shadow: 1px 13px 11px 3px rgba(0, 0, 0, 0.36);
    z-index: 10000;
    position: fixed;

    .navbar-menu-list {
      li {
        a {
          color: #fff;
        }
      }
    }
  }

  .gil-header nav.shar-navbar {
    background-color: ghostwhite;

    .home-nav-logo {
      margin-left: calc(50% - 90px);
    }
  }

  .shar-navbar-links {
    .btn-getstarted {
      background-color: #fff;
      color: #282c62;
    }

    >a.font-bold {
      color: #fff;
    }
  }

  .create-card-pwa-outer .page-sidebar-body {
    .create-card-pwa {
      height: auto;

      .card-body {
        display: flex;
        align-items: center;
      }
    }
  }

  .header-admin-dropdown.dropdown-toggle {
    overflow: visible;
  }

  #header+.px-0.h-100.container-fluid .side-navbar {
    background-color: #282c62;
  }

  #header~.px-0.h-100.container-fluid .navbar-dark .collapsed .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20px' height='18px' viewBox='0 0 20 18'%3E%3Cdefs%3E%3Cpath d='M19.2,13.8461538 L19.2,18 L0,18 L0,13.8461538 L19.2,13.8461538 Z M19.2,6.92307692 L19.2,11.0769231 L0,11.0769231 L0,6.92307692 L19.2,6.92307692 Z M19.2,0 L19.2,4.15384615 L0,4.15384615 L0,0 L19.2,0 Z' id='path-1'/%3E%3C/defs%3E%3Cg id='PWA-2021' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='5.0-Home---Get-Started' transform='translate(-20.000000, -76.000000)'%3E%3Cg id='Group-2'%3E%3Cg id='Group-3' transform='translate(20.000000, 62.000000)'%3E%3Cg id='Group'%3E%3Cg id='Icon/General/Hamburger' transform='translate(0.000000, 14.000000)'%3E%3Cmask id='mask-2' fill='white'%3E%3Cuse xmlns:xlink='http://www.w3.org/1999/xlink' xlink:href='%23path-1'/%3E%3C/mask%3E%3Cuse id='Mask' fill='%23FFFFFF' xmlns:xlink='http://www.w3.org/1999/xlink' xlink:href='%23path-1'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }

  #header~.px-0.h-100.container-fluid .navbar-dark .navbar-toggler-icon {
    filter: none;
  }

  #header .header-logo {
    max-width: calc(100% - 175px);
    margin-left: 50px;
  }

  .client-profile-toggle-outer {
    height: auto !important;
  }
}

.gil-main-container {
  @media (max-width: 1024px) {
    .btn.btn-lg {
      height: auto;
      line-height: 1;
    }

    .play-section .btn-play {
      min-width: 45px;
      min-height: 45px;
    }

    .our-member-section {
      .owl-prev {
        left: 0;
      }

      .owl-next {
        right: 0;
      }
    }

    .our-member-section .dot-bg::after {
      right: 0;
    }

    .leadership-topics-sec::after {
      width: auto;
    }

    .newsletter-panel {
      .input-group {
        display: flex;
        flex-direction: column;

        input {
          width: 100%;
          border-radius: 4px;
          margin-bottom: 10px;
        }

        .btn-white {
          width: 100%;
          border-radius: 4px;
        }
      }
    }

    .peer-learning-card img {
      height: 350px;
      object-fit: cover;
    }
  }
}

.follow-drop {
  box-shadow: 1px 2px 6px 1px rgba(39, 34, 98, 0.22) !important;
  padding: 10px;
  margin-top: 10px;
  margin-right: 20px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  height: 4rem;
  align-items: center;
  display: flex;
  padding-left: 21px;
}

.h-fit-content {
  height: fit-content !important;
}

.overlay-Lock {
  position: absolute;
  width: 100%;
  left: 0;
  background: white;
  height: 100%;
  z-index: 2;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.99);

  svg {
    font-size: 57px;
    border: 3px solid black;
    padding: 8px;
    border-radius: 50%;
  }
}

.bg-active {
  background-color: #e7e7e7;
}

.follow-following-list {
  max-height: 20rem;
  overflow: auto;
}

.client-profile-toggle {
  .client-profile-toggle-outer {
    max-height: 70vh;
    overflow: auto;
    @extend .scroll-y;
  }
}

@media (max-width: $mobile) {
  .new-landing-theme.register-form {
    >.card {
      .account-info-pic {
        text-align: left;
      }

      .col-md-8 {
        max-width: calc(100% - 40px);

        +.col-md-2 {
          width: 40px;
          padding: 0;

          img {
            padding: 0 !important;
          }
        }
      }
    }
  }
}

.partner-images {
  img {
    height: 60px;
    object-fit: contain;
  }
}

.play-icon-vision {
  width: 40px;
  height: 40px;
  opacity: 0.75;
}

.hpc-outer {
  width: 140px;
  min-width: 140px;
  margin-right: 10px;
  border-radius: 15px;
  box-shadow: 2px 9px 13px 0 rgba(39, 34, 98, 0.22);

  .bg-primary {
    height: 60px;

    img {
      width: 38px;
    }
  }

  .btn {
    font-size: 10px !important;
    box-shadow: 0 4px 8px 0 rgba(84, 104, 255, 0.3);
  }

  .hpc-inner {
    padding: 12px 8px;
  }
}

.border-lg-none {
  @media (min-width: $fhd-tab) {
    border: none !important;
  }

  @media (min-width: $hd-tab) {
    border: none !important;
  }
}

.pwa-chat-outer {
  @media (max-width: $fhd-tab) {
    input {
      max-height: 50px;
      height: 50px !important;
    }
  }

  @media (max-width: $hd-tab) {
    input {
      max-height: 50px;
      height: 50px !important;
    }
  }
}

.pwa-chat-icon {
  top: 13px;
  right: 42px;
  width: 24px;
  height: 24px;

  @media (min-width: $hd-tab) {
    right: auto;
    left: 270px;
    top: 13px;
  }

  @media (min-width: $fhd-tab) {
    right: auto;
    left: 270px;
    top: 13px;
  }
}

.pwa-chat-view {
  .pwa-chat-unit {
    padding: 18px 12px;
  }

  .person-status {
    width: 12px;
    min-width: 12px;
    height: 12px;
    margin-right: 6px;
    position: absolute;
    left: 54px;
    margin-top: 50px;
    background-color: #e2dee8;
  }

  .pcu-img {
    width: 60px;
    min-width: 60px;
    height: 60px;
    object-fit: cover;
  }

  .pcu-time {
    right: 14px;
    top: 25px;
    font-family: $font-light;

    .group-home & {
      top: 20px;
    }
  }

  .pcu-text-outer {
    width: calc(100% - 160px);
    padding-right: 15px;
    cursor: pointer;
    color: #383838;
    min-width: calc(100% - 160px);
  }

  .pcu-count {
    width: 40px;
    height: 20px;
    border-radius: 3px;
    font-size: 12px;
    margin-left: auto;
    margin-right: 2px;
  }
}

.chat-float-icon {
  background-color: #f7941d;
  width: 60px;
  height: 60px;
  box-shadow: 0 2px 4px 0 rgba($black, 0.5);
  bottom: 100px;
  right: 10px;
  transition: 0.3s ease-in-out;

  svg {
    width: 26px;
    height: 26px;
  }
}

.chat-float-icon2 {
  background-color: #f7941d;
  width: 60px;
  height: 60px;
  box-shadow: 0 2px 4px 0 rgba($black, 0.5);
  bottom: 10px;
  right: 10px;
  transition: 0.3s ease-in-out;

  svg {
    width: 26px;
    height: 26px;
  }
}

.notification-indicator-profile-icon {
  @media (max-width: $fhd-tab) {
    bottom: 0;
    top: auto;
  }

  @media (max-width: $hd-tab) {
    bottom: 0;
    top: auto;
  }
}

.pwa-footer-outer {
  height: 62px;

  @include for-tablet-portrait-up {
    height: 75px;
  }

  .pwa-footer-menu {
    font-weight: 700;
    color: currentColor;
    position: relative;
    font-size: 8px;
  }

  .pwa-footer-community {
    transition: 0.3s ease-in-out;
    width: 115px !important;

    &.todo-link {
      svg {
        margin-top: -28px;
        transition: 0.3s ease-in-out;
      }

      &.show-call-out {
        svg {
          transform: rotate(135deg);
        }
      }
    }
  }

  .pwa-footer-link {
    transition: 0.3s ease-in-out;
    // border-right: 1px solid #dcdcdc;
    width: 105px !important;

    &:first-child {
      // border-radius: 15px 0 0;
    }

    &:last-child {
      // border-radius: 0 15px 0 0;
    }

    &.todo-link {
      svg {
        margin-top: -28px;
        transition: 0.3s ease-in-out;
      }

      &.show-call-out {
        svg {
          transform: rotate(135deg);
        }
      }
    }
  }

  .active-link {
    border-bottom: 5px solid #ff9900 !important;
    // background-color: #c3c0c9 !important;
  }
}

.bottom-border-header {
  border-bottom: 4px solid #272262;
  padding: 0 5px !important;
}

.pwaFooterNav {
  background-color: #272262;
}

.pwa-callout-decorator {
  width: 60px;
  height: 5px;
  background-color: #d8d8d8;
  border-radius: 3px;
  margin: auto;
}

.gil-conversation-callout {
  .pwa-callout-decorator {
    &.mt-n3.mb-30 {
      display: none;
    }
  }

  .page-sidebar-head {
    padding: 0 !important;
  }

  .page-sidebar-body {
    @media (max-width: $fhd-tab) {
      min-height: calc(100vh - 152px);
    }

    @media (max-width: $hd-tab) {
      min-height: calc(100vh - 152px);
    }
  }
}

.pwa-profile-image-outer {
  .profile-img-upload {
    width: 96px;
    height: 96px;

    .user-image-uploaded {
      border: 6px solid #ff961e;
    }
  }
}

.header-unpin {
  .chat-float-icon {
    bottom: 20px;
  }
}

.b-500 {
  font-family: $font-medium;
}

.font-weight-bold {
  font-family: $font-bold;
}

.donation-card-outer {
  border-radius: 25px;
  box-shadow: 2px 9px 13px 0 rgba(39, 34, 98, 0.22);
}

.pwa-profile-tabs {
  border: none;
  margin-bottom: 12px;

  .nav-link {
    width: 50%;
    border: none;
    font-size: 18px;
    text-align: center;

    &:first-child {
      border-right: 1px solid #979797;
    }

    &.active {
      font-family: $font-bold;
      color: $primary;
    }
  }
}

.pwa-search-outer {
  &.shar-form.search-form-pwa input[type="text"] {
    padding: 25px 24px;
    height: 52px;
  }
}

.courseInput {
  padding: 0;
}

.profile-img-upload {
  .sb-avatar__text {
    border: 6px solid #ff961e;
  }

  .sb-avatar__image {
    border: 6px solid #ff961e;
  }
}

.header-admin-dropdown {
  .header & {
    width: auto;
    min-width: 52px;
  }

  .sb-avatar__text,
  .sb-avatar__image {
    border: 3px solid #ff961e;
  }
}

.trending-news {
  width: 25%;
}

.welcome-card-section:empty {
  display: none !important;
}

.active-indicator {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-image: linear-gradient(180deg, #f9c254 0%, #f18f2a 100%);
}

.gs-outer {
  @media (max-width: $fhd-tab) {
    &:after {
      content: "";
      min-width: 4px;
    }
  }

  @media (max-width: $hd-tab) {
    &:after {
      content: "";
      min-width: 4px;
    }
  }
}

.media-pwa-outer {
  @media (max-width: $fhd-tab) {
    .article-section.slider-mob {
      padding-right: 0 !important;

      .article-section-outer {
        margin-left: -20px;
      }

      .see-all-text {
        margin-right: 14px;
      }
    }
  }

  @media (max-width: $hd-tab) {
    .article-section.slider-mob {
      padding-right: 0 !important;

      .article-section-outer {
        margin-left: -20px;
      }

      .see-all-text {
        margin-right: 14px;
      }
    }
  }
}

.media-group-pwa {
  @media (max-width: $fhd-tab) {
    transform: translateX(-20px);
    margin-right: -40px;

    .my-group {
      min-width: 80%;
    }
  }

  @media (max-width: $hd-tab) {
    transform: translateX(-20px);
    margin-right: -40px;

    .my-group {
      min-width: 80%;
    }
  }
}

.article-section {
  @media (max-width: $fhd-tab) {
    .article-outer {
      &:only-of-type {
        min-width: auto !important;
        max-width: 100%;
      }
    }
  }

  @media (max-width: $hd-tab) {
    .article-outer {
      &:only-of-type {
        min-width: auto !important;
        max-width: 100%;
      }
    }
  }
}

.media-group-pwa,
.article-section,
.create-call-out .success-card-outer {
  @media (max-width: $fhd-tab) {
    .article-outer {
      &:only-of-type {
        min-width: calc(100vw - 40px);
      }

      .article-main-img-wrap {

        &,
        img {
          min-height: 115px;
          height: 115px !important;
        }
      }

      .pt-3.pb-2.px-3 {
        >div:first-child {
          display: flex;
          flex-wrap: wrap;

          .article-category {
            order: -1;
            width: 100%;
          }

          .article-title {
            margin-top: 8px;
            margin-bottom: 27px;
          }
        }
      }

      .card-time-info {
        span.text-dark.font-12 {
          font-size: 14px !important;
          color: #344356 !important;
        }
      }
    }
  }

  @media (max-width: $hd-tab) {
    .article-outer {
      &:only-of-type {
        min-width: calc(100vw - 40px);
      }

      .article-main-img-wrap {

        &,
        img {
          min-height: 115px;
          height: 115px !important;
        }
      }

      .pt-3.pb-2.px-3 {
        >div:first-child {
          display: flex;
          flex-wrap: wrap;

          .article-category {
            order: -1;
            width: 100%;
          }

          .article-title {
            margin-top: 8px;
            margin-bottom: 27px;
          }
        }
      }

      .card-time-info {
        span.text-dark.font-12 {
          font-size: 14px !important;
          color: #344356 !important;
        }
      }
    }
  }
}

.gil-landing-toggler {
  .navbar-toggler-icon {
    filter: invert(1);
  }
}

.home-page-city-ethos .landing-page-stories .article-new.article-outer {
  border-radius: 15px !important;
  overflow: hidden !important;
}

.home-page-city-ethos .landing-page-stories .article-new.article-outer .article-main-img-wrap img {
  height: 160px;
}

.passport-items-selector {
  @media (min-width: $fhd-tab) {
    margin-top: 20px;
  }

  @media (min-width: $hd-tab) {
    margin-top: 20px;
  }
}

.vimeo-links-row {
  @media (max-width: $fhd-tab) {
    .peer-learning-content {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }

    .peer-learning-overlay {
      height: auto !important;
    }
  }

  @media (max-width: $hd-tab) {
    .peer-learning-content {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }

    .peer-learning-overlay {
      height: auto !important;
    }
  }
}

.ios-splash-screen {
  background-image: linear-gradient(180deg, #fefdfd 1%, #d8d8d8 98%);
  border-radius: 5px;
  width: 280px;
}

.hide-header {
  .gil-header {
    display: none !important;
  }
}

.splash-screen-logo {
  width: 125px;
  height: 125px;
  border-radius: 50px;
}

.btn-splash-screen {
  background-image: linear-gradient(192deg, #77549f 12%, #ebe9ef 130%);
  box-shadow: 0 4px 8px 0 rgba(84, 104, 255, 0.3);
  border-radius: 15px;
  height: 58px;
  font-size: 16px;
  letter-spacing: 1px;
}

.download-bar-outer {
  height: 12px;
  border-radius: 6px;
  max-width: 280px;

  .download-bar-inner {
    background-image: linear-gradient(180deg, #77549f 0%, #422a66 100%);
  }
}

.pwa-invite-callout {
  #network-invitation {
    display: block !important;
    top: 70px;
    border-radius: 15px;
    box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 1000;
    padding-top: 0 !important;
    animation-name: slideUp !important;

    .edit-profile {
      height: calc(100vh - 70px);
      overflow: auto;
      padding-bottom: 24px;
      margin: 0 -16px;
      padding-left: 40px !important;
      padding-right: 40px !important;

      >.align-items-center {
        >.d-flex {
          margin: auto;

          .round-circle {
            display: none;
          }

          h3.mb-0.font-30-bold {
            font-size: 20px;
            color: #000;
          }
        }

        .close-button {
          width: 100%;
          text-align: center;
          order: -1;
          margin-top: 2px;
          margin-bottom: 20px;
        }

        .btn-close {
          @extend .pwa-callout-decorator;
          margin-top: 8px;

          svg {
            display: none;
          }
        }
      }
    }

    .border-dotted-bottom {
      padding-right: 0 !important;
      width: 100%;
      justify-content: center;
      color: #000;
      border: none;
      font-size: 16px;
    }

    .pwa-callout-decorator-sec {
      .header-unpin~.px-0.h-100.container-fluid & {
        top: 0 !important;
      }
    }
  }
}

.topics-image-outer {
  margin: 0 -20px;

  img {
    border-radius: 15px 15px 0 0;
    height: 150px;
    object-fit: cover;
  }
}

@media (max-width: $fhd-tab) {
  .pwa-group-list {
    padding-right: 20px;
  }

  .see-all-callout {
    .home-page-padding {
      .article-section-outer {
        padding-left: 24px;
        padding-right: 8px;
        padding-bottom: 80px;
      }
    }
  }

  .media-heading-outer {
    flex-wrap: nowrap !important;

    .see-all-text {
      min-width: 60px;
    }
  }

  .pwa-account-callout {
    .page-sidebar-head {
      .font-bold.display-8.ml-2 {
        margin-bottom: 24px;
      }
    }
  }
}

@media (max-width: $hd-tab) {
  .pwa-group-list {
    padding-right: 20px;
  }

  .see-all-callout {
    .home-page-padding {
      .article-section-outer {
        padding-left: 24px;
        padding-right: 8px;
        padding-bottom: 80px;
      }
    }
  }

  .media-heading-outer {
    flex-wrap: nowrap !important;

    .see-all-text {
      min-width: 60px;
    }
  }

  .pwa-account-callout {
    .page-sidebar-head {
      .font-bold.display-8.ml-2 {
        margin-bottom: 24px;
      }
    }
  }
}

.direct-message-callout .message-box-view {
  @media (max-width: $fhd-tab) {
    height: calc(100vh - 310px);
  }

  @media (max-width: $hd-tab) {
    height: calc(100vh - 310px);
  }
}

.show-icon {
  position: absolute;
  right: 12px;
  top: 14px;
  cursor: pointer;

  &-pwa {
    top: 23px;
    right: 20px;
  }

  svg {
    width: 24px;
  }
}

.pwa-profile-groups {
  .shar-form {
    padding: 0 24px;

    >label.font-bold.display-8.mb-3.form-label {
      text-align: center;
      font-size: 18px !important;
      margin-bottom: 24px !important;
      width: 100%;
    }
  }
}

.doc-edit {
  padding: 10px;
  border: none !important;
  outline: none;
  border-radius: 10px;
}

.doc-name-edit {
  border: 2px solid #272262;
  max-height: 100px;
  width: 90% !important;
  border-radius: 10px;
  margin: auto;
}

.radio-label {
  margin-bottom: 0rem !important;
}

.media-pwa-outer {
  .slider-mob-inner {
    .article-outer {
      .px-3.py-4 {
        padding: 20px 16px !important;

        .font-14.mb-20.font-bold {
          margin-bottom: 8px !important;
        }

        .d-flex.mt-3 {
          padding-top: 12px !important;
        }
      }
    }
  }
}

.group-chat {
  &-dp {
    width: 30px;
    height: 30px;
    object-fit: cover;
  }

  &-add {
    width: 30px;
    height: 30px;
    position: relative;
    top: -2px;
  }

  &-more {
    width: 10px;
    position: relative;
    top: 2px;
  }

  &-main-img {
    width: 150px;
    height: 150px;
    object-fit: cover;
  }

  &-body {
    margin-top: 58px;
    padding: 20px 16px 48px;

    .messages ul li {
      .group-chat-text {
        max-width: calc(100% - 40px) !important;
      }

      .group-chat-like {
        margin: 0;
        width: 17px;
        height: 14px;
        border-radius: 0;
        margin-right: 40px;
      }

      .article-pic {
        margin: 0;
        // margin-right: 10px;
      }

      .group-chat-article-pic {
        width: 100%;
        height: 115px;
        border-radius: 0;
        margin: 0;
      }

      .group-chat-attachment-download {
        margin: 0;
        border-radius: 0;
        width: 16px;
        height: 16px;
      }
    }
  }

  &-join {
    border-radius: 4px;
  }

  &-article {
    #messageList & {
      margin-left: -30px;
      margin-top: 12px;
      transform: translateX(10px);

      .article-outer {
        border-radius: 15px 0 15px 15px;
      }

      &:after {
        content: "";
        position: absolute;
        width: 20px;
        height: 16px;
        top: calc(50% - 16px);
        right: 15px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20px' height='16px' viewBox='0 0 20 16'%3E%3Cg id='PWA-2021' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='7.1-Group-Page-1' transform='translate(-318.000000, -1135.000000)' fill='%23272262' fill-rule='nonzero'%3E%3Cg id='Group-8' transform='translate(20.000000, 994.000000)'%3E%3Cpath d='M307.000201,145.053816 L307.000201,141.999816 C307.000115,141.59542 306.756474,141.230882 306.382856,141.076138 C306.009238,140.921394 305.579195,141.006908 305.293201,141.292816 L298.293201,148.292816 C298.105485,148.480213 298,148.73457 298,148.999816 C298,149.265061 298.105485,149.519418 298.293201,149.706816 L305.293201,156.706816 C305.578838,156.993753 306.009417,157.079916 306.383475,156.92499 C306.757534,156.770064 307.001096,156.404687 307.000201,155.999816 L307.000201,152.903816 C307.284201,152.883816 307.565201,152.874816 307.843201,152.874816 C313.269201,152.874816 316.082201,156.446816 316.205201,156.604816 C316.463733,156.946748 316.912046,157.084907 317.318201,156.947816 C317.725605,156.811168 318.000201,156.429525 318.000201,155.999816 C318.000201,146.875816 309.914201,145.318816 307.000201,145.053816 Z M307.843201,150.874816 C307.196201,150.874816 306.531201,150.919816 305.868201,151.007816 C305.37126,151.074433 305.000264,151.49843 305.000201,151.999816 L305.000201,153.585816 L300.414201,148.999816 L305.000201,144.413816 L305.000201,145.999816 C305.000201,146.551816 305.471201,146.999816 306.022201,146.999816 C306.932201,146.999816 314.136201,147.200816 315.701201,153.424816 C314.047201,152.206816 311.420201,150.874816 307.843201,150.874816 Z' id='Shape' transform='translate(308.000101, 149.000526) scale(-1, 1) translate(-308.000101, -149.000526) '%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      }
    }

    .article-pic {
      margin-right: 0;
    }
  }

  &-attachment {
    box-shadow: 2px 9px 13px 0 rgba($primary, 0.2);
    margin-bottom: 14px;
    border-radius: 0 15px 15px 15px;
    overflow: hidden;
    margin-top: 6px;

    &-icon {
      margin: 0 !important;
      filter: invert(1);

      &-outer {
        width: 60px;
        max-width: 60px;
        flex: 1 1 auto;
      }
    }

    &-data {
      padding: 6px 12px;

      .font-bold {
        word-break: break-word;
      }
    }

    &-download {
      &-outer {
        margin-bottom: 6px;
        margin-right: 12px;
      }
    }
  }

  &-footer {
    bottom: 0;
    padding: 5px 10px;
    z-index: 2;
  }

  &-send {
    width: 40px;
    height: 40px;
  }

  &-input {
    background: #eee;
    border: none;
    height: 40px !important;
    border-radius: 20px;
    padding: 0 30px 0 8px;
    width: 140px;
  }

  &-emoji {
    right: 10px;
    top: 9px;
  }
}

.profile-position {
  z-index: 99 !important;
}

.updated-at {
  padding-top: 10px;

  @include for-tablet-portrait-up {
    padding-top: 0rem;
    padding-bottom: 2rem;
  }
}

.create-position {
  z-index: 99 !important;
  right: 0px !important;
}


.chat-as-page {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #fff;

  &-body {
    .messages ul li {
      margin: 0;
      margin-bottom: 15px;
      width: 100%;

      .chat-img-inside {
        img {
          margin: 0;
          box-shadow: 2px 9px 13px 0 rgba(39, 34, 98, 0.22);
          width: 250px;
          border-radius: 15px;
          height: 270px;
          object-fit: cover;
          margin-top: 12px;

          &.img-full-size {
            position: fixed;
            top: 50%;
            transform: translateY(-50%);
            left: 10px;
            width: calc(100% - 20px) !important;
            z-index: 100;
            border-radius: 0 !important;
            height: auto !important;
            max-height: calc(100vh - 20px);
            object-fit: contain;
            margin: 0 !important;
            z-index: 101;
          }
        }
      }

      &.replies {
        .chat-img-inside {
          height: 290px;

          img {
            border-top-right-radius: 0px;
          }

          +div {
            margin-left: 0 !important;
          }
        }

        .group-chat-attachment {
          border-radius: 15px 0 15px 15px;
        }

        .chat-img-inside img {
          border-top-right-radius: 0 !important;
        }
      }

      &.sent {

        .article-section-outer,
        .community-section-inner {
          transform: translateX(-30px);

          .article-outer {
            border-radius: 0 15px 15px 15px;
          }
        }

        .chat-img-inside img {
          border-top-left-radius: 0 !important;
        }

        .group-chat-article .article-outer {
          border-radius: 0 15px 15px 15px !important;
        }
      }
    }

    #messageList {
      padding: 16px 20px 0 !important;

      .messages ul li {
        p {
          max-width: calc(100% - 40px) !important;
          min-height: 40px;
        }

        &.replies {

          p,
          .group-chat-article {
            +div {
              margin-left: 5px !important;
            }
          }
        }

        &.sent {
          >div:nth-child(2) {
            margin-left: auto !important;
            text-align: right;
            margin-right: 5px;
          }

          .group-chat-article::after {
            right: auto;
            left: 20px;
          }
        }
      }

      >.col {
        padding: 0;
      }

      >.text-center.font-12.font-med.mt-4 {
        margin-top: 0 !important;
      }
    }

    .input-msg-box {
      display: none;
    }

    .message-box-view {
      height: calc(100vh - 105px);
    }
  }

  &-avatar {
    &.sb-avatar__text {
      background-color: #dedce6 !important;
      border: 3px solid #ff961e;

      span {
        color: rgba($primary, 1);
      }
    }

    &.sb-avatar__image {
      background-color: #dedce6 !important;
      border: 3px solid #ff961e;

      span {
        color: rgba($primary, 1);
      }
    }
  }

  .group-chat-body {
    padding: 0px 0px 48px;

    .chat-details {
      padding-top: 0;
    }
  }

  .emoji-picker-react {
    position: absolute;
    bottom: 56px;
    width: 100%;
  }
}

.input-expanded {

  >.ml-4,
  >.mx-4,
  .group-chat-add {
    display: none;
  }

  >.position-relative {
    flex: 1;
    margin-right: 10px;

    .group-chat-input {
      width: 100%;
    }
  }

  .group-chat-emoji {
    right: 0;
  }

  .ml-auto svg {
    transform: rotate(180deg);
  }
}

.chat-upload-outer,

.attach-upload-outer {
  position: relative;
  margin-bottom: 0;

  .upload-img-panel,
  input[type="file"] {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
  }
}

@media (max-width: 360px) {
  .chat-upload-sec {
    margin-left: 12px !important;
  }

  .attach-upload-outer {
    margin-left: 16px !important;
    margin-right: 8px !important;
  }
}

.invite-btn-sm {
  letter-spacing: 0.63px;
  border: 1px solid #282c62;
  border-radius: 8px;
  height: 33px;
  font-size: 10px !important;
  width: 120px;
  box-shadow: 0 4px 8px 0 rgba($primary, 0.3);
}

.group-chat-gil {
  height: calc(100vh - 58px);
  overflow: auto;

  &-first {
    margin-top: 80px;

    +.group-chat-body {
      margin-top: 10px;
      padding-bottom: 0;

      .message-box-view {
        height: auto;
      }
    }
  }
}

.group-chat-option {
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 -48px;
}

.text-alternate-light {
  color: #f7941d;
}

.header-unpin~.px-0.h-100.container-fluid .chat-as-page~.pwa-chat-outer .gil-pwa-callout.gil-conversation-callout {
  top: 70px !important;
}

.group-chat-article-pic {
  width: 100% !important;
  height: 115px !important;
  border-radius: 0 !important;
  margin: 0 !important;
}

.chat-img-inside img {
  box-shadow: 2px 9px 13px 0 rgba(39, 34, 98, 0.22);
  width: 250px !important;
  border-radius: 15px !important;
  height: 270px !important;
  object-fit: cover;
  margin: 12px 0 !important;
}

.plus-icon-height.gil-pwa-callout-slideMore {
  top: auto !important;
}

.gil-pwa-callout-passport {
  .passport-select-set {
    min-width: calc(50% - 8px);
  }
}

.passport-info-outer {
  .passport-pill {
    background-image: linear-gradient(180deg, #4f479b 0%, #272262 100%);
  }
}

// .pwa-profile-outer .notification-indicator,
// .notification-indicator {
//   @media (max-width: $fhd-tab) {
//     background-image: linear-gradient(180deg, #f9c254 0%, #f18f2a 100%);
//   }

//   @media (max-width: $hd-tab) {
//     background-image: linear-gradient(180deg, #f9c254 0%, #f18f2a 100%);
//   }
// }

.name-assignmentheading {
  font-size: 19px;
  font-weight: 600;
}

.autoCompleteTextField {
  .MuiInputBase-input {
    box-shadow: none !important;
    height: 0px !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  input#joinedNeighbourhood {
    margin-bottom: 8px !important;
    margin-top: 8px !important;
  }
}

.pwa-profile-accounts {
  .time-ago {
    margin-right: 5px;
  }

  .ac-activated-border {
    border-width: 2px !important;
  }

  +.pwa-profile-account-change {
    .btn.btn-primary {
      border-radius: 15px;
      background: #272262 !important;
    }
  }
}

.gil-pwa-campaign-create {
  form[name="group"] {
    .mr-auto.d-inline-block.text-center {
      width: 100%;

      .btn.btn-primary {
        width: 100%;
        border-radius: 15px !important;
        min-height: 58px;
        font-size: 16px !important;
      }

      .display-8.font-medium-italic.cursor-pointer {
        font-family: $font-bold !important;
        font-size: 16px !important;
      }
    }
  }

  .create-article-upload {
    width: 100%;
  }
}

.success-card-outer {
  @media (max-width: $fhd-tab) {
    max-width: 264px;
    margin: auto;

    .article-outer {
      min-width: auto !important;
    }

    +.text-center {
      .btn.btn-primary {
        border-radius: 15px !important;
        min-width: 200px;
        min-height: 45px;
      }

      .font-medium {
        color: #8640c2;
      }
    }
  }

  @media (max-width: $hd-tab) {
    max-width: 264px;
    margin: auto;

    .article-outer {
      min-width: auto !important;
    }

    +.text-center {
      .btn.btn-primary {
        border-radius: 15px !important;
        min-width: 200px;
        min-height: 45px;
      }

      .font-medium {
        color: #8640c2;
      }
    }
  }
}

.align-items-center.justify-content-between.group-section-outer.my-4.pb-3 {
  @media (max-width: $fhd-tab) {
    margin-top: -30px !important;

    .font-21-bold.mb-4.pb-2 {
      color: $primary;
    }

    .earned-badge {
      min-width: auto;
    }
  }

  @media (max-width: $hd-tab) {
    margin-top: -30px !important;

    .font-21-bold.mb-4.pb-2 {
      color: $primary;
    }

    .earned-badge {
      min-width: auto;
    }
  }
}

.mcq-set-dark .b-600.font-14.mt-1,
.mcq-set-dark .b-600.mt-1.font-14-medium {
  color: #fff !important;
}

.ios-splash-screen {
  ol {
    color: $primary;
  }
}

.pwa-todo-callout {
  z-index: 20 !important;
  top: 70px !important;
  bottom: 50px;
}

body .pwa-menu.footer-unpin {
  @media (max-width: $fhd-tab) {
    .todo-link svg {
      margin-top: 0;
    }
  }

  @media (max-width: $hd-tab) {
    .todo-link svg {
      margin-top: 0;
    }
  }
}

.notifications-outer {
  @media (max-width: $fhd-tab) {
    .btn-none {
      img {
        // filter: invert(1);
      }
    }
  }

  @media (max-width: $hd-tab) {
    .btn-none {
      img {
        // filter: invert(1);
      }
    }
  }
}

@media (max-width: $fhd-tab) {
  .notification-overflow {
    height: calc(100vh - 180px);

    .time-ago {
      margin-right: 8px;
    }
  }
}

@media (max-width: $hd-tab) {
  .notification-overflow {
    height: calc(100vh - 180px);

    .time-ago {
      margin-right: 8px;
    }
  }
}

.courses-small-img {
  width: 60px;
  height: 60px;
  object-fit: cover;
}

.courses-tab-pwa {
  .article-outer .article-pic {
    display: inline-block !important;
  }
}

.gil-splash-screen {
  height: 100vh;

  .react-player {
    height: 500px !important;
  }
}

.react-player-video {
  border-radius: 10px !important;
  overflow: hidden;
}

@media (max-width: $desktop) {
  .pwa-profile-callout-body {
    position: fixed;
    top: 170px;
    background: #fff;
    padding-top: 16px;
    border-radius: 15px 15px 0 0;
    width: 100%;
    animation: slideUp 0.3s ease-in-out;

    .pwa-profile-callout-inner {
      overflow: auto;
      height: calc(100vh - 180px);
    }
  }

  .page-sidebar-outer.direct-message-callout,
  .page-sidebar-outer.pwa-account-callout,
  .page-sidebar-outer.gil-conversation-callout,
  .gil-pwa-callout.direct-message-callout,
  .gil-pwa-callout.pwa-account-callout,
  .gil-pwa-callout.gil-conversation-callout {
    .page-sidebar-inner {
      min-height: calc(100vh - 130px);
    }
  }

  .group-detail {
    .welcome-card {
      .p-lg-4.p-2.d-flex.w-100 {

        .sb-avatar,
        .welcome-card-heading-img {
          display: none !important;
        }
      }
    }
  }

  .article-section .article-outer .article-pic {
    display: inline-block !important;
  }

  body .navbar-dark .navbar-toggler-icon {
    height: 18px !important;
  }
}

@media (max-width: $fhd-tab) {
  .pwa-profile-callout-body {
    position: fixed;
    top: 70px;
    background: #fff;
    padding-top: 16px;
    border-radius: 15px 15px 0 0;
    width: 100%;
    animation: slideUp 0.3s ease-in-out;

    .pwa-profile-callout-inner {
      overflow: auto;
      height: calc(100vh - 180px);
    }
  }

  .page-sidebar-outer.direct-message-callout,
  .page-sidebar-outer.pwa-account-callout,
  .page-sidebar-outer.gil-conversation-callout,
  .gil-pwa-callout.direct-message-callout,
  .gil-pwa-callout.pwa-account-callout,
  .gil-pwa-callout.gil-conversation-callout {
    .page-sidebar-inner {
      min-height: calc(100vh - 130px);
    }
  }

  .group-detail {
    .welcome-card {
      .p-lg-4.p-2.d-flex.w-100 {

        .sb-avatar,
        .welcome-card-heading-img {
          display: none !important;
        }
      }
    }
  }

  .article-section .article-outer .article-pic {
    display: inline-block !important;
  }

  body .navbar-dark .navbar-toggler-icon {
    height: 18px;
  }
}

@media (max-width: $hd-tab) {
  .pwa-profile-callout-body {
    position: fixed;
    top: 70px;
    background: #fff;
    padding-top: 16px;
    border-radius: 15px 15px 0 0;
    width: 100%;
    animation: slideUp 0.3s ease-in-out;

    .pwa-profile-callout-inner {
      overflow: auto;
      height: calc(100vh - 180px);
    }
  }

  .page-sidebar-outer.direct-message-callout,
  .page-sidebar-outer.pwa-account-callout,
  .page-sidebar-outer.gil-conversation-callout,
  .gil-pwa-callout.direct-message-callout,
  .gil-pwa-callout.pwa-account-callout,
  .gil-pwa-callout.gil-conversation-callout {
    .page-sidebar-inner {
      min-height: calc(100vh - 130px);
    }
  }

  .group-detail {
    .welcome-card {
      .p-lg-4.p-2.d-flex.w-100 {

        .sb-avatar,
        .welcome-card-heading-img {
          display: none !important;
        }
      }
    }
  }

  .article-section .article-outer .article-pic {
    display: inline-block !important;
  }

  body .navbar-dark .navbar-toggler-icon {
    height: 18px;
  }
}

.event-time-box {
  width: 25px;
  height: 25px;
  border-radius: 2px;
  color: #fff;
  font-family: $font-medium;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(180deg, #a23bc0 0%, #691c8c 100%);
  flex-direction: column;
  position: absolute;
  bottom: 10px;
  left: 40px;
  line-height: 1;

  &-day {
    font-size: 6px;
  }

  &-date {
    font-size: 10px;
  }
}

.article-page-carousel-top {
  circle.fill-primary {
    fill: #0034bb;
  }
}

.post-pic {
  .post-uplodad-label {
    display: flex;
    flex-wrap: wrap;
  }
}

.media-image {
  font-size: 16px;
  margin-right: 8px;
  opacity: 0.8;
  margin-bottom: 3px;
  display: inline-block;
}

.home-nav-logo {
  width: 200px;
}

.header-logo img {
  @media (min-width: $fhd-tab) {
    position: relative;
    top: -2px;
    max-height: 33px;
  }

  @media (min-width: $hd-tab) {
    position: relative;
    top: -2px;
    max-height: 33px;
  }
}

.active-link {
  .white-on-active {
    fill: #fff;
  }
}

.chat-edit-options {
  position: fixed;
  bottom: 50px;
  background-color: #fff;
  border-radius: 15px 15px 0 0;
  padding: 0 24px 10px;
  width: 100%;
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.5);
  animation: slideUp 0.3s ease-in-out;
  z-index: 1;
  left: 0;

  .option-view {
    padding: 16px;
    text-align: center;

    &:not(:last-child) {
      border-bottom: 1px solid #ccc;
    }
  }
}

.img-backdrop {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  backdrop-filter: blur(3px);
}

.msg-edit-option {
  input {
    background: #f5f5f5;
    border: none;
    height: 40px;
    width: calc(100% - 40px);
    border-radius: 15px 0 15px 15px;
    padding: 5px 10px;
    margin-top: 12px;
  }

  .w-50 {
    float: right;
  }

  +div:not(.msg-edit-options) {
    margin-left: 10px !important;

    span {
      min-width: 55px;
      display: inline-block;
    }
  }
}

.msg-edit-options {
  width: 50%;
  float: right;

  +div {
    margin-left: 10px !important;

    span {
      min-width: 55px;
      display: inline-block;
    }
  }
}

.chatCall {
  padding-top: 60px;

  .page-sidebar-body {
    padding: 15px !important;
  }

  // height: 100%;
  // .all-msgs {
  //   height: 70%;
  // }
}

.chat-search {
  position: relative;

  input {
    // position: absolute;
    // top:0px;
    border-radius: 15px !important;
    // box-shadow: 0 12px 19px 0 rgb(60 128 209 / 9%);
    height: 50px !important;
    padding: 25px 24px !important;
    width: 100% !important;
    margin-left: 0px !important;
    border: 1px solid#691c8c;
  }
}

.search-icon {
  position: absolute;
  top: 13px;
  left: 90%;
}

.header-text {
  color: $header-text !important;
}

.upload-text {
  color: $text-color;
}

.text-color {
  color: $text-color;
}

.web-notifications-outer {
  @media (max-width: $fhd-tab) {
    .btn-none {
      img {
        filter: invert(0);
      }
    }
  }
}

.courseSideBarExpanded {
  width: 20% !important;
}

.courseSideBarContainer {
  padding: 10px;
}

.expandedContainer {
  width: 20% !important;
}

.CoursePageExpantion {
  margin-left: 18% !important;
  width: 82% !important;

  // transition: none !important;
  @include for-tablet-portrait-up {
    width: 70% !important;
    margin-left: 30% !important;
  }
}

.Sidebar {
  position: fixed;
  top: 55px;
  overflow-x: hidden;
  left: 0px;
  bottom: 0;
  box-shadow: 3px 2px #c5c3c3;
  display: flex;
  flex-direction: column;
  width: 20%;
  height: 100%;
  background-color: #272262 !important;
  z-index: 35;
  margin-right: auto;

  @include for-tablet-portrait-up {
    width: 30%;
  }

}


.sidebar-logo {
  font-size: 16px;
}

.sidebar-icon {
  height: 1.25em;
  width: 1.25em;
  margin-right: 16px;
  user-select: none;

  cursor: pointer;
}

.sidebar-header {
  display: flex;
  align-items: center;
  padding: 16px;
}

.sidebar-selected-item-neighborhood {
  border-bottom: 4px solid #ff9900;
  padding-left: 10px;
  padding-right: 10px;
}

.sidebar-selected-item,
.sidebar-items {
  border-right: 5px solid #ff9900;
  background: #ffb366;
}

.sidebar-items {
  display: flex;
  flex-direction: column;
  // border-style: ridge;
  padding: 0 8px;
  border-width: 3px;
  // border-left-width: 5px;
  background-color: #272262 !important;

  @include for-tablet-portrait-up {
    padding: 0px;
  }
}

.main-heading {
  // background-color: white;
  width: 24%;
  text-align: center;
  padding-top: 8px;
  margin-bottom: 22px;
  // border: 2px solid #272262;
  // border-radius: 12px;

  @include for-tablet-portrait-up {
    width: 35%;
  }
}

.topbar-items {
  display: flex;
  flex-direction: row;
  padding: 10px 0;
}

.topbar-items-border,
.leftbar-items-border,
.leftbar-items-border-active {
  border-style: ridge;
  border-width: 1px;
  border-bottom: 5px solid #431a8f;
  background: rgba(129, 47, 194, 0.15);
}

.leftbar-items-border-active {
  border-bottom: none;
  border-left: 5px solid #431a8f;
}

.border-golden {
  border: 1px solid #ff9900 !important;
  color: white;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  margin-right: 5px !important;
}

.leftbar-items-border {
  border-bottom: none;
  border-left: 5px solid white;
  background: white;
}

.sidebar-items .item {
  display: flex;
  transition: background-color 0.2s;
  cursor: pointer;

  align-items: center;
  padding: 16px 8px;

  border-radius: 5px;

  @include for-tablet-portrait-up {
    padding: 7px 8px;
    border-radius: 0px;
  }
}

.Course-Sidebar {
  // overflow: auto;
  top: 110px;
  padding-bottom: 100px;
  z-index: 25;

  @include for-tablet-portrait-up {
    top: 115px;
    padding-bottom: 200px;
  }

  .sidebar-items {
    width: 100%;
    padding: 0 !important;

    .item {
      width: 100%;
      padding-left: 0px;
      padding-right: 0;

      .item-panal {
        width: 100%;

        .panal-head {
          margin-left: 2px;
        }
      }
    }
  }
}

.sidebar-items .item .sub-item {
  width: 100%;
  margin-top: 10px;
  padding: 16px 8px;
  // border-style: ridge;
  padding-left: 17px;
  border-bottom: 1px solid lightgray;

  @include for-tablet-portrait-up {
    padding: 7px 8px !important;
    border-radius: 4px !important;
  }

  .Sub-heading-section {
    margin-left: 13px;

    .section-heading {
      font-style: italic;
    }

    .section-sub-heading {
      position: relative;
      bottom: 5px;
      font-size: small;
      font-style: italic;
    }
  }
}

.sidebar-items .item .sub-chapter-item {
  .section-data {
    .chapterSection {
      margin-left: 13px;
      display: flex;
      font-weight: bold;

      svg {
        margin-right: 5px;
        color: lightgrey;
      }

      padding: 16px 0px;
    }
  }

  width: 100%;
  border-bottom: 1px solid lightgray;
}

.course-chapterSection {
  font-size: 16px;
}

// .sidebar-items .item:hover {
//   // background-color: rgba(255, 255, 255, 0.05);
// }

.sidebar-items-course {
  border-width: 0px !important;
  border-left-width: 5px !important;
}

.Sidebar.collapsed {
  width: 68px;
  z-index: 20 !important;
}

// .Sidebar.collapsed .sidebar-items {
//   border-left: none;
// }

.Sidebar.collapsed .sidebar-icon {
  margin-right: 0;
}

.Sidebar.collapsed .sidebar-text,
Sidebar.collapsed .sidebar-logo {
  display: none;
}

.sidebarIcon {
  border-radius: 50%;
  background-color: #d1d1d1;
  width: 20px;
  height: 20px;
  text-align: center;
}

.TopbarIcon {
  border-radius: 50%;
  background-color: #d1d1d1;
  width: 20px;
  height: 20px;
  text-align: center;
}

.sidebarItemBorderLeft {
  border: 3px solid #272262;

  // background-color: white;
  @include for-tablet-portrait-up {
    border: none;
  }
}

neighbourhood-nav .TopbarItemBorderLeft {
  border-left: 5px solid white;
  background-color: white;
}

.marginToContainer {
  margin-left: 20%;
  width: 80%;
}

.coursePageHeader {
  top: 55px;
  left: 0px;
  position: fixed;
  width: 100%;
  height: 60px;
  z-index: 30;
  padding: 5px;
  background-color: #272262;
  border-bottom-style: ridge;
}

.regionPageHeader {
  top: 55px;
  left: 0px;
  position: fixed;
  width: 100%;
  height: 65px;
  z-index: 20;
  padding: 5px;
  background-color: #fff;
  border-bottom-style: ridge;
}

.regionSidebar {
  margin-top: 60px;
}

.regionTopMargin {
  margin-top: 65px;
}

.courseSidebarIcon {
  width: 25px;
  height: 25px;
  position: relative;
  left: -2px;
  color: #ff9900;
}

.CourseHeaderIcon {
  width: 20px;
  height: 20px;
}

.CourseHeaderBtn {
  border-radius: 10px;
  padding: 0px 50px 0 50px;
}

abbr[title] {
  border-bottom: none !important;
  cursor: inherit !important;
  text-decoration: none !important;
}

.headerSearchBar {
  margin-right: -10px;
  border-right: none;
  outline: none;

  @include for-tablet-portrait-up {
    &::placeholder {
      font-size: 5px !important;
    }
  }

}

.courseCertificate {
  background: rgb(241, 240, 240);
  border-radius: 10px;
  text-align: center;
  width: 300px;
}

.certificateTitle {
  font-family: "Tangerine", serif;
  font-size: xx-large;
}

.assignmentPage {
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 21;
  padding-bottom: 50px;
}

.createPage {
  top: 120px;
  right: 0;
  bottom: 0;
  z-index: 99;
  padding-bottom: 0px;
  width: 75% !important;
  border-left: 1px solid #cacaca !important;
}

.multi-line {
  height: 120px !important;
}

.course_panal_header {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 15px;
  padding-left: 50px;
  border-bottom: 2px solid lightgrey;

  .courseSidebarIcon {
    margin-right: 5px;
    font-size: large;
  }

  .btn-primary {
    min-width: 100px;
    height: 20px;
  }

  .header_title {
    font-weight: normal;
    font-size: 16px;
  }
}

.comment_body {
  margin-left: 21px !important;
  overflow-wrap: break-word !important;
  margin-bottom: 0.5rem !important;
}

.text-avatar.sb-avatar {
  margin-top: 2px;
}

.text-avatar.userAvtar {
  margin-top: 2px;
}

.comment_container {
  width: 100%;
  padding: 18px 23px 18px 0px;
  // padding: 0px 20px 20px 20px;
  padding-left: 20px;

  .comment_date {
    font-size: large;
    display: flex;
    font-style: italic;
    width: 100%;
    color: gray;
    justify-content: center;
  }
}

.pr-10 {
  padding-right: 10px !important;
}

.commentheader {
  display: flex;
  width: 100%;

  .comment-name {
    font-size: 13px;
  }

  .commentUserData {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;

    // width: 84%;
    .userAvtar {
      margin-right: 2px;
      max-width: 15px;
      max-height: 15px;
    }
  }

  .ChatcommentUserData {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    width: 84%;

    .userAvtar {
      margin-right: 2px;
      max-width: 15px;
      max-height: 15px;
    }
  }

  .commentTime {
    display: flex;
    margin-left: auto;
    width: fit-content;
    align-items: center;
  }
}

.commentBodyContainer {
  margin-top: 10px;
  width: 100%;
  border-radius: 20px;
  background-color: #f7f7f7;
  padding: 20px 20px 20px 20px;
  text-align: justify;

  .comment_head {
    // margin-left: auto;
    position: relative;
    // bottom: 20px;
    left: 10px;
    // margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }
}

.discussionCount {
  width: 200px;
  background-color: #f39e00;
}

.menu-container {
  z-index: 1;
  background-color: rgb(238, 234, 234);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;
  width: 12em;
  right: 0;
  height: 12em;
  top: 0;
  border-radius: 20px;

  .menu_item_data {
    font-weight: 500;
    font-size: large;

    &.delete {
      position: absolute;
      bottom: 10px;

      color: orange;
    }

    &.edit {
      display: flex;
      justify-content: space-between;
    }

    &.report {
      display: flex;
      justify-content: space-between;
    }
  }
}

.menu-containerReport {
  z-index: 1;
  background-color: rgb(238, 234, 234);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;
  width: 12em;
  right: 0;
  top: 0;
  border-radius: 20px;

  .menu_item_data {
    font-weight: 500;
    font-size: large;

    &.report {
      display: flex;
      justify-content: space-between;
    }
  }
}

.commentMenu {
  // margin-left: 20px;
  // margin-top: 4px;
  align-items: center;
  display: flex;
  font-size: large;

  .ReplyIcon {
    margin-right: 10px;
  }

  .moreDrop {
    display: flex;
    margin-left: 0px;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
  }

  svg {
    font-size: 20px;
    max-width: none;
  }
}

.commentFooter {
  width: 100%;
  display: flex;

  .reactionContainer {
    margin-left: auto;
    font-size: 20px;

    svg {
      margin-left: 5px;
      width: 20px;
      height: 20px;
    }
  }
}

.commentLike {
  display: flex;
  position: relative;
  margin-left: auto;
  bottom: 3px;

  .reactionContainer {
    margin-left: auto;
    font-size: 20px;

    svg {
      margin-left: 5px;
      width: 20px;
      height: 20px;
    }
  }
}

.post-comments {
  width: 95%;

  .comment-replies {
    width: 95%;
    margin-left: auto;
  }
}

.comment-input {
  padding-left: 50px;
  padding-right: 75px;
  margin-top: 30px;

  .demo-wrapper {
    height: 200px !important;
    padding-left: 30px;
    padding-top: 30px;
    border-left: 1px solid #dddddd !important;
    border-right: 1px solid #dddddd !important;
    border-top: 1px solid #dddddd !important;

    box-shadow: inset 5px 5px 6px 0px rgba(221, 221, 221, 1);

    .demo-editor {
      height: 110px !important;
      margin-top: 10px;
    }
  }

  .rdw-editor-toolbar {
    width: 87%;
    box-shadow: 5px 5px 6px 0px rgba(221, 221, 221, 1);
  }

  .reply-container {
    width: 90%;
    margin-left: auto;
  }
}

.comment-inline-input {
  padding-right: 26px;
  padding-left: 0 !important;
}

.chatBox-wrapper-class {
  height: 65vh !important;
}

.chatBox {
  height: 85vh;
  overflow-y: scroll;
}

.commentAnchor {
  .public-DraftStyleDefault-block {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

.commentAnchor,
.replyChatBox {
  //   position: sticky;
  //   bottom: 0;
  //   height: 50px;
  background: white;
  margin-top: 15px;
  height: 190px;

  @include for-tablet-portrait-up {
    margin-bottom: 110px;
  }

  .demo-wrapper {
    padding-top: 10px;
    height: 161px !important;
    // overflow-y: scroll;

    @include for-tablet-portrait-up {
      height: 216px !important;
    }

    .demo-editor {
      height: 48px !important;
      overflow-y: scroll;

      .DraftEditor-root {
        margin-top: 0 !important;
      }
    }
  }
}

.replyChatBox {
  margin-top: 0;

  .public-DraftStyleDefault-block {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

// .chatBoxHeader {
//   position: sticky;
//   top: 0;
//   background: white;
// }

.chatBoxChats {
  // z-index: -1;
  position: relative;
}

.chatBox::-webkit-scrollbar {
  width: 0.4em !important;
}

.chatBox::-webkit-scrollbar-thumb {
  background-color: #272262;
  outline: 1px solid #ccc;
}

.toolsContainer {
  position: relative;
  bottom: 45px;

  .toolsIcon {
    font-weight: bold;
    width: 30px;
    margin-left: 10px;
    height: 30px;
  }
}

.editor {
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: text;
  padding: 16px;
  border-radius: 2px;
  margin-bottom: 2em;
  box-shadow: inset 0px 1px 8px -3px #ababab;
  background: #fefefe;
}

.editor :global(.public-DraftEditor-content) {
  min-height: 140px;
}

.course-community-search {
  margin-left: -14px;
}

.disabled-panal-container {
  color: lightgray !important;
}

.sentIconContainer {
  width: 40px;
  height: 40px;
  padding: 5px;
  border-radius: 50%;
  margin-bottom: 20px;
  position: relative;
  bottom: 4px;
  background-color: #272262;
  color: white !important;
}

#videoInput {
  display: none;
}

#imageInput {
  display: none;
}

#docInput {
  display: none;
}

#attachInput {
  display: none;
}

.filledLike {
  background-color: #272262;
  border-radius: 4px;
}

.courseSidebarCard {
  width: 230px;
  height: 250px;
  margin: 10px;
  border-radius: 10px;
  background-color: #272262;
  color: white;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.32);
}

.courseSidebarCardIcon {
  width: 90px;
  height: 90px;
}

.courseSidebarFlex {
  border-bottom: 2px solid #e4e4e4;
  border-top: 2px solid #e4e4e4;
  padding: 10px 0 10px 0;
  padding-left: 20px;
}

.courseSidebarFlex::-webkit-scrollbar {
  display: none;
}

.courseSidebarToggle {
  background-color: white;
  color: black;
}

.course-input-group {
  display: flex;

  input {
    width: 100%;
    height: 50px !important;
    border-radius: 15px;
    margin-bottom: 10px;
  }
}

.course-search-icon {
  font-size: 35px;
}

.course-card-wrapper {
  width: 260px;
}

.course-card {
  // box-shadow: 2px 9px 13px 0px rgba(39, 34, 98, 0.22);
  box-shadow: 0px 2px 4px rgba(39, 34, 98, 0.220908);
  min-width: 260px;
  height: 270px;
  border-radius: 20px;

  .image-container {
    .course-image {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      min-width: 260px !important;
      min-height: 68% !important;
      max-height: 50% !important;
    }
  }

  .no-image-course {
    height: 68%;
  }

  .course-card-content {
    display: flex;
    // overflow: auto;
    flex-direction: column;
    height: 100px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #272262;
    // justify-content: center;
    // align-items: center;
    border: 3px solid #ff9900;

    .course-category-container {
      margin-top: 10px;
      font-weight: bold;
      font-size: 14px;
      height: 20px;
      margin-bottom: 16px;
    }

    .course-title-container {
      text-overflow: ellipsis;
      // overflow: hidden;
      // white-space: nowrap;
      white-space: normal;
      margin-top: 12px;
      font-size: 22px;
      font-weight: bold;
      color: white;
      font-family: 'Times New Roman', Times, serif;
      font-weight: 600;
      padding-bottom: 25px;
    }
  }
}

.course-lesson-container {
  position: relative;
  bottom: 28px;
  left: 20px;
  font-weight: 400;
  font-size: 12px;
  color: #ffb366;
}

.card-container {
  // margin-left: 5px;
  overflow-x: auto;
  padding-bottom: 10px;
  // width: 100%;
  // padding-bottom: 20px;
  // padding-right: 20px;
}

@media (min-width: $hd-tab) {
  .card-container::-webkit-scrollbar {
    height: 0.4em !important;
  }

  .card-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .card-container::-webkit-scrollbar-thumb {
    background-color: $primary;
    outline: 1px solid #ccc;
  }
}

.no-image-container {
  width: 250px;
  justify-content: center;
  border-right: 4px solid #ff9900;
}

.no-image-container-news {
  width: 70%;
  justify-content: center;
  border-right: 4px solid #ff9900;
}

.course-details-header-container {
  margin-top: 9px;
  width: 100%;
  display: flex;
  position: sticky;
  top: 7.5%;
  background-color: white;
  z-index: 12;
  align-items: center;
  border-bottom: 2px solid lightgrey;
  height: 4em;

  .panal-container {
    height: 100%;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;

    font-size: 16px;
  }

  .active-panal {
    background-color: lightgrey;
    font-weight: bold;
  }
}

.profilePopUp {
  z-index: 999 !important;
  position: fixed;
  top: 0;
  left: 0;
}

.answered-assignment-wrapper {
  padding: 23px;
  border-radius: 10px;
  background-color: #f7f7f7;

  .answer-wrapper {
    font-size: 14px;
  }
}

.course-data-outer {
  padding: 35px 45px 0 34px;
}

.assignment-heading {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  @include for-tablet-portrait-up {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.article-data-outer {
  .lesson-assignment-container {
    .assignment-questions-container {
      .sub-item {
        margin-bottom: 30px;

        .Sub-heading-section {
          .section-heading {
            font-style: italic;
            font-size: 16px;
            line-height: 19px;
            color: #23364a;
          }

          .section-sub-heading {
            font-style: italic;
            font-size: 12px;
            line-height: 19px;
            color: #23364a;
          }
        }
      }
    }
  }
}

.assignment-common-styling {
  box-shadow: 2px 2px 4px 1px rgba(39, 34, 98, 0.220908) !important;
}

.course-editor-wrapper {
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.5);
}

.share-check-wrapper {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;

  .share-text {
    font-size: 16px;
    font-weight: normal;
    margin-left: 30px;
  }
}

.save-answer-panal {
  button {
    height: 40px;
    width: 192px;
  }
}

.lesson_answer_padding {
  padding-left: 40px;
  padding-right: 20px;
}

.discuss-wea-logo {
  margin-left: 10px;
  margin-right: 10px;
  max-width: 24.28px;
  max-height: 23.18px;
}

.course-section-heading {
  font-style: italic;
  font-size: 16px;
  line-height: 19px;
  color: #23364a;
  font-weight: normal !important;
}

.CommentTool-wrapper {
  z-index: 1;
  position: relative;
  bottom: 46px;
  left: 26px;
}

.comment-file-input {
  display: none;
}

.send-comments {
  position: relative;
  bottom: 9px;
  right: 45px;
}

.media-container {
  margin-bottom: 10px;
  z-index: 1;
  bottom: 40px;
  /* position: relative; */
  padding-top: 5px;
  padding-left: 13px;
  margin-left: 17px;
  /* padding-bottom: 5px; */
  width: 500px;
  background-color: #f7f7f7;
  font-size: 16px;
  font-weight: bold;
  left: 0;
  position: absolute;

  .close-media-icon {
    width: 12px;
    display: flex;
  }
}

.comment-media {
  width: 97.4%;
  margin-left: auto;
  border-radius: 10px;

  .player-wrapper {
    width: 100%;
  }

  .comment-react-player {
    margin-top: 10px;
    border-radius: 20px;
    overflow: hidden;
    height: 460px !important;
  }

  .comment_media_head {
    position: relative;
    right: 87px;
    top: 11px;

    .dropdown {
      .commentMenu {
        .ReplyIcon {
          background-color: #f7f7f787;
          border-radius: 5px;
        }

        .moreDrop {
          border-radius: 5px;
          background-color: #f7f7f787;
        }
      }
    }
  }
}

.comment-only-media {
  border-radius: 10px;

  .player-wrapper {
    width: 100%;
  }

  .comment-react-player {
    border-radius: 20px;
    overflow: hidden;
    height: 460px !important;
  }

  .comment_media_head {
    position: absolute;
    right: 25px;

    .dropdown {
      .commentMenu {
        .ReplyIcon {
          background-color: #f7f7f787;
          border-radius: 5px;
        }

        .moreDrop {
          border-radius: 5px;
          background-color: #f7f7f787;
        }
      }
    }
  }
}

.ReplyIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 24px;
}

.moreDrop {
  width: 19px;
  height: 24px;
}

.profilePopUpPage {
  top: 0;
  left: 0;
  right: 0;
  z-index: 21;
}

.article-card-image {
  object-fit: cover;
}

.courseHeaderHeading {
  color: rgb(66, 65, 65);
  position: relative;
  top: 3px;
}

.articleIcon {
  width: 25px;
  height: 25px;
  color: #000;
}

.homeIcon {
  width: 30px;
  height: 27px;
}

.paddingHomeIconExpanded {
  padding-left: 1px;
  padding-right: 2px;
}

.paddingHomeIcon {
  padding-left: 12px;
}

.circle {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  // padding-left: 2%;
  position: relative;
  font-size: 13px;
}

.circle-communities {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  border: 6px solid #C5C5C5; // padding-left: 2%;
  position: relative;
  font-size: 13px;
  // padding-top: 0 !important;
}

.circleImage {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-top-card {
  height: 450px;
  width: 100%;
  // background-image: url(../assets/images/homeBackground.png);
  display: flex;

  .home-card {
    background-color: #ffffff;
    border: 1px solid #d2d2d2;
    width: 85%;
    height: 380px;
    border-radius: 3px;
    margin: auto;
  }
}

.home-dropdown {
  height: 50px !important;

  padding: 0 !important;
  width: 380px !important;
  border-radius: 5px;
}

.dropdown-title {
  font-style: italic;
}

.signup-drop-menu:active {
  color: white !important;
}

.home-dropdown .btn-none {
  padding: 0 !important;
  min-height: 50px;
}

.goto-button {
  height: 50px;
}

.community-page {
  width: 90vw;
}

.menu-overflow {
  height: 400px;
  overflow-y: scroll;
}

.signup-menu-overflow {
  height: 260px;
  overflow-y: scroll;
}

.client-profile-outer {
  background-color: $bg-light-grey;
}

.profile-width {
  width: 67%;
  margin-left: 6px;
  // padding-left: 6px;
  // padding-right: 6px;

  @include for-tablet-portrait-up {
    width: 100%;
    display: block;
    margin: 0 auto;
    padding-left: 0px;
    padding-right: 0px;
  }
}

.width-discussion {
  width: 30%;
  margin: 6px;

  @include for-tablet-portrait-up {
    width: 100%;
    display: block;
    margin: 0 auto;
    margin-bottom: 44px;
  }
}

.display-profile {
  @include for-tablet-portrait-up {
    display: flex;
    flex-direction: column;
  }
}

.w-70 {
  width: 70%;
}

.w-30 {
  width: 30%;
}

.testimonial-card {
  height: 240px;
  // width: 360px;
  border-radius: 15px;

  @include for-tablet-portrait-up {
    width: 330px;
  }
}

.paddingHomeIcon {
  padding-left: 13px;
}

.heart {
  position: relative;
  top: 15px;
  left: 323px;
  width: 25px;
  height: 22px;
  color: #ababab;
}

.image {
  top: 37px;
  left: 130px;
  right: 130px;
  bottom: 103px;
}

.regionSearchBar {
  position: absolute;
  left: 1100px;
}

.imogi {
  position: absolute;
  width: 315px;
  height: 40px;
  left: 1390px;
}

.imageRegion {
  display: block;
}

.membersParagraph {
  position: absolute;
  height: 22px;
  left: 0%;
  right: 0%;
  top: calc(50% - 4px / 2 + 73.5px);
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
}

.regionImage {
  left: 0.06%;
  right: 98.84%;
  top: 4.1%;
  bottom: 86.89%;
  height: 25px;
}

.addIconStyling {
  width: 20px;
  height: 30px;
}

.regionIcon,
.circleIcon {
  width: 27px;
  height: 27px;
}

.circleIcon {
  color: #b6b6b6;
}

.card-title {
  padding-bottom: 20px;
  color: white;
}

// .regionCard {
//   width: 110px;
// }

.client-profile-outer {
  background-color: $bg-light-grey;
}

.w-67 {
  width: 67%;
}

.w-30 {
  width: 30%;
}

.w-90 {
  width: 90% !important;
}

.profileMainContainer {
  height: 38rem;
  background-color: $white;
  border-radius: 15px;
}

.profile-page-userAvatar {
  .sb-avatar__text {
    border: 3px solid #ff961e;
  }

  .sb-avatar__image {
    border: 3px solid #ff961e;
  }
}

.profile-page-userImage {
  width: 12.5rem !important;
  height: 12.5rem !important;
  border: 3px solid white;
}

.profile-page-userAvatar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 10rem;
}

.profile-page-banner-container {
  height: 300px;
  width: 100%;
  object-fit: scale-down;
  background-color: #000;
}

.fs-36 {
  font-size: 36px;
}

.ff-Inter-Bold {
  font-family: "Inter_bold";
}

.profile-user-location {
  color: $dark-orchid;
}

.profile-user-headline {
  font-size: 24px;
  color: #383838;
}

.btn-bg-white {
  background-color: white;
  color: #422a66;
}

.CustomProfileButtonStyle {
  border-radius: 10px;
  font-size: 16px;
  width: 40%;
  padding: 15px 0 15px 0;
}

.leave-community {
  border-radius: 10px;
  font-size: 16px;
  width: 100%;
  padding: 15px 0 15px 0;

  @include for-tablet-portrait-up {
    width: 80%;
    display: block;
    margin: 0 auto;
  }
}

.add-members {
  border-radius: 13px;
  font-size: 16px;
  width: 100%;
  padding: 15px 0 15px 0;

  @include for-tablet-portrait-up {
    width: 80%;
    display: block;
    margin: 0 auto;
  }
}

.news-filter-dropdown {
  width: 22% !important;
  font-size: 16px !important;
  border-radius: 10px;
  // border: 3px solid #ff9900;
  background-color: #272262 !important;
  color: #ff9900 !important;
}

.news-filter {
  padding: 3px;
  font-size: 16px !important;
  border-radius: 10px;
  // border: 3px solid #ff9900;
  background-color: #272262 !important;
  color: #ff9900 !important;
}

.delete-doc-modal {
  border-radius: 6px;
  font-size: 16px;
  width: 25% !important;
  // padding: 15px 0 15px 0;
  height: 45px !important;

  @include for-tablet-portrait-up {
    // width: 80%;
    display: block;
    margin: 0 auto;
  }
}

.news-icon-cancel {
  width: 22%;
  padding-right: 200px;
  cursor: pointer;

  @media (min-width:1500px) {
    padding-right: 300px !important;
  }

  @include for-tablet-portrait-up {
    width: 19%;
    padding-right: 100px;
  }
}

.delete-doc-modal-btn {
  border-radius: 10px;
  font-size: 16px;
  width: 10% !important;
  height: 50px !important;
  margin-right: 115px !important;

  @media (min-width:1300px) and (max-width:1500px) {
    margin-right: 150px !important;
  }

  @media (min-width:1500px) {
    margin-right: 200px !important;
  }

  @include for-tablet-portrait-up {
    display: block;
    margin-right: 20px !important;
  }
}

.AboutMainContainer {
  margin-top: 1rem;
  background-color: $white;
  border-radius: 15px;
  padding: 31px 50px 31px 50px;
}

.eventContainer {
  margin-top: 1rem;
  background-color: $white;
  border-radius: 15px;
  padding: 2rem 3rem 2rem 3rem;
  max-height: 113vh;
  display: grid;

  row-gap: 30px;
  grid-template-columns: repeat(2, minmax(auto, 1fr));
}

.newsContainer {
  margin-top: 1rem;
  background-color: $white;
  border-radius: 15px;
  padding: 2rem 3rem 2rem 3rem;
  max-height: 117vh;
  display: grid;

  row-gap: 30px;
  grid-template-columns: repeat(2, minmax(auto, 1fr));
}

.section-head {
  font-size: 24px;
  color: #383838;
}

.about-description {
  font-size: 14px;
  color: black;
}

.section-head {
  color: #422a66;
  font-size: 18px;
  font-weight: 700;
}

.section-subHead {
  color: #422a66;
  font-size: 18px;
}

.qualitySelectorContainer {
  border-radius: 8px;
  font-size: 16px;
  padding: 10px 22px 10px 22px;
  margin: 10px;
  display: flex;
  font-weight: 700;
  flex-wrap: wrap;

  &.unSelected {
    color: #383838;
    background-color: white;
    border: 1px solid #c6c5db;
  }

  &.selected {
    color: white;
    border: 1px solid #272262;
    background-color: #272262;
  }
}

.addIconStyling {
  width: 20px;
  height: 30px;
}

.regionIcon {
  width: 27px;
  height: 27px;
}

.card-title {
  padding-bottom: 20px;
}

.region-tab {
  overflow-x: auto !important;
  width: 100%;
}

.regionCard-community {
  word-break: keep-all !important;
  height: 15px;
  width: 124px;
  text-align: center !important;
}

.regionCard {
  word-break: break-all !important;
  height: 15px;
  width: 120px;
  text-align: center !important;
}

.small-region-name {
  padding-top: 0 !important;
  justify-content: center;
}

.long-region-name {
  padding-top: 25px;
}

.course-card-skeleton {
  margin-left: 48px;
}

.news-card {
  box-shadow: 0px 2px 4px rgba(39, 34, 98, 0.220908);
  width: 540px;
  min-width: 540px;
  display: flex;
  height: 280px;
  border-radius: 24px;
  border: 3px solid #272262;

  // @media (min-width : 1400px) {
  //   width: 85%;
  // }

  .image-container {
    max-width: 40% !important;
    min-width: 40% !important;
    border-right: 4px solid #ff9900;

    .course-image {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      min-width: 260px !important;
      min-height: 100% !important;
      max-height: 100% !important;
      max-width: 100% !important;
      min-width: 100% !important;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }

  .news-card-content {
    position: relative;
    display: flex;
    white-space: normal;
    flex-wrap: wrap;
    overflow: auto;
    flex-direction: column;
    height: 100%;
    width: 100%;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding-left: 20px;
    padding-right: 20px;

    .course-category-container {
      margin-top: 10px;
      font-weight: bold;
      height: 20px;
      font-size: 14px;
      margin-bottom: 16px;
    }

    .course-title-container {
      margin-top: 12px;
      font-size: 20px;
      font-weight: bold;
    }
  }
}

.news-card-vertical {
  // box-shadow: 2px 9px 13px 0px rgba(39, 34, 98, 0.22);
  box-shadow: 0px 2px 4px rgba(39, 34, 98, 0.220908);
  height: 280px;
  min-height: 280px;
  display: flex;
  flex-direction: column;
  width: 280px;
  border-radius: 20px;

  .image-container {
    max-height: 50% !important;
    min-height: 50% !important;

    .course-image {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      min-width: 260px !important;
      min-height: 100% !important;
      max-height: 100% !important;
      max-width: 100% !important;
      min-width: 100% !important;
    }
  }

  .news-card-content {
    position: relative;
    display: flex;
    white-space: normal;
    overflow: auto;
    flex-direction: column;
    height: 50%;
    width: 100%;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding-left: 20px;
    padding-right: 20px;

    .course-category-container {
      margin-top: 10px;
      font-weight: bold;
      height: 20px;
      font-size: 14px;
      margin-bottom: 16px;
    }

    .course-title-container {
      margin-top: 12px;
      font-size: 20px;
      font-weight: bold;
    }
  }
}

.checkbox-round {
  width: 1em;
  height: 1em;
  border-radius: 50%;
  vertical-align: middle;
  border: none;
  background-color: #d8d8d8;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.check-container {
  position: absolute;
  bottom: 0.5rem;
  color: #ff9900;
  font-weight: 600;
}

.text-golden {
  color: #ff9900;
}

.checkbox-round:checked {
  appearance: none;
  clip-path: circle(50% at 50% 50%);
  background-color: #422a66 !important;
}

.events-card {
  box-shadow: 0px 2px 4px rgba(39, 34, 98, 0.220908);
  width: 320px;
  min-width: 320px;

  height: 270px;
  border-radius: 20px;

  .image-container {
    .events-image {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      min-width: 320px !important;
      min-height: 50% !important;
      max-height: 50% !important;
    }
  }

  .events-card-content {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    // height: 150px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding-left: 20px;
    padding-right: 20px;
    height: 135px;
    border: 3px solid #ff9900;
    background-color: #272262;

    .course-category-container {
      margin-top: 10px;
      font-weight: bold;
      height: 20px;
      font-size: 14px;
      position: relative;
      left: 75px;
      margin-bottom: 2px;
    }

    .course-title-container {
      margin-top: 12px;
      font-size: 22px;
      font-weight: bold;
      color: white;
      font-family: 'Times New Roman', Times, serif;
      font-weight: 600;
    }
  }

  .profile-event-time-box {
    width: 4.188rem;
    box-shadow: 0px 2px 4px rgba(84, 104, 255, 0.3);
    position: absolute;
    height: 4.188rem;
    border-radius: 6px;
    color: #422a66;
    font-family: "Inter_med";
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    flex-direction: column;
    line-height: 1;
    bottom: 40%;
    margin-right: 10px;

    &-day {
      font-size: 14px;
    }

    &-date {
      font-size: 32px;
    }
  }

  .event-attand-button {
    height: 28px;
    border-radius: 8px;
    font-size: 14px;
    margin-left: auto;
  }
}

.profile-right-event-time-box {
  width: 4.188rem;
  box-shadow: 0px 2px 4px rgba(84, 104, 255, 0.3);
  height: 4.188rem;
  border-radius: 6px;
  color: #422a66;
  font-family: "Inter_med";
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  flex-direction: column;
  line-height: 1;
  bottom: 40%;
  margin-right: 10px;

  &-day {
    font-size: 14px;
  }

  &-date {
    font-size: 32px;
  }
}

.font-10 {
  font-size: 10px;
}

.w-10 {
  width: 10px;
}

.RightSectionCardMainContainer {
  // margin-top: 1rem;
  background-color: $white;
  border-radius: 15px;
  padding: 28px 0px 28px 0px;

  @include for-tablet-portrait-up {
    padding-left: 3rem;
    padding-right: 2rem;
  }
}

.profile-right-section-head {
  color: #422a66;
  font-size: 24px;
  margin: 0 10px 0 20px;
  font-weight: 700;
}

.course-title-container::first-letter {
  text-transform: capitalize;
}

.unCheckBoxreplica {
  width: 25px;
  height: 25px;
  background-color: #d8d8d8;
  border-radius: 50%;
}

.event-sub-chapter-item {
  padding: 10px 10px 8px 20px;

  .chapterSection {
    font-size: 16px;
    color: #23364a;
  }
}

.event-sub-chapter-item:hover {
  background-color: rgba(129, 47, 194, 0.15);
  border-left: 1px solid #812fc2;
}

.search-communities {
  width: 25%;

  @include for-tablet-portrait-up {
    width: 35%;
  }
}

.SeactionSearchContainer {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: center;

  .create-dicussion-btnd {
    border-radius: 10px;
  }

  .SeactionSearch {
    border: none;
    outline: none;
    width: 90%;
    font-size: 16px;
  }

  .SeactionSearch-news-filter {
    width: 90%;
    font-size: 16px;
    padding: 10px;
    border-radius: 10px;
    background-color: #272262 !important;
    color: #ff9900 !important;
    outline: none;
    border: none;

    &::placeholder {
      color: #ff9900 !important;
    }
  }

  .SeactionSearchPeers {
    border: none;
    outline: none;
    width: 85%;
    font-size: 16px;

    @include for-tablet-portrait-up {
      width: 80%;
    }
  }

  .SearchMembersSection {
    margin-left: 1.5rem !important;

    @include for-tablet-portrait-up {
      margin-left: 0rem !important;
    }
  }

  .AdduserToGroupInput {
    width: 100% !important;
  }

  .searchSectionWrapper {
    font-size: 16px;
    height: 40px;
    display: flex;
    align-items: center;
    width: 100%;
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.5);
    padding: 6px 19px 6px 19px;
    border-radius: 10px;

    @include for-tablet-portrait-up {
      width: 90%;
    }
  }

  .searchSectionWrapperHeader {
    font-size: 16px;
    height: 40px;
    display: flex;
    align-items: center;
    width: 100%;
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.5);
    padding: 6px 19px 6px 19px;
    // border: 3px solid #ff9900;
    border-radius: 10px;

    @include for-tablet-portrait-up {
      width: 90%;
    }
  }

  .searchSectionWrapperPeers {
    font-size: 16px;
    height: 40px;
    display: flex;
    align-items: center;
    width: 100%;
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.5);
    padding: 6px 19px 6px 19px;
    border-radius: 10px;
  }

  .searchSectionWrapperMembers {
    font-size: 16px;
    height: 40px;
    display: flex;
    align-items: center;
    width: 100%;
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.5);
    padding: 6px 19px 6px 19px;
    border-radius: 10px;

    @include for-tablet-portrait-up {
      width: 90%;
      padding: 4px;
    }
  }

  .courses-input {
    width: 25%;

    @include for-tablet-portrait-up {
      width: 50%;
    }
  }

  // min-width: 10px;
  // font-size: 10px;
  // min-height: 10px;

  .searchSectionWrapper:focus {
    border: 2px solid #422a66;
  }

  .searchSectionWrapperHeader:focus {
    border: 2px solid #422a66;
  }
}

.SearchMembersSection {
  margin-left: 1.5rem !important;

  @include for-tablet-portrait-up {
    margin-left: 0rem !important;
  }
}

.box-shadow-create {
  border: none;
  outline: none;
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.5) !important;
  border-radius: 5px !important;
}

.focus-border {
  border-bottom: 4px solid #ff9900;
}

.font-18 {
  font-size: 18px;
}

.Profile-InfoCard {
  width: 100%;
  box-shadow: 0px 2px 4px rgba(39, 34, 98, 0.220908);
  background-color: #fff;
  padding: 10px;
  border-radius: 15px;

  .Profile-InfoCard-infoSection {
    display: flex;
    flex-direction: column;
    margin-right: 6px;
    justify-content: center;

    .Profile-InfoCard-Heading {
      font-size: 16px;
      color: #272262;
      font-weight: 700;
    }

    .Profile-InfoCard-subHeading {
      font-size: 10px;
      color: #383838;
    }
  }

  .Profile-InfoCard-rightSection {
    margin-left: auto;
    font-size: 25px !important;
  }
}

.font-color-primary {
  color: #422a66;
}

.font-16 {
  font-size: 16px;
}

.activity-cards-container {
  margin-top: 5px;

  .activity-name {
    display: flex;
    font-size: 18px;
    margin-top: 10px;
    font-weight: 400;
    color: #383838;

    .activity-actionName {
      font-weight: 400;
      margin-right: 7px;
    }
  }
}

.w-fit {
  width: fit-content;
}

.no-found {
  padding-left: 106px;
}

.object-fit-contain {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.container-overflow {
  @extend .scroll-y;

  &::-webkit-scrollbar {
    width: 4px;
    height: 6px;
  }

  width: 100%;
  overflow-x: auto;
}

.sidebar-icon {
  width: 20px;
  height: 20px;
}

.edit-profile-formContainer {
  position: relative;
  // top: 65%;
  bottom: 8rem;
  margin-left: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;

  @include for-tablet-portrait-up {
    margin-left: 0 !important;
  }

  &-form {
    width: 66.66%;

    &-form_heading {
      font-size: 18px;
      font-weight: 700;
    }
  }
}

.field-common-styling {
  margin-bottom: 10px;
  box-shadow: inset 0px 1px 3px rgba(195, 146, 146, 0.5);
  border-radius: 15px;
  font-size: 15px;
  background-color: #fff;
  outline: none;
  border: none;
  font-style: normal;
  // font-weight: 700;
  color: #000;

  .MuiOutlinedInput-notchedOutline {
    display: none;
  }

  .autoComplete-textfield-styling {
    input::placeholder {
      font-style: normal !important;
      // font-weight: 700 !important;
      color: #000 !important;
    }
  }

  .autoComplete-textfield-styling-hideplaceHolder {
    input::placeholder {
      color: transparent;
    }
  }
}

.field-common-styling-neighbourhood {
  margin-top: 15px;
  margin-bottom: 15px;
  box-shadow: 0 12px 19px 0 rgba(60, 128, 209, 0.09);
  border: 1px solid #cacaca;
  border-radius: 15px;
  font-size: 15px;
  font-style: normal;
  color: #000;

  .MuiOutlinedInput-notchedOutline {
    display: none;
  }

  .MuiInputBase-input {
    height: 0px !important;
    padding: 25px !important;
    padding-left: 10px !important;
    margin-bottom: 0px !important;
    background-color: #FDFDFD !important;
    box-shadow: none !important;
    border-radius: 0px !important;
    ;
  }

  .autoComplete-textfield-styling {
    input::placeholder {
      color: #344356 !important;
      opacity: 1 !important;
      font-size: 18px !important;
    }
  }
}

.field-common-styling-networks {
  margin-top: 15px;
  margin-bottom: 15px;
  box-shadow: 0 12px 19px 0 rgba(60, 128, 209, 0.09);
  border: 1px solid #cacaca;
  border-radius: 15px;
  font-size: 15px;
  font-style: normal;
  color: #000;

  .MuiOutlinedInput-notchedOutline {
    display: none;
  }

  .MuiInputBase-input {
    height: 0px !important;
    padding: 20px !important;
    padding-left: 10px !important;
    margin-bottom: 0px !important;
    background-color: #FDFDFD !important;
    box-shadow: none !important;
    border-radius: 0px !important;
    ;
  }

  .autoComplete-textfield-styling {
    input::placeholder {
      color: #344356 !important;
      opacity: 1 !important;
      font-size: 18px !important;
    }
  }
}

.field-common-check-label {
  font-size: 21px;
  font-weight: 500;
  color: #000;
}

.field-common-check-styling {
  position: relative;
}

.field-common-check-styling label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.field-common-check-styling label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.field-common-check-styling input[type="checkbox"] {
  visibility: hidden;
}

.field-common-check-styling input[type="checkbox"]:checked+label {
  background-color: #41075c;
  border-color: #41075c;
}

.field-common-check-styling input[type="checkbox"]:checked+label:after {
  opacity: 1;
}

.field-common-text-area {
  resize: none;
  height: 220px !important;
}

.mt-30 {
  margin-top: 30px;
}

.field-common-styling::placeholder {
  font-style: normal !important;
  // font-weight: 700;
  color: rgb(116, 116, 116);
}

.w-60 {
  width: 60%;
}

.EditProfile-page-sidebar-form-container {
  @include for-tablet-portrait-up {
    width: 90%;
  }
}

.p {
  &-l-20 {
    padding-left: 20px;
  }

  &-r-20 {
    padding-right: 20px;
  }

  &-t-20 {
    padding-top: 20px;
  }

  &-b-20 {
    padding-bottom: 20px;
  }
}

.mt-15 {
  margin-top: 15px;
}

.CommunitySection {
  display: flex;

  background-color: white;
  overflow-y: hidden;

  &-ChatDisplays {
    width: 79.91%;
  }

  &-userNavigation {
    width: 20.09%;

    @include for-tablet-portrait-up {
      width: 35% !important;
    }
  }

  @media (max-width: 820px) {
    &-ChatDisplays {
      width: 65%;
    }

    &-userNavigation {
      width: 35%;
    }
  }

  &-right {
    padding: 0px 30px 0px 31px;

    &-header {
      font-weight: 700;
      font-size: 21px;
      line-height: 30px;
      /* or 143% */
      padding-left: 19px;
      padding-top: 14px;
      display: flex;
      align-items: center;
      color: #383838;
    }
  }

  &-left {
    &-header {
      font-weight: 700;
      font-size: 21px;
      line-height: 30px;
      /* or 143% */
      padding-left: 19px;
      padding-top: 14px;
      display: flex;
      align-items: center;
      color: #383838;
    }

    &-userDataContainer {
      margin-top: 25px;
      height: 68vh;
      padding-bottom: 20px;
      overflow-y: auto;
    }

    &-userData {
      border-left: 3px solid rgba(0, 0, 0, 0);
      padding: 14px 0px 12px 19px;
      justify-content: space-between;
      flex-wrap: nowrap !important;

      &-selected {
        border-left: 3px solid rgba(66, 42, 102, 1);
        background-color: rgba(66, 42, 102, 0.15);
      }
    }

    &-userName {
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 150px;
      overflow: hidden;
      margin-left: 15px;
      font-size: 16px;
      color: #23364a;
    }

    &-userNameAvatar {
      flex-wrap: nowrap !important;
    }
  }
}

.w-80 {
  width: 80%;
}

.w-20 {
  width: 20%;
}

.CommonIconButton {
  background-color: rgba(66, 42, 102, 1) !important;
  border-radius: 5px !important;
  width: 40px !important;
  height: 30px !important;
  color: white !important;
}

.GroupUsersSectionWrapper {
  padding-top: 7px !important;
  padding-bottom: 8px !important;
  font-size: 16px;
  height: 40px;
  display: flex;
  align-items: center;
  width: 100%;
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.5);
  padding: 10px 19px 10px 19px;
  border-radius: 5px;
  height: 100%;
  margin-top: 5px;
}

.UserchipContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 95%;
  margin-left: 8px;
}

.chipContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(66, 42, 102, 0.15);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 6px 9px 6px 7px;

  .userprofile {
    display: flex;
    align-items: center;

    &-Avatar {
      display: flex;
      height: 20px !important;
      width: 20px !important;
      border-radius: 50%;
    }

    &-name {
      margin-left: 7px;
      font-weight: 600;
      font-size: 16px;
      color: #23364a;
      margin-bottom: 0 !important;
    }
  }

  .Removeuser {
    margin-left: 15px;
    display: flex;
    align-items: center;
  }
}

.disabledButton {
  background-color: rgba(66, 42, 102, 0.15) !important;
}

.GroupNameInput {
  padding-left: 0 !important;
  height: auto !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.GroupNameInput::placeholder {
  font-size: 18px !important;
  color: #000000 !important;
  font-weight: 400 !important;
}

.MessageSectionBody {
  height: 73vh;
  overflow-y: auto;
  padding-bottom: 20px;
  padding-right: 10px;
  margin-top: 9px;
}

.message-card {
  &-userInfoContainer {
    &-group {
      display: flex;
      flex-direction: column;
    }

    &-personal {
      display: flex;
      align-items: center;
    }
  }

  &-container {
    margin-top: 10px;
    display: flex;
    padding: 14px 12px 19px 16px;
    box-shadow: 0px 2px 4px rgba(39, 34, 98, 0.220908);
    background-color: #fff;
    border-radius: 15px;
    width: 100%;
    cursor: pointer;
  }

  &-container:hover {
    background-color: lightgray;
  }

  &-name {
    &-group {
      margin-top: 5px;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 19px;
    margin-left: 10px;
    font-weight: 700;
    font-size: 16px;
    color: #23364a;
  }

  &-lastMesage {
    line-height: 20px;
    font-weight: normal;
  }

  &-messagesInfo {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &-unseen_count {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 31.02px;
      color: #fff;
      font-weight: 300;
      font-size: 12px;
      height: 21px;
      background: #f5951e;
      border-radius: 2px;
      margin-bottom: 0;
    }

    &-recent_message_time {
      margin-bottom: 0;
      margin-top: 5px;
      line-height: 14px;
      font-size: 12px;
      color: #23364a;
    }
  }
}

.members-Count {
  font-size: 16px;
  line-height: 19px;

  color: #23364a;
}

.topics-filter {
  color: white !important;
  background-color: #422a66 !important;
}

.create-radio {
  font-size: 18px;
  font-style: italic;
  font-weight: 500;
  color: #272262;
  display: flex;
  align-items: center;
}

.hide {
  display: none;
}

.discussion-height,
.campaign-discussion-height {
  height: 400px;
  overflow-y: auto;
}

.campaign-discussion-height {
  height: 500px;
}

// .popup::-webkit-scrollbar {
//   display: none;
// }

.welcome-region-image {
  padding-left: 450px;
}

.welcome-region-heading {
  padding-left: 40%;
  padding-top: 1%;
  font-family: "Inter_Bold";
  font-size: 24px;
  line-height: 28px;
  color: #000000;
}

.welcome-region-members {
  height: 29px;
  padding-left: 480px;
  top: 310px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}

.welcome-region-join {
  position: absolute;
  left: 60%;
  width: 460px;
  left: 340px;
  top: 350px;
  background: #422a66;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.welcome-region-heading5 {
  position: absolute;
  left: 445px;
  top: 445px;
  line-height: 21px;
  color: #000000;
}

.MuiGrid-root {
  padding-top: 78px;
  padding-bottom: 60px;
}

.pwa-callout-body {
  background-color: #f5f5f5;
  position: relative;
  bottom: 30px;
  margin-left: 16px;
  padding-left: 16px;

  @include for-tablet-portrait-up {
    width: 100%;
    // margin-left: -20px !important;
    margin-left: 1px;
    padding-left: 1px;
  }
}

.create-course {
  // position: absolute;
  // top: 3.3rem;
  // right: 1.4rem;
  height: 106px;
  border-radius: 25px;
  margin-right: 1.4rem;
}

.modal-content {
  padding-left: 0px !important;
}

.create-button {
  border-radius: 10px;
  padding: 14px 50px;
  margin-top: 30px;
  letter-spacing: 0.1rem;
  font-weight: 500 !important;

  :hover {
    font-weight: 500 !important;
  }
}

.region-Icon {
  width: 22px;
  height: 22px;
  display: block;
  margin: 0 auto;
}

.button-radius,
.course-button {
  border-radius: 15px;
}

.course-button {
  border-radius: 15px;
  // background-color: #422a66;
  width: 85%;
  height: 100%;

  @include for-tablet-portrait-up {
    width: 50%;
    height: 20%;
    font-weight: lighter;
    font-size: smaller;
    letter-spacing: normal;
    padding: 2px;
    margin-top: 20px;
  }
}

#communityBtn:disabled {
  background: #422a66;
  mix-blend-mode: normal;
  opacity: 0.25;
}

.EditorContainer {
  position: fixed;
  bottom: 15px;
  display: flex;
  padding-left: 79px;
  padding-right: 80px;
  width: 80%;
}

.invites-input {
  // border-radius: 5px 5px 0px 0px !important;
  width: 90% !important;
  background-color: transparent !important;
  border: 0px solid !important;
  // transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.invites-container {
  margin: 0 0 0px 5px;
  max-height: 500px !important;
  overflow-y: scroll !important;
  overflow-x: hidden;
  scrollbar-width: 2px;
}

.m-left-5 {
  margin-left: 5px;
}

.invite-container {
  min-height: 70px;
  box-shadow: 0px 2px 4px rgba(39, 34, 98, 0.220908);
  border-radius: 15px;
  margin-left: 5px;
}

.invite-input {
  padding-bottom: 70px !important;
}

.invites-subtext {
  margin-top: -2px !important;
  color: #9152c8;
}

.invites-image {
  width: 60px;
  height: 60px;
}

.invites-avatar {
  &.sb-avatar.sb-avatar--text {
    height: 0 !important;
  }

  .invites-avatar {
    // margin-top: -55px;
    margin-left: 16px;
    border: 4px solid #fff;
  }
}

.Message-section-Header {
  box-shadow: 0px 15px 10px -15px rgba(39, 34, 98, 0.220908);
  padding: 5px 17px 5px 31px;
}

.chat-container {
  display: flex;
  flex-direction: column-reverse;
  padding-left: 79px;
  padding-right: 80px;
  width: 100%;
  overflow-y: auto;
  height: 66vh;
  padding-top: 10px;
  padding-bottom: 10px;
}

.display-linebreak {
  white-space: pre-line;
}

.chatMessage {
  display: flex;
  margin-top: 10px;

  &-me {
    flex-direction: row-reverse;
  }

  &-message {
    &-text {
      &-me {
        border-radius: 15px 0px 15px 15px;
        margin: 0px 8px 0px 8px;
        padding: 12px 14px 11px 14px;
        color: #fff;
        background: rgba(130, 130, 130, 0.65);
      }

      &-other {
        color: #fff;
        border-radius: 0px 15px 15px 15px;
        margin: 0px 8px 0px 8px;
        padding: 12px 14px 11px 14px;
        background: rgba(66, 42, 102, 0.65);
      }
    }
  }
}

.Conversation-Date {
  display: flex;
  justify-content: center;
  color: #383838;
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;
}

.left-panel-count {
  background: #f5951e;
  border-radius: 2px;
  padding: 3px 15px 3px 15px;
  font-weight: 300;
  font-size: 12px;
  // line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.min-h {
  min-height: 60vh;
}

.min-h-100 {
  min-height: 100%;
}

.event_search_name {
  width: 400px;
  height: 50px;
  background: #ffffff;
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
}

.cancel_button {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  width: 230px;
  height: 50px;
}

.go_to_article {
  height: 45px;
  width: 200px;
  border-radius: 15px;
}

.h-90vh {
  height: 90vh;
}

.datePickerOuterBox {
  display: flex;
  justify-content: end;
}

.datePickerInnerBox {
  display: flex;
  align-items: center;
  height: 2.25rem;
  background: #dbe3eb;
  color: #121619;
  border-radius: 0.25rem;
  cursor: pointer;
}

.datePickerCalendarSpan {
  margin-right: 0.75rem;
  background: #dbe3eb;
}

.calendarIcon {
  margin-left: 0.5rem;
  width: 1.25rem;
  height: 1.25rem;
  color: #121619;
}

.datePickerInput {
  cursor: pointer;
  border: 0;
  outline-width: 0px;
  width: 6rem;
  background: #dbe3eb;
  color: #121619;
  font-size: 0.75rem;
  line-height: 1rem;
  width: 7.625rem;
}

.downArrowIcon {
  margin: 0.5rem;
}

.datePickerContainer {
  display: flex;
  justify-content: flex-end;
}

.startDatePicker {
  display: flex;
}

.endDatePicker {
  display: flex;
}

.startDate {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 1rem;
}

.endDate {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 1rem;
}

.event-page-outer::-webkit-scrollbar {
  height: 0.4em !important;
}

.event-page-outer::-webkit-scrollbar-thumb {
  background-color: #272262;
  outline: 1px solid #ccc;
}

.event-page-outer {
  overflow-x: auto;
}

.heading-seeAll {
  max-width: 1316px;
}

.join-button-styling {
  min-width: fit-content !important;
  padding: 0 10px 0 10px;
  border-radius: 10px;
}

.pl-45 {
  padding-left: 45.2px;
}

.event-overflow-hide {
  overflow-x: clip !important;
}

.courses-page-container {
  margin-bottom: -30px;
}

.event-button-container {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.member-card-wrapper {
  position: relative;
  overflow: hidden;
  background-color: #272262;

  .members-card-overlay {
    display: none;
  }
}

.member-card-wrapper:hover {
  position: relative;
  overflow: hidden;

  .members-card-overlay {
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
  }
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.create-event-bottom {
  bottom: 0 !important;
}

.pagination-box {
  display: flex;
  justify-content: center;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.background-sidebar-pages {
  background-color: #f5f5f5 !important;
}

.newSideCalloutStyle {
  width: calc(100% - 63px);
  top: 58px;

  @include for-tablet-portrait-up {
    width: 100%;
  }
}

.communitySideCallout {
  width: 100%;
  top: 58px;
}

.course-community-circle {
  padding-bottom: 1px;
}

.create-event-callout {
  width: 100%;
  margin: 0 !important;
}

.posted-by-container {
  position: relative;
  width: 320px;

  &-drop {
    width: 100% !important;
    position: relative !important;
    height: 75px !important;
    right: 0 !important;
  }

  .dropdown-toggle {
    height: 100% !important;
  }

  @include for-tablet-portrait-up {
    padding-top: 20px;
    width: 100%;
  }
}

.editorClass {
  margin-top: 20px;

  .codex-editor__redactor {
    padding-bottom: 10px !important;

    @include for-tablet-portrait-up {
      margin-bottom: 280px !important;
    }
  }

  .ce-block__content,
  .ce-toolbar__content {
    max-width: calc(100% - 80px) !important;

    @include for-tablet-portrait-up {
      max-width: 100% !important;
      margin-left: 30px;
    }
  }

  .cdx-block {
    max-width: 100% !important;
  }
}

.custom-header {
  font-size: 18px;
  line-height: 21px;
  font-weight: 700;

  @include for-tablet-portrait-up {
    padding-left: 36px;
  }
}

.Input-ArticleCreation {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d1d1d1;
  border-radius: 15px 15px 15px 15px;
  height: 400px;
  width: 600px;

  @include for-tablet-portrait-up {
    width: 100%;
  }
}

.HeaderFormContainer {
  margin-left: 40px;
  width: 60%;

  @include for-tablet-portrait-up {
    margin-left: 0px;
    width: 100%;
  }
}

.cdx-input.image-tool__caption {
  display: none;
}

.article-banner-container {
  border-radius: 15px 15px 15px 15px;
  max-height: 400px;
}

.CreateArticle-page-sidebar-head {
  z-index: 10;
  background: white;
  top: 0;
  box-shadow: 0 2px 3px -1px rgba(0, 0, 0, 0.1);
  position: sticky;
  width: 100%;
  padding: 13px 6px;
  font-size: 16px;
  color: #23364a;

  >.ml-auto.cursor-pointer {
    position: relative;
    right: 15px;
  }

  &-article {
    @media (max-width: $hd-tab) {
      padding: 20px;

      .create-post-head {
        padding: 0;
        width: 100%;
      }
    }
  }
}

.CreateEvent-page-sidebar-head {
  z-index: 10;
  background: white;
  top: 0;
  box-shadow: 0 2px 3px -1px rgba(0, 0, 0, 0.1);
  position: sticky;
  width: 100% !important;
  padding: 6px 10px;
  font-size: 16px;
  color: #23364a;

  >.ml-auto.cursor-pointer {
    position: relative;
    right: 15px;
  }
}

.createArticle-container-class {
  margin: 0 !important;
  padding: 0 !important;
  max-width: 100% !important;

  &-success {
    margin: 0 !important;
    padding: 4rem 0 0 0 !important;
    max-width: 100% !important;
  }
}

.creator-form-article {
  padding: 42px;
}

.backArrowRow {
  height: 3.625rem;
}

.eventDetailsContainer,
.newsDetailsContainer {
  width: 91%;
  margin: 0 auto;
  margin-top: 1.875rem;
}

.eventLeftPanel,
.newsLeftPanel {
  width: 68.4%;
}

.eventLeftPanel {
  width: 68.4%;

  @include for-tablet-portrait-up {
    width: 100% !important;
  }
}

.eventRightPanel,
.newsRightPanel {
  width: 29%;
}

.eventRightPanel {
  width: 29%;

  @include for-tablet-portrait-up {
    width: 40% !important;
  }
}

.eLeftPanelDescrp {
  border-radius: 15px;
  background-color: white !important;
  max-height: 41.125rem;
}

.eLeftPanelText,
.nLeftPanelText {
  padding: 1.5rem 2.5rem 1.5rem 2.5rem;
  border-radius: 15px;

  p {
    color: #000000;
  }
}

.eLeftPanelDateBox {
  width: 21%;
}

.eventDateBox {
  color: #812fc2;
  height: 7.5rem;
  width: 7.5rem;
  margin-left: 30px;
  margin-top: 20px;

  p:first-child {
    font-size: 18px;
    margin-top: 0.75rem;
  }

  p:last-child {
    font-size: 48px;
  }
}

.eLeftPanelDetailsBox {
  margin-top: 7rem;
}

.commentToolPosition {
  position: relative;
  bottom: 0;
  left: 0;
  background: #f5f5f5;
  padding-bottom: 1rem;
}

.ePanelUserChatContainer {
  margin-top: 1rem;
}

.attend-button {
  width: 15rem;
  margin-right: 17px;
}

.attend-button-row {
  margin-top: 29px;
  margin-bottom: 2rem;
}

.menu-list-content {
  p {
    margin-bottom: 4px;
  }
}

.react-player-box {
  height: 25rem;
  border-radius: 10px;
}

.react-player-video {
  width: 46%;
}

.react-player-content {
  position: relative;
  width: 54%;
  padding-left: 2.2rem;
  padding-top: 1.375rem;
  padding-right: 3.525rem;

  p:last-child {
    font-size: 1.3rem;
  }
}

.website-link-button {
  width: 42%;
}

.news-menu-button {
  margin-top: 6rem;
}

.news-viewers-section {
  max-height: 18.5rem;
  padding-bottom: 1rem;
  // overflow-y: scroll;
}

.news-viewers-section-more {
  overflow-y: scroll;
}

.news-viewers-section-less {
  overflow-y: hidden;
}

.news-viewers-section::-webkit-scrollbar {
  width: 0.4em !important;
}

.news-viewers-section::-webkit-scrollbar-thumb {
  background-color: #272262;
  outline: 1px solid #ccc;
}

.detailContainerImage {
  height: 300px;
  border: 1px solid lightgray;
  display: flex;
  justify-content: center;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.campaign-banner-img {
  max-height: 400px;
}

.filledLikeIcon {
  transform: scale(1.3);
}

.border-news-card {
  background-color: black;
  display: grid;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  // border-radius: 10px 0px 10px 0px !important;
  // border-radius: 0 !important;
}

.event_search_name {
  width: 400px;
  height: 50px;
  background: #ffffff;
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
}

.cancel_button {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  width: 230px;
  height: 50px;
}

.go_to_article {
  height: 45px;
  width: 200px;
  border-radius: 15px;
}

.myNetworkSeeMore {
  max-height: 300px;
  overflow-y: auto;
}

.addDocumentsHeading {
  color: #422a66;
  cursor: pointer;
  font-weight: 300;
  margin: 8px 10px 4px 20px;
  float: right;
}

.uploadDocumentLabel {
  display: flex;
  align-items: center;
  width: 100%;
  height: 0.9375rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 0.625rem;
  color: #aec7e8;
}

.uploadDocumentModal {
  width: 45% !important;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 80vh;

  @include for-tablet-portrait-up {
    width: 75% !important;
    // height: 50%;
  }
}

.uploadDocumentEditModal {
  width: 40% !important;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 80vh;

  @include for-tablet-portrait-up {
    width: 35% !important;
    // height: 50%;
  }
}


.uploadDocumentInput {
  cursor: pointer;
  position: absolute;
  height: 3.75rem;
  width: 100%;
  z-index: 2;
  opacity: 0;

}

.documentUploadIcon {
  height: 1.25rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 1rem;
  z-index: 1;

}

.myDiscussionSeeMore {
  max-height: 150px;
  overflow-y: auto;
}

.myDocumentsSeeMore {
  max-height: 400px;
  overflow-y: auto;
}

.eventsSeeMore {
  max-height: 320px;
  overflow-y: auto;
}

.on-course-page {
  z-index: 33 !important;
  width: 100% !important;
}

.disableSvg {
  svg {
    path {
      fill: lightgray;
    }
  }
}

.eventContentContainer {
  max-height: 11rem;
  overflow-y: auto;
}

.eventContentContainer::-webkit-scrollbar {
  width: 0.4em !important;
}

.eventContentContainer::-webkit-scrollbar-thumb {
  background-color: #272262;
  outline: 1px solid #ccc;
}

.news-card-description {
  overflow-wrap: anywhere !important;
}

.description-scroll {
  max-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
}

.faded {
  color: grey;
  cursor: default;
}

.upload-menu-background {
  background: black;
  border: 1px solid whitesmoke;
  align-items: center;
  display: flex;
  padding-bottom: 10px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
}

.hidden {
  cursor: default;
  display: none !important;
}

.top-0 {
  top: 0px !important;
}

.news-share-button {
  position: absolute;
  bottom: 2rem;
}

.video-tool__video-preloader {
  background-image: url("../assets/images/city_specific_images/Videoplay.png") !important;
}

.custom-input-image-wrapper {
  background-color: #d1d1d1;
  width: 100% !important;
  display: grid;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.custom-article-banner-container {
  max-height: 400px;

  @media (max-width: 1230px) {
    margin-bottom: 20px;
  }
}

.custom-input-image {
  @media (max-width: 1230px) {
    margin-bottom: 20px;
  }
}

.vh-84 {
  height: 84vh;
}

.px-204 {
  height: 204px;
}

.vh-59 {
  height: 59vh !important;
}

.mediaflex {
  @media (max-width: 1230px) {
    flex-direction: column !important;
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.commentSectionContainer {
  .commentUserData {
    width: 100% !important;
  }

  .ChatcommentUserData {
    width: 80% !important;
  }

  .comment_head {
    bottom: 3px !important;
  }
}

.card-padding {
  padding-left: 2.7rem;
}

.activity-container {
  max-height: 150vh !important;
}

.community-option-container {
  display: flex;
  align-items: center;
}

.community-option-container-text {
  margin-bottom: 0 !important;
  margin-left: 10px;
  margin-top: 2px;
}

.custom-panal-class {
  min-width: 300px;
}

.word-break-all {
  word-break: break-all;
}

.react-multi-timePicker div {
  max-width: 203px !important;

  input {
    width: 200px !important;
  }
}

.dateAtimecss {
  align-items: flex-start;

  & .buttletccss {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .buttletccss::before {
    content: "";
    background: lightgray;
    width: 8px;
    margin-bottom: 4px;
    height: 8px;
    margin-right: 7px;
    border-radius: 50%;
  }

  & img {
    margin-top: 2px;
  }

  @include for-tablet-portrait-up {
    margin-left: 70px;
  }
}

.dynamic_height {
  max-height: 100% !important;
}

.display-linebreak {
  white-space: pre-line;
}

.bottom-border {
  border-left: none;
  border-bottom: 5px solid #431a8f;
  padding: 14px 19px;
}

.bottom-border-white {
  border-bottom: 5px solid #ffffff;
  padding: 14px 19px;
}

.display-none {
  display: none;
}

.f-weight-bold {
  font-weight: 700;
}

.f-weight-normal {
  font-weight: 400 !important;
}

.fs-size-16 {
  font-size: 16px;
}

.right-section-header-container {
  display: flex;
  justify-content: space-between;
}

.h-100 {
  height: 100% !important;
}

.active-subchapter {
  // border-left: 5px solid #431a8f;
  background: #c3c0c9;
}

.closeIconDiss {
  top: 15px;
  right: 28px;
}

.closeIconEdit {
  top: 30px;
  right: 45px;
}

.closeIconComment {
  // top: 33px;
  top: 6px;
  // right: 105px;
  right: 33px;
}

.campaign-heading-activity {
  color: #000;
  text-decoration: underline;
}

.ml-33p {
  margin-left: 33px !important;
}

.SidepanalheaderIcon {
  padding: 5px 10px;

  @include for-tablet-portrait-up {
    padding: 0px 0px !important;
    margin-right: 10px !important;
  }
}

.pwa-neighbourhood {
  margin-left: 0px;

  @include for-tablet-portrait-up {
    margin-left: auto;
  }
}

.SelectedSidePanal {
  // border-bottom: 5px solid #431a8f;

  border-bottom: 3px solid #ff9900 !important;
  padding-bottom: 6px;

  @include for-tablet-portrait-up {
    background: none;
  }
}

.disCommentBody {
  margin-left: auto;
  width: 100%;
  margin-left: 21px;
  overflow-wrap: break-word;
  margin-bottom: 0.5rem;

  p {
    margin-bottom: 0 !important;
  }
}

.dicussion-nameContainer {
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-transparent {
  color: rgba(0, 0, 0, 0);
}

.button-class-slider {
  background-color: rgb(118, 118, 118) !important;
  border-radius: 100% !important;
  width: 35px !important;
  height: 35px !important;
  align-self: center !important;
  position: relative !important;
  z-index: 1;
  font-size: 50px !important;
  color: white !important;
}

#custom-tab>.MuiTabScrollButton-root:first-child {
  left: 50px;
}

#custom-tab>.MuiTabScrollButton-root:last-child {
  right: 50px;
}

.MainContainerScrollItem {
  margin-top: 1rem;
  background-color: $white;
  border-radius: 15px;
  padding: 31px 50px 31px 0px;
}

.home-page-headings {
  padding-left: 42px;

  @include for-tablet-portrait-up {
    padding-left: 20px;
  }
}

.home-page-headings-courses {
  // background-color: white;
  width: 24%;
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
  // margin-bottom: 15px;
  // border: 2px solid #272262;
  // border-radius: 12px;
  // font-size: 20px;
  // // font-family: 'Times New Roman', Times, serif;
  // font-weight: 600 !important;
  // color: #272262;

  @include for-tablet-portrait-up {
    padding-left: 20px;
    width: 35%;
  }
}

.home-page-headings-Community {
  background-color: white;
  width: 22%;
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 10px;
  border: 2px solid #272262;
  border-radius: 12px;
  font-size: 20px;
  // font-family: 'Times New Roman', Times, serif;
  font-weight: 600 !important;
  color: #272262;

  @include for-tablet-portrait-up {
    padding-left: 20px;
    width: 35%;
  }
}

.home-page-courses {
  padding-left: 38px;
}

.discussion-corporateName {
  font-size: small;
  font-style: italic;
}

.icon-label {
  font-size: 9px;
  margin-top: 10px;
  color: white;

  @include for-tablet-portrait-up {
    font-size: 15px;
    margin-top: 11px;
    font-weight: 600;
  }
}

.icon-label-sideBar {
  display: block;
  font-size: 8px;
  margin-top: 6px;
}

.icon-label-nav {
  font-size: 11px;
  color: #272262;
  // height: 1px;
}

.event-margin {
  margin-left: -3px;
}

.w-76 {
  width: 76%;
}

.w-77 {
  width: 77%;
}

.commentSectionWrapper {
  width: 100% !important;
  // .ReplyIcon {
  //   margin-top: 6px !important;
  // }
}

.discussion_container {
  position: relative;
}

.comment-button-wrapper {
  font-weight: 800;
  right: 3%;
  cursor: pointer;
  // top: 100%;
  bottom: 15%;
  position: absolute;

  .comment-button {
    z-index: 1000;
    padding: 1rem;
    background-color: #272262;
    color: white;
    font-weight: 800 !important;
  }

  .comment-button:hover {
    background-color: #272262 !important;
    color: white !important;
  }
}

.rsvpBtn {
  height: 58px !important;
}

.rmbutton {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3) !important;
}

.rsvpLoaderContainer {
  width: 240px;
  display: flex;
  align-items: center;
  justify-content: center;

  .MuiCircularProgress-root {
    color: #272262 !important;
    width: 25px !important;
    height: 25px !important;
  }

  // @include for-tablet-portrait-up {
  //   width: 177px;
  // }
  margin-bottom: 10px;
}

.course-confirm-card-container {
  margin-top: 3rem !important;
}

.Create-course-page-container {
  display: flex;
  justify-content: center;
  padding-left: 18rem;
  background-color: white;
}

.z-index-2 {
  z-index: 2;
}

.message-card-container-active {
  background-color: #e3e2eb;
}

.ChatHeaderContainer {
  position: relative;

  .unReadIndicator {
    width: 10px;
    height: 10px;
    background: #e71b1b;
    border-radius: 50%;
    position: absolute;
    top: 3px;
    right: 8px;

    @include for-tablet-portrait-up {
      top: -2px;
      right: -2px;
    }
  }
}

.btn-markRead-styling {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-left: auto;
  border: 1px solid lightgrey;
  display: flex;
  align-items: center;
  font-size: 10px;
  padding: 2px 6px;
  border-radius: 3px;
  height: 25px;
}

.overflow-none {
  overflow: hidden !important;
}

.btn-new-style {
  border-radius: 7px;
  color: white;
  font-weight: 600;
  font-size: 15px;
  padding: 13px;
  background: #25215e;
}

.position-static {
  position: static;
}

.btn-markRead-styling-desabled {
  color: gray;
  box-shadow: none;
}

.btn-markRead-styling:active {
  box-shadow: none;
}

.course-page-image-max {
  max-width: fit-content;
}

.carousal-item-inner {
  height: 645px !important;
  display: flex !important;
  align-items: center !important;
  background-color: #000 !important;
  justify-content: center !important;
}

.no-data-styling {
  font-size: 23px;
  margin-left: 4%;
  font-weight: 600;
  margin-top: 13px;
}

.carousal-item-inner-video {
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
  background-color: #000 !important;
  justify-content: center !important;
}

.ff-tab-container {
  border-bottom: 1px solid #4a4394 !important;

  .nav-item {
    display: flex;
    justify-content: center;
    width: 50% !important;
    border: none;
  }

  .active {
    font-weight: 600;
    color: #272262;
    border-bottom: 3px solid #272262 !important;
  }
}

.active-modal {
  outline: none;
  position: absolute;
  left: 30%;
  margin: 10px;
  padding: 20px;
  border-radius: 10px;
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;

  .modal-title {
    display: flex;
    flex-direction: column;
    align-items: center;

    .modal-title-text {
      font-size: 22px;
      color: red;
      font-weight: 700;
    }

    svg {
      color: red;
      font-size: 60px;
    }
  }

  .modal-body {
    font-size: 17px;
    text-align: justify;
    padding: 8px;
  }

  .modal-btn {
    width: 11rem;
    padding: 10px;
    border-radius: 10px;
  }

  .modal-textArea {
    outline: none;
    resize: none;
    width: 95%;
    padding: 1rem;
    border-radius: 10px;
    border: 1px solid lightgray;
    height: 7rem;
  }

  .modal-input {
    outline: none;
    width: 95%;
    padding: 1rem;
    border-radius: 10px;
    border: 1px solid lightgray;
    height: 2rem;
  }
}

.w-60 {
  width: 60% !important;
}

.error-text {
  color: red;
}

.createNewsTab {
  display: flex;

  @include for-tablet-portrait-up {
    display: block;
  }
}

.uploadImage {
  @include for-tablet-portrait-up {
    padding-left: 36px;
  }
}

.newsSearchBar {
  @include for-tablet-portrait-up {
    width: 40% !important;
  }
}

.searchBar-news {
  @include for-tablet-portrait-up {
    width: 100% !important;
  }
}

.createNewsBtn {
  @include for-tablet-portrait-up {
    width: 40% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px !important;
  }
}

.createNewsForm {
  @include for-tablet-portrait-up {
    flex-direction: column;
  }
}

.bg-black {
  background-color: black;
}

.rounded-md {
  border-radius: 16px;
}

.newsTabContent {
  @include for-tablet-portrait-up {
    flex-direction: column;
  }
}

.setFullWidth {
  width: 100% !important;
}

.websiteLink {
  margin-top: 2rem !important;
}

.newsLeftPadding {
  @include for-tablet-portrait-up {
    padding-left: 0.75rem !important;
  }
}

// .communication-tab{
//   @include for-tablet-portrait-up {
//     background-color:red;
//     display: flex;
//     flex-direction: column;
//  }
// }
.itemDetail {
  @media (max-width: 820px) {
    margin-top: 40%;
  }
}

.itemDetail-Network {
  @media (max-width: 820px) {
    margin-top: 56%;
  }
}

.communication-modal-margin {
  @media (max-width: 820px) {
    margin-top: 0px;
  }
}

.create-event-width {
  width: 40% !important;

  @include for-tablet-portrait-up {
    width: 60% !important;
  }
}

.coversationBox {
  @include for-tablet-portrait-up {
    padding-right: 0px !important;
    padding-left: 20px !important;
    width: 98% !important;
  }
}

.newsNestedButton {
  @include for-tablet-portrait-up {
    margin-top: 0px !important;
    width: 70% !important;
  }
}

.commTabOuterSection {
  @include for-tablet-portrait-up {
    padding-left: 0px !important;
  }
}

.communityTabSection {
  @include for-tablet-portrait-up {
    padding-left: 0px !important;
  }
}

.tabSectionMessageHeader {
  @include for-tablet-portrait-up {
    padding-left: 20px !important;
    padding-right: 24px !important;
  }
}

.chatContainerTab {
  @include for-tablet-portrait-up {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

.fullWidth {
  width: 100% !important;
}

.header-padding {
  font-size: 18px;
  line-height: 21px;
  font-weight: 700;

  @include for-tablet-portrait-up {
    padding-left: 0px;
  }
}

.collapsedSideBar {
  @include for-tablet-portrait-up {
    margin-left: 7% !important;
    flex-grow: 1 !important;
    width: calc(100% - 68px);
  }
}

.collapsedBannerImage {
  @include for-tablet-portrait-up {
    margin-left: 0.1% !important;
  }
}

.chatBoxTabBottom {
  @include for-tablet-portrait-up {
    padding-bottom: 4.5rem !important;
  }
}

.neighbourhood-nav {
  background-color: #272262;
  margin-top: 9px;
}

// .closeModalTab {
//   @include for-tablet-portrait-up {
//     top: 0 !important;
//   } 
// }

.widthTabMargin {
  @include for-tablet-portrait-up {
    margin-bottom: 6rem !important;
  }
}

.participntSearchTab {
  @include for-tablet-portrait-up {
    transform: scale(0.75) !important;
  }
}

.textAreaTab {
  @include for-tablet-portrait-up {
    width: 15rem !important;
  }
}

.noDiscussion {
  @include for-tablet-portrait-up {
    margin-top: 2.5rem !important;
  }
}

.btnPlayTab {
  @include for-tablet-portrait-up {
    width: 4.7rem !important;
  }
}

.profileBottomTab {
  @include for-tablet-portrait-up {
    padding-bottom: 5rem !important;
  }
}

.closeIconTop {
  margin-top: 2rem !important;
}

.news-image-cls {
  width: 50%;

  @include for-tablet-portrait-up {
    width: 100%;
  }

}

.border-golden {
  border: 3px solid #f90;
}


.vertical-card-content {
  background-color: #272262;
  color: white;
  border: 3px solid #ff9900
}

// .community-tab-design {
//   box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 10px;
//   background-color: white;
// }

.rounded-20 {
  border-radius: 20px !important;
}

.trendingNewsClass {
  width: 750px;

  @include for-tablet-portrait-up {
    width: 500px;
  }
}

.pl-25 {
  padding-left: 2.5rem;
}