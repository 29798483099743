.dashboard-welcome {
  background: #0e202c url('../assets/images/welcome-bg.png') no-repeat top left;
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 1;
  &:after{
    width: 100%;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 8%;
    transform: skewY(-5deg);
    background-color: #fff;
    z-index: 2;
  }
  &-inner {
    position: relative;
    z-index: 5;
    padding: 0 0 20% 0;
    background: url('../assets/images/logos/s-logo-bg.png') no-repeat bottom left;
    top: 150px;
    margin-bottom: 150px;
  }
  .title {
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 30px;
    &:after {
      content: "";
      position: absolute;
      height: 2px;
      width: 70px;
      background: $alternate-color;
      left: 0;
      bottom: 0;
    }
  }
  .bottom-helping-image {
    position: absolute;
    bottom: 0;
    right: -150px;
    img {
      width: auto;
    }
  }
  .top-helping-image {
    img {
      width: auto;
    }
    .first-image {
      position: absolute;
      top: -120px;
      left: 40px;
    }
    .second-image {
      left: -33px;
      position: absolute;
      top: -55px;
    }
  }
  .close {
    position: absolute;
    top: -100px;
    right: 30px;
    cursor: pointer;
  }
  
}
.font-28 {
  font-size: 28px;
}
.font-36 {
  font-size: 36px;
}
.line-height-normal {
  line-height: normal;
}
.check-msg {
  margin-top: 20px;
  position: relative;
  .form-check {
    padding-left: 0;
  }
  
  input {
    display:none;
  }
  
  label {
    position:relative;
    padding-left:30px;
    display: inline-block;
    font-size: 18px;
    &:before, &:after {
      content:'';
      position:absolute;
    }
    &:before {
      width:20px;
      height:20px;
      border:1px solid rgba($light-grey, 0.5);
      top:5px;
      left:0;
      border-radius:4px;
    }
    &:after {
      width:0px;
      height:0px;
      transition:width 0.2s;
      top: 11px;
      left: 4px;
      transform:rotate(-45deg);
    }
  }
  
  input:checked + {
    label {
      &:before {
        background: $primary;
      }
      &:after {
        border:1px solid $white;
        border-width: 0 0 2px 2px;
        height:5px;
        width:12px
      }
    }
  } 
} 
.home-page {
  &-sharemeister {
    background: url('../assets/images/dashboard-welcom-top.png') no-repeat top left;
    @media (max-width:576px) {
      background: none;
    }
  }
  &-city {
    background: url('../assets/images/dashboard-welcom-bottom.png') no-repeat 100% 260px;
    @media (max-width:576px) {
      background: none;
    }
  }

  &-sharemeister,
  &-city {
    .container {
      @media (min-width: 1200px) {
        max-width: 1032px;
      }
    }
  }
  .tilte {
    position: relative;
    &:before {
      content: "";
      background: $alternate-color;
      height: 2px;
      width: 70px;
      position: absolute;
      top: 0;

      @media (max-width: $hd-tab) {
        left: calc(50% - 35px);
        height: 3px;
      }
    }
  }
}