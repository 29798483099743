.surch-filter {
  svg.filter-icon {
    height: 1.6rem;
    width: 1.6rem;
  }
}

.filter-side-bar {
  right: 0;
  top: 0;
}

.modal-close-lg {
  font-size: 40px;
  text-align: right;
}

.filter-heading {
  font-family: $font-bold;
  font-size: 1.5rem;
  svg {
    margin-right: 15px;
  }
}

.input-range {
  margin-bottom: 40px;
  margin-top: 30px;
}

.input-range__label--max {
  right: 12px;
}

.input-range__track--active {
  background: $primary !important;
}
.input-range__slider {
  background: $black !important;
}
.input-range__track-max-w {
  margin: auto;
  width: 100%;
  max-width: calc(100% - 20px);
}

.input-range__slider {
  border: 1px solid $black !important;
}

.filter-section{
  .custom-control {
       padding-left: 2.5rem;
      font-size: 14px;
      .custom-control-label{
          &::after, &::before{
              left: -2rem;
              margin-top: -4px;
          }
      }
  }
  .custom-control-input:checked ~ .custom-control-label::before{
      border-color: #636567;
      background: #ffffff;
  }

  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after{
      background: #1d1b1b;
      transform: scale(0.7);
  }
}

.tag-panel{
    .tag-name{
        display: inline-block;
        min-width: 60px;
        border-radius: 20px;
        border: 1px solid #ccc;
        padding: 3px 30px 3px 16px;
        font-size: 16px;
        margin-right: 6px;
        margin-bottom: 8px;
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
        cursor: pointer;
        &:hover{
            // box-shadow: 0 0 2px #ccc;
            box-shadow: 0 3px 3px rgba(0,0,0,0.25);
        }
        &.active{
            background: #000;
            color: #fff;
        }
    }
}

.toggle-switch input[type=checkbox] {display:none}
.toggle-switch label {cursor:pointer;}
.toggle-switch label .toggle-track {
  display:block;height:20px;
  width:40px;
  background:#eee;
  border-radius:20px;
  position:relative;
  border:1px solid #ccc;
}

.toggle-switch .toggle-track:before{
  content:'';
  display:inline-block;
  height:15px;
  width:15px;
  background: $text-grey;
  border-radius:20px;
  position:absolute;
  top:1.5px;
  right: 20px;
  transition:right .2s ease-in;
}

.toggle-switch input[type="checkbox"]:checked + label .toggle-track:before{
  background: $primary;
  right: 2px;
}

.cursor-pointer {
  cursor: pointer;
}
