@mixin for-tablet-portrait-up {
  @media (max-width: 1200px) {
    @content;
  }
}
.chateditor {
  &-container {
    width: 100%;
    background: #ffffff;
    border-radius: 10px;
    border: 1px solid #e5e3e3;
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.5);
    padding: 15px 20px 15px 10px;
    display: flex;
    align-items: center;
    @include for-tablet-portrait-up{
      margin: auto;
      width: 90%;
    }
  }
  &-writing {
    width: 100%;
  }
  &-text-area {
    width: 100%;
    display: flex;
    align-items: center;
    vertical-align: middle;
    resize: none;
    height: fit-content;
    outline: none;
    max-height: 60px;

    border: none;
  }
  &-text-area::placeholder {
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    font-weight: 600;
    color: #828282;
  }
}
.Chat-textEditor-tools {
  display: flex;
  align-items: center;
  .iconsContainer {
    display: flex;
    align-items: center;
  }
  .input-icons {
    margin-left: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    &-send-comments {
      width: 40px;
      height: 40px;
    }
  }
}
.Emoji-picker {
  position: absolute;
  bottom: 80px;
}

.chat-media {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 7rem;
  max-height: 7rem;
  margin-left: 10px;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid lightgray;
  min-width: 7rem;
  min-height: 7rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: relative;
  .remove-icon {
    position: absolute;
    top: 0;
    right: 0;
  }
}
.chat-media-container {
  display: flex;
  width: fit-content;
  // margin-bottom: 10px;
  background-color: white;
  overflow: hidden;
}
.doc {
  &-icons {
    svg {
      width: 3rem;
      height: 3rem;
    }
  }
}

.display-linebreak {
  white-space: pre-line;
}

.stickyNote {
  position: relative;
  top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin: auto;
  .text-container {
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 30px;
    height: 5rem;
    background: rgba(130, 130, 130, 0.65);
    color: white;
    p {
      margin-bottom: 0;
    }
  }
}

.EncryptMsgTopIcon {
  color: white;
  width: 40px;
  margin-right: 10px;
}
