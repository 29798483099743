.side-modal {
   &-close {
     font-size: 40px;
   }

   &-outer {
     top: 58px;
     right: 0;
     overflow: hidden;
     z-index: 10;
     left: 37px;
   }

   &-inner {
      min-height: calc(100vh - 58px);
      max-height: calc(100vh - 58px);
      animation: slideLeft 0.5s ease-in-out;
      box-shadow: 0 2px 6px 0 rgba(0,0,0,0.25);
      overflow: auto;
   }
}
