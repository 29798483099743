.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 2px solid rgb(228, 228, 228);
  box-shadow: 24;
  border-radius: 4px;
  padding: 4;
  outline: 0;
}

.modalTitle{
    font-size: 18px;
    font-weight: 600;
}

.cursorPointer{
    cursor: pointer;
}