.new-landing-theme {

  .form-control {
    border-color: #efefef;
    font-family: $font-family-base;
    border: 2px solid rgba(129, 154, 159, 0.4);
  }

  .form-control::placeholder {
    font-size: 18px;
    font-family: $font-family-base;
    color: #819a9f;
  }

  .form-control:focus {
    border-color: $primary;
    box-shadow: none;
  }

  a:hover {
    color: $primary;
    text-decoration: underline;
  }
}

.sign-share-logo {
  height: 54px;
}

.shar-form-element {
  max-width: 355px
}


.register {
  &-outer {
    max-width: 700px;
    background-color: $bg-header;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  }

  &-form {
    .form-control {
      margin: auto;
      max-width: 325px;
      margin-top: 24px;
      margin-bottom: 5px;
    }

    .error {
      max-width: 325px;
      text-align: left;
      margin: auto;
    }
  }

  &-logo {
    max-width: 200px;
    max-height: 60px;
  }

}

.form-element-outer {
  max-width: 325px;
}

.account-info-pic {
  img {
    width: 50px;
    height: 50px;
}
}

.pwa-login-screen {
  min-height: 100vh;
  padding:60px 20px 40px !important;
  animation: slideLeft 0.5s ease-in-out;

  h1.font-20-bold.text-center.my-5,
  .text-margin {
    margin-top: 70px !important;
    margin-bottom: 50px !important;
  }

  .pwa-form {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &.main-screen-view {
    padding-top: 100px !important;

    .display-7.text-center.my-5 {
      margin-top: 80px !important;
    }
  }

  .passport-select-set {
    min-width: auto;
    flex: 1 1 auto;
    margin-left: 10px;
  }

  .passport-select-items {
    margin-bottom: 22px;
  }

  .btn-login {
    height: 58px;
    border-radius: 15px !important;
  }

  &.shar-form {
    .font-28-bold {
      font-size: 24px;
    }
  
    input {
      &::placeholder {
        font-family: $font-regular-italic !important;
        color: #344356;
      }
    }

    label[for="remember_me"] {
      font-size: 16px;

      &::after,
      &::before {
        top: 2px;
      }
    }
  }
}

.upload-edit-icon {
  position: absolute;
  right: 5px;

  .approve-action-icon {
    width: 25px;
    height: 25px;
    background: $primary;
    padding: 4px;
    border-radius: 50%;
  }
}

.splash-logo {
  max-height: 60px;
  max-width: calc(100% - 136px);
}

.go-back-icon {
  left: 24px;
  color: #828282;
}

.splash-screen  {
  min-height: 100vh;

  img {
    max-width: 80%;
    height: auto;
    object-fit: contain;
  }
}

.account-info-outer  {
  h5.font-bold {
    margin-bottom: 4px;
    text-transform: capitalize;
  }
  p {
    margin-bottom: 0;
  }
}

.add-remove-text {
  font-size: 1.25rem;
  margin-bottom: 4px;
}

.new-landing-theme.register-form {
  .card {
    background-color: transparent;
    border-radius: 0;
    > .row {
      align-items: center;
      h5.font-bold {
        margin-bottom: 4px;
        text-transform: capitalize;
      }
      p {
        margin-bottom: 0;
      }
    }
  }

  .col-md-2 {
    #Close-Icon {
      fill: $color-warning;
      stroke: $color-warning;
    }
  }
}

.register-outer {
  h3.mb-5.font-bold {
    font-size: 32px;
  }
}

.passport-text {
  margin: 30px auto;
  max-width: 245px;

  &-select {
    width: 100%;
    margin-bottom: 48px;

    + .passport-select-items {
        max-height: calc(100vh - 365px);
        overflow: auto;
        max-width: 100%;
        transform: translateX(-10px);

        .passport-select-set {
          border: 2px solid $nav-item-border-color;
          border-radius: 8px;
          overflow: hidden;
          display: flex;
          align-items: center;
          height: 63px;
        }

        .passport-item {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          display: inline-block !important;
          border: none;
          height: 100%;
          padding-top: 18px !important;
        }
    }
  }
}

.navbar-pwa {
  margin-top: 6px;

  .navbar-item {
    width: 12px;
    height: 12px;
    border: 3px solid $nav-item-border-color;
    background-color: #fff;
    border-radius: 50%;
    position: relative;
    z-index: 1;

    &.active {
      background-color: $primary;
      border-color: $primary;
    }

    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    background: $nav-item-border-color;
    height: 2px;
    top: 5px;
  }
}

.passport-select {
  &-items {
    max-width: 280px;

    .passport-item {
      width: 100%;
      margin-bottom: 0;
      transition: 0.2s ease-in-out;
      text-align: center;
      cursor: pointer;
      border: 2px solid $nav-item-border-color;
      border-radius: 8px;

      &-inner {
        border-radius: 25px;
        line-height: 1;
        border: 1px solid $border-color;
        height: 100%;
      }
    }
  }

  &-set {
    min-width: 135px;
    height: 66px;
    margin-bottom: 10px;

    input[type="checkbox"] {
      display: none;

      &:checked {
        + .passport-item  {
          background-color: $primary;
          color: #fff;
          border-color: $primary;
        }
      }
    }

    &-inner {
      height: 40px;

      &:nth-child(even) {
        margin-right: 10px;
      }
    }
  }
}

.passport-sidebar-width {
  max-width: 546px !important;

  .passport-items-selector {
    margin-top: 40px;
  }

  .message-box-view {
    height: calc(100vh - 170px);
  }

  @media (min-width: $hd-tab) {
    .message-box-view {
      height: calc(100vh - 204px);
      margin: -36px -36px 0px -36px;
      padding: 0;
    }

    .input-msg-box {
      margin-left: 26px;
    }

    #messageList {
      padding-right: 18px !important;
    }
  }
}
