@mixin for-tablet-portrait-up {
  @media (max-width: 1200px) { @content; }
}
.communication-nav {
  border-right: 1px solid #dddddd;
  background: white;
  position: relative;
  z-index: 2;
  margin-left: 12px;

  @media (max-width: $hd-tab) {
    min-height: calc(100vh - 142px);
  }
  .communication-header {
    @include padding(16px, 40px, 0px, 40px);
    .share-font-24 {
      @extend .display-5;
      font-family: $font-medium;
    }
    .active-line-height {
      line-height: 1.2;
    }
    .active-name {
      @extend .display-8;
      color: #9a9a9c;
      font-family: $font-medium;
    }
  }

  .border-padding {
    @include padding(20px, 8px, 20px, 8px);
  }

  .nav-padding {
    @include padding(0px, 30px, 0px, 30px);
    @media (max-width: $mobile) {
      padding: 0 16px;
    }
  }

  .network-nav {
    padding: 6px 15px 6px 30px;
    position: relative;
    @include transition(all ease-in-out 0.2s);

    .vertical-menu {
      height: 20px;
    }

    .row.bg-white {
      position: absolute;
      right: -4px;
      bottom: 43px;
      box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.24);
      .col-6 {
        max-width: 100%;
        flex: 100%;

        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }

    &-suboptions {
      ul.bg-white {
        &:not(:empty) {
          padding-top: 15px;
        }

        li {
          &:hover {
            color: $primary;
          }
        }
      }

      .more-actions-options-outer {
        &:not(:empty) {
          padding-top: 15px;
          padding-bottom: 20px;
        }
      }

      .shar-badge {
        background: rgba(0, 0, 0, 0.3);
        font-size: 12px !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .recent-activity-padding {
    @include padding(8px, 40px, 0px, 40px);
  }
}

.communication-nav {
  .networks {
    li {
      cursor: pointer;
      &:hover {
        .network-nav {
          background: $hover-light-primary;
          border-radius: 4px;
        }
        a:hover {
          color: #3d3c40;
        }
      }
      &.active {
        .network-nav {
          background: $hover-light-primary;
        }
      }
    }
  }
}

.line-height-sm {
  line-height: 18px;
}

ul li .badge {
  width: 45px;
  @include padding(6px, 8px, 6px, 8px);
  border-radius: 8px;
  font-size: 16px;
  color: $white;
  font-family: $font-bold;
  background-color: $color-warning;
}

.border-bottom-dotted {
  border-bottom: 1px dotted grey;
}

.close-btn {
  position: absolute;
  right: 24px;
  font-size: 45px;
  cursor: pointer;
  color: #3d3c40;
  line-height: 1;

  @media (max-width: $mobile) {
    font-size: 35px;
  }
}

.interaction-padding {
  @include padding(85px, 0px, 8px, 16px);
}

.network-view,
.campaigns-list {
  .network-box-shadow {
    box-shadow: 0px -6px 6px rgba(0, 0, 0, 0.15);
    border-right: 1px solid #dddddd;
    color: #3d3c40;
  }

  .network-item-header {
    display: flex;
    align-items: center;
    @include padding(16px, 24px, 0px, 16px);
  }

  input[type="text"] {
    width: 100%;
    font-size: 18px;
    background-color: white;
    color: #848282;
    padding: 8px 20px 8px 40px;
  }

  .item-search {
    @include margin(28px, 24px, 28px, 16px);
  }

  .select-add-network {
    @include padding(0px, 24px, 18px, 5px);
    @extend .d-flex;
    @extend .justify-content-between;
    @extend .align-items-center;
    @extend .font-18-medium;
  }

  .dropdown-toggle::after {
    margin-left: 2.13rem;
  }

  .dropdown:focus {
    outline: none;
  }

  .dropdown-item {
    @include padding(0.75rem, 0.8rem, 0.75rem, 0.8rem);
  }

  .btn:focus {
    box-shadow: none;
  }

  .btn {
    @include padding(0.375rem, 0.15rem, 0.375rem, 0.15rem);
  }

  .border-transparent {
    border: 1px solid transparent;
  }

  .custom-dropdown .lists-position {
    position: absolute;
    will-change: transform;
    top: 0px;
    left: -10px !important;
    transform: translate3d(0px, 38px, 0px);
  }

  .custom-dropdown {
    border: 1px solid transparent;
    border-radius: 4px 4px 0px 0px;
    padding-left: 9px;
    width: 160px;
  }

  .custom-dropdown.show.dropdown {
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  }

  .show.dropdown .dropdown-menu {
    background: $white;
    margin: 0;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    border-radius: 0rem 0rem 0.25rem 0.25rem;
    border-top: 0;
  }

  .dropdown-item:hover,
  .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: $hover-light-primary;
    @include transition(all ease-in-out 0.5s);
  }

  .members-list {
    li {
      @include transition(all ease-in-out 0.5s);
      cursor: pointer;
      padding-bottom: 4px;
      &:hover {
        background: $hover-light-primary;
        a:hover {
          color: #3d3c40;
        }
      }
    }
  }

  .member {
    @include padding(12px, 24px, 12px, 16px);
  }
}

.member-info .profile-info {
  box-shadow: none !important;
}

.interactions {
  @extend .d-flex;
  @extend .justify-content-between;
  @extend .align-items-center;
  @extend .font-18-medium;
}

#menu-list {
  display: none;
  position: relative;
  z-index: 1;
}

#menu-summary {
  display: none;
}

.ModalOpen {
  animation: openModal 0.4s ease-out forwards;
}

@keyframes openModal {
  0% {
    transform: translateX(-10%);
    opacity: 0.3;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.dev-goals .col {
  flex: 0 0 20%;
  max-width: 20%;
}

.col-cont {
  width: 32px;
  height: 32px;
  margin-left: 1px;
  margin-bottom: 1px;
}

.dropdown_primary {
  .secondary-menu-dropdown {
    display: inline-block;
    border-radius: 20px;

    &-img {
      &[aria-expanded="true"] {
        border-radius: 20px 20px 0 0;

        + .dropdown-menu {
          border-radius: 0 0 20px 20px;
        }
      }

      border-radius: 20px;
      padding: 0 20px !important;
      min-width: 180px;
      min-height: 42px;
      font-size: 1.125rem;
      background: {
        image: url("../assets/images/icons/chevron_down_white.svg");
        color: $primary;
        size: 12px;
        repeat: no-repeat;
        position: calc(100% - 20px) calc(1em - 0px),
          calc(100% - 15px) calc(1em + 2px), calc(100% - 0.5em) 0.5em;
      }

      &:after {
        border-top: none;
      }

      @media (max-width: $hd-desktop) {
        min-width: 160px;
        height: 38px;
        font-size: 1rem;
      }
    }

    .dropdown-menu {
      top: 36px !important;
      left: 0 !important;
      width: 100%;
      transform: none !important;
      border: none;

      @media (max-width: $hd-desktop) {
        font-size: 0.875rem;
      }
    }
  }

  .btn-primary {
    min-width: 180px;
    height: 42px;
    font-size: 1.125rem;
    @media (max-width: $hd-desktop) {
      min-width: 160px;
      height: 38px;
      font-size: 1rem;
    }
  }
}

.shar-badge {
  @media (max-width: $hd-desktop) {
    width: 38px !important;
    height: 22px;
    padding: 3px 0 !important;
  }
}

.font-20-bold {
  font-size: 20px;
  font-family: $font-bold;
}

.font-18-medium {
  font-size: 18px;
  font-family: $font-medium;
}

.font-16-regular {
  font-size: 16px;
  font-family: $font-family-base;
}

.font-14-medium {
  @extend .font-14;
  font-family: $font-medium;
}

.icons-position {
  bottom: 0;
  left: 0;
  right: 0;
  width: 250px;
}

.campaign-details-image {
  > img {
    width: 330px;
    height: 220px;
    object-fit: cover;
    overflow: hidden;
    border-radius: 4px;
  }

  div {
    max-width: 330px;
    max-height: 220px;
  }
}

.back-button-sm {
  z-index: 10;

  .width-10 {
    width: 18px;
    height: 18px;
  }
}

.text-active {
  margin-left: -1px;
}

.profile-user {
  color: $text-grey;
}

.text-width {
  max-width: calc(100% - 60px);
}

.menu-vertical {
  width: 6px;
  opacity: 0;
  pointer-events: none;
  transition: 0.2s ease-in-out;
}

.approve-action-icon {
  width: 12px;
  @media (min-width: $hd-desktop) {
    width: 16px;
  }
}

.campaign-name-container {
  width: calc(100% - 12px);
}

.campaign-name {
  width: calc(100% - 44px);
}

.members-list {
  li {
    &:hover {
      .menu-vertical {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
  li.active {
    background: $hover-light-primary;
  }
}

.list-item-loader {
  width: 6px;
  pointer-events: none;
}

.live-feed-loader {
  pointer-events: none;
}

@media (max-width: $hd-tab) {
  .tab-view-height {
    min-height: calc(100vh - 127px);
  }
}

.vertical-menu-option {
  min-width: 6px;
}

.active-list-item {
  background: #e6f2f5;
}

.map-button {
  font-size: 35px;
}

.map-legend {
  position: absolute;
  bottom: 34px;
  right: 18px;
  background: #fff;
  padding: 10px 16px;
  max-width: 230px;
  width: 100%;
  .map-icon-image {
    max-width: 24px;
  }
}

.map-close-button {
  position: absolute;
  z-index: 1000;
  top: 22px;
  right: 37px;
}

.map-button-color {
  color: #ffffff;
  &:hover {
    color: #ffffff;
  }
}

.see-all-back {
  margin-top: -3px;
  margin-right: 15px;
}

@media (max-width: $hd-tab) {
  .see-all-back {
    display: none;
  }
}

.text-red {
  color: #dd2741;
}

.float-edit {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 50px;
  border-radius: 50%;
  height: 50px;
  background-color: $primary;
  border: 0px;
}

.sub-option-menu {
  position: absolute;
  right: -4px;
  bottom: 43px;
  box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.24);
  min-width: 160px;
  &-shop {
    z-index: 1;
    bottom: 68px;
  }
  .col-6 {
    max-width: 100%;
    flex: 100%;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
