.border-radius-8 {
  border-radius: 8px !important;
}

.btn-td{
  width: auto;
  font-size: 12px;
  min-width: auto;
  height: auto;
  padding: 5px 10px;
}

.payable-info {
  input[type="text"] {
    width: 90%;;
  }

  .col {
    max-width: 220px;
  }
}

.card-details iframe {
  height: 48px;
}

.hide-element{
  display: none;
}

.flex-inherit {
  flex: inherit;
}

.payment-card {
  max-width: 420px;
  margin-right: 5%;
}

.finance-sort-by select {
  height: 40px;
  border-radius: 4px;
  font-weight: bold;
}

.cnd-price-box {
  border-radius: 15px;
  box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.5);
  width: 160px;
  height: 40px;

  &-outer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &-td {
    width: 200px;
  }

  &-dropdown {
    background-color: #fff;
    min-width: 340px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    border-radius: 15px;
    display: flex;
    align-items: center;

    &::after {
      display: none;
    }
    svg {
      margin-left: auto;
      width: 10px;
      margin-right: 10px;
    }

    img {
      width: 30px;
      height: 30px;
    }
  }
}

.receipt-view-callout {
  .page-sidebar-body {
    .col-lg-7 {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0;
      
      .receipt-screen {
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
        border: none;
        border-radius: 15px;

        > img,
        > .display-6.font-bold.my-3,
        > .font-14 {
          display: none;
        }

        table.my-4 {
          margin: 0 !important;

          .font-bold {
            font-family: $font-family-base !important;
          }
        }
      }
    }
  }
}

.select-option-callout {
  .page-sidebar-head  {
    img {
      margin-right: 16px;
      height: 20px;
    }
  }
}

.view-receipt-btn {
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  border-radius: 15px;
}

.icon-rotate {
  transform: rotate(180deg);
}