.light-bg {
  background: $bg-light-grey ;
}

.feed-element {
  box-shadow: 0 4px 8px 0 rgba(5,13,30,0.75);
  border-radius: 4px;
}

.refresh-icon {
  width: 45px;
  height: 45px;
}

.chip-light {
  background: rgba(0,0,0,0.05);
  border-radius: 9px;
}

.chip-sm {
  width: max-content;
}

.feed-profile {
  min-width: 28px;
  img {
    width: 35px;
  }
}

.collapse-icon {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;

  &:active {
    color: currentColor !important;
  }

  > svg {
    font-size: 22px;
  }

  &:after {
    display: none;
  }
}

.field-padding {
  padding-left: 20px;
  padding-right: 80px;
}

.story-creator {
  min-width: 45px;
  img{
    width: 45px;
  }
}

.story-form {

  textarea.form-control {
    height: 70px;
    background: rgba($bg-light-grey, 0.4);
    font-size: 0.875rem;

    @media (max-width: $desktop) {
      width: 100%;
    }
  }

  select.form-control {
    background-color: rgba($bg-light-grey, 0.4);
    height: 45px;
    font-size: 0.875rem;

    @media (max-width: $desktop) {
      width: 100%;
    }
  }
}

.feed {
  &-options {
    top: auto;
    bottom: 34px;
    right: 0;
    z-index: 2;
    min-width: 220px;
    transform: translateY(100%);
    display: none;
  }

  &-icon {
    &-menu {
      svg {
        position: relative;
        z-index: 100;
      }
    }

    &-trash {
      svg {
        width: 18px;
      }
    }
  }

  &-img {
    text-align: center;

    img {
      max-height: 400px;
    }

    div{
      max-width: 100%;
      max-height: auto;
      z-index: 1;
      margin: auto;
    }
  }

  &-story-form {
    select {
      max-width: 220px;
      max-height: 50px;
    }
  }
  &-media-heading {
    width: 120px;
    height: 30px;
    background: $color-blue;
  }

  &-bg-position {
    margin: -24px;
    min-height: 100%;
    height: auto;
  }
}

.more-actions-options {
  &:not(:empty) {
    padding: 20px 30px;
    margin: 4px 4px 0;
  }

  &-icon {
    width: 20px;
  }

  &-list {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  &-outer {
    margin-top: 37px;
  }

  .client-profile-more-icon {
    width: 4px;
  }

  > .align-items-center.mb-2 {
      position: relative;

    > .no-gutters.bg-white.mx-1.mt-1.p-3.mb-0.row {
        position: absolute;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.38);
        right: 0;

        .col-6 {
          flex: 0 0 100%;
          max-width: 100%;
        }

        &:empty {
          display: none;
        }
    }
  }

}

.shar-color-picker {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-image: linear-gradient(180deg, #82ADD1 0%, #A44B4B 100%);
  margin-left: 32px;

  @media (max-width: $hd-tab) {
    margin-left: 0;
    margin-top: 20px;
  }

  &-container {
    position: relative;

    input {
      margin-bottom: 0 !important;
    }

    .error {
      width: 100%;
      order: 3;
    }

    .sketch-picker  {
      position: absolute;
      right: -30px;
      top: 0;
      transform: translateX(-100%);
      z-index: 10;
      margin-bottom: 20px;
    }
  }
}

.live-feed-stories-outer {
  max-width: 573px;
  margin: 30px auto 0 auto;

  .mt-5.text-center {
    width: 100%;
  }

  @media (min-width: $mobile) {
    .article-outer {
      margin-bottom: 36px !important;

      &:nth-child(odd) {
        margin-right: 45px;
      }

      &:nth-child(even) {
        margin-right: 0;
      }
    }

    .article-outer {
      overflow: visible !important;

      .article-main-img-wrap {
        img {
          border-radius: 15px 15px 0 0;
        }
      }
    }
  }

  @media (max-width: 1275px) and (min-width: $hd-tab) {
    max-width: 520px;
    justify-content: center;

    .article-outer {
      margin-bottom: 36px !important;

      &:nth-child(odd) {
        margin-right: 0;
      }
    }
  }

  > center {
    margin: auto;
  }
}

.landing-page-stories {
  &.welcome-card-sm {
    a.position-relative {
      img {
        height: 350px;
        object-fit: cover;

        @media (max-width: $hd-tab) {
          height: 200px;
        }
      }
    }
  }

  @media (min-width: $mobile) {
    .article-outer {
      margin-bottom: 36px !important;
    }

    .article-outer {
      overflow: visible !important;
      width: 30%;

      .article-main-img-wrap {
        img {
          border-radius: 15px 15px 0 0;
        }
      }
    }
  }
}

.dropdown-styler {
  + .round.form-control {
      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
      border-radius: 10px;
  }
}

.rank-dropdown-opner {
  + .feed-options {
    display: block;
  }
}
