.campaings-layout {
  color: $primary-color;
  @include padding(28px, 0px, 40px, 80px);

  .form-control {
    border: 1px solid #979797;
    border-radius: 6px;
  }

  .form-control:focus {
    border: 1px solid $border-focus;
    box-shadow: none;
  }

  .form-control::placeholder {
    font-family: $font-light-italic;
    color: $primary-color;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
  }

  select.round {
    background-image: url(../assets/images/chevron_down.svg);
    background-position: calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px), calc(100% - 0.5em) 0.5em;
    background-size: 12px;
    background-repeat: no-repeat;
  }

  select.round:focus {
    background-image: url(../assets/images/chevron_up.svg);
    background-position: calc(100% - 20px) calc(0.8em + 2px),
      calc(100% - 15px) calc(1em + 2px), calc(100% - 0.5em) 0.5em;
    background-size: 12px;
    background-repeat: no-repeat;
  }

  .custom-container {
    display: block;
    position: relative;
    padding-top: 4px;
    padding-left: 28px;
    margin-bottom: 10px;
    font-family: $font-family-base;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .custom-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .custom-checkmark {
    position: absolute;
    top: 6px;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #fff;
    border: 1px solid #979797;
    border-radius: 5px;
  }

  .custom-container input:checked ~ .custom-checkmark {
    border: 1px solid $primary;
    background: $primary;
  }

  .custom-checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .custom-container input:checked ~ .custom-checkmark:after {
    display: block;
  }

  .custom-container .custom-checkmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid $white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .items-central {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .target-input {
    input {
      opacity: 0;
    }
  }

  .placeholder-font {
    font-family: $font-light-italic;
    font-size: 1rem;
    color: $primary-color;
  }

  .add-file-height {
    border: 1px dashed $border-color;
    height: 180px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    width: 325px;
    position: relative;

    @media (max-width: $mobile) {
      width: 100%;
    }

    > .text-center {
      margin: auto;
      padding-bottom: 1rem;
    }
  }
}

.DraftEditor-root {
  margin-top: -15px;
}

.rdw-editor-main {
  height: 200px;
  padding-left: 5px;
}

.demo-wrapper {
  border: 1px solid rgb(221, 221, 221);
  padding: 10px;
  border-radius: 6px;
}

@media (max-width: 991px) {
  .campaings-layout {
    @include padding(20px, 20px, 20px, 20px);
  }
}

.upload-wrap {
  img {
    max-height: 270px;
  }
}

.events-none {
  pointer-events: none;
}

.create-story-form {
  @media (max-width: $desktop) {
    max-width: 325px;
  }

  @media (max-width: $mobile) {
    .row {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.MuiPickersToolbar-toolbar,
.MuiPickersDay-isSelected,
.MuiPickersClockPointer-pointer,
.MuiPickersClockPointer-thumb,
.MuiPickersClock-pin,
.MuiPickerDTTabs-tabs {
  background-color: $primary !important;
}

.MuiPickersClockPointer-thumb,
.MuiInput-underline:after {
  border-color: $primary !important;
}

.public-DraftEditorPlaceholder-inner {
  font-size: 16px;
  color: #444;
  font-family: $font-light-italic;
}

.checkbox-payable .custom-control-label::before {
  top: 0.1rem;
}

.checkbox-payable .text-payout {
  color: $primary;
}

.text-payout:hover {
  text-decoration: underline;
}

.space-nowrap {
  white-space: nowrap;
}

.space-sm-wrap {
  @media (max-width: $mobile) {
    white-space: normal;
  }
}
.PrivateTabIndicator-colorSecondary-486 {
  background-color: $alternate-color !important;
}

#image_url {
  opacity: 1;
}

.story-content-input {
  max-width: 705px;
}

#schedule_time {
  .MuiInput-input {
    padding: 0 !important;
  }
}

.post-file-uploader {
  opacity: 0;
  width: 0;
  height: 0;
}

.post-media-uploader {
  margin-top: -33px;
  margin-left: 10px;
}

.post-textarea {
  height: 60px !important;
  width: 585px !important;
  resize: none;
  margin-bottom: 35px;

  @media (max-width: $tab) {
    width: 320px !important;
  }

  @media (max-width: $mobile) {
    width: 270px !important;
  }
  &.upload-box-sizing {
    height: 120px !important;
    margin-bottom: 8px;
  }
}

.form-border-radius {
  border-radius: 4px;
}

.post-media-preview {
  width: 267px;
  height: 150px;
  object-fit: contain;
  border: 1px solid $primary;
  border-radius: 4px;
}

.post-media-close {
  font-size: 18px;
  line-height: 1;
  color: $white;
  background: $black;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  right: -11px;
  top: -11px;
  cursor: pointer;
}

.post-video-upload {
  width: 267px;
  height: 150px;

  * {
    max-width: 100%;
    max-height: 150px;
  }
}

#start_at:not(.event-date-picker),
#end_at:not(.event-date-picker),
#schedule_start {
  input[type="text"] {
    padding-bottom: 0;
  }
}

.upload-thum-video {
  video {
    position: absolute;
    width: 50%;
    max-height: calc(100% - 66px);
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    top: 50%;
  }
}

.upload-img-panel {
  + small {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 10px;
    z-index: 1;
  }
}

.demo-editor.rdw-editor-main,
.region-tab,
.upload-box-sizing,
.menu-overflow,
.discussion-height,
.campaign-discussion-height,
.description-scroll {
  @extend .scroll-y;
  &::-webkit-scrollbar {
    width: 4px;
  }
}

.css-1wy0on6 {
  display: none !important;
}

.post-pic {
  height: 180px;
  background-color: #dbdbdb;

  img.width-20[alt="add_circle_icon"] {
    opacity: 0;
  }

  small {
    margin-bottom: 5px !important;
  }

  .upload-thum-img {
    width: 100%;
    position: relative;
    z-index: 1;
    height: 100%;
    display: flex;

    .upload-wrap {
      display: flex;
      justify-content: center;
      width: 100% !important;

      img {
        background: transparent !important;
        -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF,endColorstr=#00FFFFFF)" !important; /* IE8 */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF,endColorstr=#00FFFFFF)  !important; /* IE6 & 7 */
        zoom: 1;
        background-color: #dbdbdb;
        height: fit-content;
      }
    }
  }

  .error:not(:empty) {
    position: absolute;
    bottom: -20px;
    margin: 0;
    left: 0;
    width: 100%;
    text-align: center;
    max-width: initial;
    padding-top: 2px;
  }
}

.post-uplodad-label {
  input {
    display: none;
  }

  .add-icon-upper {
    width: 40px;
    height: auto;
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    background: #fff;
    border-radius: 50%;
  }
}

.pwa-create {
  &-inner {
    padding-top: 32px;
    padding-bottom: 32px;

    .form-control {
      margin-bottom: 20px;

      &::placeholder {
        font-family: $font-medium !important;
        font-size: 18px !important;
        color: #344356;
      }
    }

    textarea.form-control {
      height: 196px;
      padding: 20px;
    }

    .error:not(:empty) {
      margin-top: -15px;
    }
  }
}

.pwa-upload-field {
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  span {
    font-family: $font-medium !important;
    font-size: 18px !important;
    color: #344356;
  }

  + input {
    display: none;
  }
}

.pwa-share-post {
  font-family: $font-medium !important;
  font-size: 18px !important;
  color: #344356;
  width: 90px !important;
  height: 70px !important;

  &:not(:last-child) {
    margin-right: 12px;
  }
}

.save-post-btn {
  min-width: 200px !important;
  border-radius: 25px !important;

  @media (max-width: $hd-tab) {
    margin-top: 20px;
  }
}

.attacment-icon {
  width: 15px;
}

#create-story,
#create-event {
  .page-sidebar-outer {
    flex: 0 0 100%;
    max-width: initial !important;
    padding-top: 20px;
    margin-top: 0;

    .page-sidebar-inner {
      width: 100%;
      box-shadow: none;
    }

    .page-sidebar-head {
      display: none !important;
    }

    .page-sidebar-body {
      padding: 0 !important;
      .article-outer {
        &:nth-child(2) {
          margin-left: 10px;
        }
      }
    }

    .sidebar-form-width {
      max-width: initial;

      .post-pic {
        margin: initial;

        + .my-2.ml-1.display-8.font-medium {
          max-width: 320px;
        }
      }
    }
  }

  .btn-close {
    svg {
      width: 35px;
    }
  }

  nav.nav.nav-tabs {
    max-width: 700px;
  }

  @media (max-width: $hd-tab) {
    .align-items-center.mt-1 {
      padding: 0 10px;
    }

    .btn-close {
      margin-right: 20px;
    }

    .create-article-upload {
      width: 100%;
    }

    .sidebar-tabs-outer {
      .nav.nav-tabs {
        width: 100%;
        .nav-link {
          width: 50%;
          max-width: 50%;
          min-width: auto !important;
        }
      }
    }

    .page-sidebar-body-article {
      padding: 0;
    }

    .page-sidebar-outer {
      margin-top: 20px;
    }

    .tab-content {
      padding: 20px 5px !important;
    }
  }

  .position-sidebar-fixed {
    position: fixed;
    padding: 24px !important;

    .page-sidebar-head {
      display: flex !important;
      border-bottom: none !important;

      > img,
      > svg,
      > span.font-bold.display-8.ml-2 {
        display: none;
      }
    }
  }

  .page-sidebar-body {
    .article-create-tabs {
      > .nav.nav-tabs {
        display: none;
      }

      > .tab-content {
        padding-top: 20px;
      }
    }

    .sidebar-tabs-outer {
      max-width: 450px;
    }
    .campaigns-actions {
      margin-left: 0px !important;
    }
  }
  .search-input-callout {
    margin-left: 0 !important;
  }
}

#menu-summary {
  .article-page-outer {
    max-width: 100%;
    width: 100%;
    padding: 0;
  }

  .article-page-back {
    transform: none;
    margin-top: 6px;
    margin-right: 10px;
  }

  .ml-lg-n3 {
    margin-left: 0 !important;
  }

  .tab-view-height {
    padding: 0 !important;

    @media (max-width: $hd-tab) {
      .close-button {
        display: none;
      }
    }
  }

  .row.flex-lg-nowrap.mx-0.h-100.scroll-y {
    padding: 24px;
    height: auto !important;
  }

  .article-page-carousel-main-img {
    height: 300px;
  }

  .article-page-side-outer {
    position: fixed;

    @media (max-width: $hd-tab) {
      top: 540px;
    }
  }

  .feed-bg-position {
    margin: 0;
  }

  .article-outer {
    overflow: hidden;
  }
}

.pwa-share-post.d-none {
  opacity: 0;
}

.add-category {
  padding: 26px 34px;
}

.courses-tab {
  .nav-tabs {
    border: none;

    .nav-link {
      font-size: 16px;
      color: #344356;
      border: none;
      border-bottom: 4px solid #e7e7e7;
      font-weight: 500;
      padding: 14px 32px;

      &.active {
        font-weight: bold;
        border-color: $primary;
      }
    }
  }

  .sidebar-form {
    .post-pic small {
      position: relative;
      transform: none;
      display: block;
      left: 0;
      margin-top: 10px;
    }

    .post-pic.create-article-upload .m-auto.upload-wrap img,
    .upload-thum-video {
      border-radius: 0;
      height: fit-content;
      width: fit-content;
      object-fit: cover;
    }

    .upload-thum-video video {
      width: 100%;

      height: 168px;
      max-height: initial;
    }

    .post-pic .m-auto.upload-wrap {
      max-width: 100%;
    }
  }

  .course-edit-sec {
    input[type="text"] {
      max-width: 300px;
      border-radius: 6px;
      height: 40px;
    }

    .btn {
      height: 40px;
    }
  }

  .btn-section-save {
    height: 40px;
  }

  .create-assignments-modal {
    .shar-form.search-form-pwa.sidebar-form input[type="text"],
    .shar-form.search-form-pwa.sidebar-form .article-dropdown {
      margin-left: 0;
      width: 430px;
    }

    .shar-form.search-form-pwa.sidebar-form .mcq-set input[type="text"] {
      width: 100%;
    }

    .shar-form.search-form-pwa.sidebar-form
      .mcq-set-questions
      input[type="text"] {
      height: 50px;
      margin-bottom: 0;

      &::placeholder {
        font-size: 16px;
      }
    }

    .msq-activator {
      position: relative;

      input {
        background: linear-gradient(180deg, #f3f3f7 30%, rgba(#422a66, 0.2));

        &::placeholder {
          font-weight: bold;
          font-size: 16px !important;
        }
      }

      svg {
        position: absolute;
        right: 20px;
        top: 23px;
        cursor: pointer;
      }
    }
  }

  .mcq-set {
    padding: 30px;
    box-shadow: 2px 2px 4px 1px rgba(39, 34, 98, 0.22);
    border-radius: 10px;
  }

  .btn-edit {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    border-radius: 25px;
    width: 120px;
    height: 50px;
  }
}

.text-primary-light {
  color: #4291b6;
}

.shar-form.search-form-pwa.sidebar-form .demo-wrapper {
  height: 450px;
  width: 80%;
  font-size: 18px;
  font-weight: 500;
  font-family: $font-medium;
  padding: 20px;

  .public-DraftStyleDefault-block {
    margin-top: 0;
    margin-bottom: 0;
  }

  .rdw-editor-main {
    height: auto;
    padding-top: 12px !important;
  }

  .rdw-editor-toolbar {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  }

  @media (max-width: $hd-tab) {
    box-sizing: border-box;
    width: 100%;
  }
}

#create-story,
#create-post,
#create-initiative,
#create-event {
  padding: 0 !important;

  > .shar-form {
    padding: 40px !important;
  }

  &.ModalOpen {
    + .ModalOpen {
      display: none !important;
    }
  }

  .success-card-outer {
    .article-outer,
    .my-group,
    + .text-center {
      margin-left: 10px;
    }

    + .text-center {
      text-align: left !important;
    }
  }
}

.lesson-img {
  background-image: linear-gradient(
    180deg,
    rgba(166, 166, 166, 0.16) 0%,
    rgba(0, 0, 0, 0.23) 100%
  );
  border-radius: 5px;
  width: 55px;
  height: 55px;

  img {
    width: 55px;
    height: 55px;
    border: 1px solid #ccc;
    border-radius: 5px;
    object-fit: cover;
  }

  &-arrow {
    width: 30px;
    height: 30px;

    svg {
      width: 8px;
      height: 12px;
      transform: rotate(180deg);
    }
  }
}

.course-categories-dropdown {
  &.show {
    height: auto !important;
  }
}

.cs-lesson-indicators {
  color: #7c7c7c;

  li {
    width: 8px;
    height: 8px;
    background-color: #7c7c7c;
    border-radius: 50%;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 12px;
    }
    &.active {
      background-color: $primary;
    }
  }
}

.lesson-score {
  width: 75px;
  height: 75px;
}

.mcq-set-main {
  background-image: linear-gradient(180deg, #ffffff 0%, #64cbff 100%);

  .rounded-circle {
    background-image: linear-gradient(180deg, #0088cc 0%, #02608f 100%);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    color: #fff;
  }
}

.mcq-set-light {
  background-image: linear-gradient(180deg, #71cefb 0%, #01689c 100%);

  .rounded-circle {
    background: #f6f6fc;
    border: 1px solid #979797;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  }
}

.mcq-set-dark {
  background-image: linear-gradient(
    180deg,
    #0088cc 0%,
    #0073ac 100%
  ) !important;

  .b-600.font-14.mt-1 {
    color: $primary;
  }
}

.course-sidecallout {
  background: #ffffff;
  box-shadow: 2px 2px 4px 1px rgba(39, 34, 98, 0.22);
  border-radius: 10px;
  color: #000 !important;

  &-active {
    box-shadow: 2px 2px 4px 1px rgba(39, 34, 98, 0.22), inset 0 0 0 4px $primary;

    .mcq-set-right {
      height: 92px;
      position: relative;
      top: 4px;
      right: 4px;
      border-radius: 0 6px 6px 0;
    }
  }

  .toolbox-card-checkbox-outer:not(.assignment-checkbox) {
    width: 15px;
    height: 15px;
    top: -15px;
    left: -14px;
    position: relative;

    svg {
      position: relative;
      top: -6px;
    }
  }
}

.mcq-set-right {
  width: 100px;
  height: 100px;
}

.assignment-list {
  box-shadow: 2px 2px 4px 1px rgba(39, 34, 98, 0.22);
  border-radius: 10px;

  svg {
    margin-left: -36px;
  }

  &-inner {
    img {
      height: 75px;
    }
  }

  @media (max-width: $hd-tab) {
    .assignment-list {
      padding: 0 !important;
      box-shadow: none;

      .btn {
        width: 100%;
        margin-top: 15px;
      }
    }
  }
}

.btn.btn-half-rounded {
  min-width: 120px;
  height: 50px;
  border-radius: 15px;
}

.cs-page-side-outer {
  width: 500px;
}

.lesson-table-body {
  transform: translateX(20px);
}

.add-new-section {
  margin-top: -21px;
  input {
    max-width: calc(100% - 250px);
    border-radius: 5px;
    height: 40px;

    &::placeholder {
      color: #495057 !important;
      font-weight: 400;
      font-family: $font-family-base !important;
    }
  }
}

.assignment-icon {
  transform: scale(0.7);
}

.pwa-callout-decorator {
  width: 60px;
  height: 5px;
  background-color: #d8d8d8;
  border-radius: 3px;
  margin: auto;
}

.assignment-callout {
  &.page-sidebar-outer {
    max-width: 100%;
    z-index: 21;
  }

  .shar-form.search-form-pwa.sidebar-form .demo-wrapper {
    width: auto;
    height: 230px;
    border-radius: 10px;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);

    @media (max-width: $hd-tab) {
      height: auto;
    }
    .rdw-editor-toolbar {
      width: fit-content;
    }
  }

  .page-sidebar-body {
    max-width: 1280px;
    margin: auto;
  }

  @media (max-width: $hd-tab) {
    .pwa-callout-decorator {
      position: absolute;
      top: 10px;
      left: calc(50% - 30px);
      margin-top: 0 !important;
    }
  }

  .page-sidebar-body {
    > .d-flex.align-items-center.pb-2 {
      box-shadow: 2px 2px 4px 1px rgba(39, 34, 98, 0.22);
      border-radius: 10px;

      .course-sidecallout {
        box-shadow: none;
      }
    }

    .course-sidecallout {
      ul.list-unstyled {
        margin-left: 0 !important;

        .demo-wrapper {
          padding: 5px;
        }
      }
    }
  }
}

.lesson-tile-primary {
  width: 100%;
  max-width: 850px;
}

.download-btn-color-1 {
  stop-color: $primary;
}

.download-btn-color-2 {
  stop-color: darken($primary, 10%);
}

.btn-download {
  width: 240px;
  height: 50px;
  background: #fff;
  font-weight: 600;
  color: $primary;
  font-size: 16px;
  position: relative;
  padding-left: 45px;

  svg {
    position: absolute;
    left: -20px;
    top: -29px;
    transform: scale(0.2);
  }

  @media (max-width: $hd-tab) {
    width: 100%;
    margin-top: 16px;
  }
}

.correct-answer-bg {
  color: #fff;
  background-image: linear-gradient(
    180deg,
    #0085c8 0%,
    #96ceea 100%
  ) !important;
}

.wrong-answer-icon {
  transform: scale(1.1);
}
.text-captalize {
  text-transform: capitalize;
}

.tab-content .rdw-editor-main {
  height: 350px !important;
  padding-left: 5px;
}

.show-quiz {
  display: block;
}
.hide-quiz {
  display: none;
}

body .shar-form.search-form-pwa select.round {
  background-position: calc(100% - 16px) 50%;
}

.event-details-head {
  @media (max-width: $hd-tab) {
    margin-top: 0 !important;
    flex-wrap: nowrap !important;
  }
}

.event-close-btn {
  @media (max-width: $hd-tab) {
    top: 1px !important;
    position: relative !important;
    padding: 0 !important;
    left: 0 !important;
    height: auto;
    margin-right: 24px;

    .night-mode & {
      filter: invert(1);
    }
  }
}

.page-sidebar-outer,
.gil-pwa-callout {
  @media (max-width: $fhd-tab) {
    &,
    & .create-call-out {
      border-radius: 15px 15px 0 0;
      top: 70px !important;
      box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.5);
      overflow: auto;
      animation: slideUp 0.3s ease-in-out;
      z-index: 2000 !important;
    }

    &-slideMore {
      .page-sidebar-inner {
        min-height: auto;
      }
    }

    &-passport {
      .passport-select-set input[type="checkbox"]:checked + .passport-item {
        background-image: linear-gradient(180deg, #4f479b 0%, #272262 100%);
      }

      .page-sidebar-inner {
        min-height: auto;
      }

      .page-sidebar-body {
        padding: 0 48px;
        padding-bottom: 24px;
      }

      .display-8.font-semi-bold {
        margin-bottom: 24px;
        font-size: 18px !important;
        font-family: $font-bold;
        margin-top: 10px;
      }

      .passport-select-set {
        margin-right: 8px;

        &:nth-child(even) {
          margin-right: 8px;
        }
      }
    }

    .show-in-pwa {
      display: block !important;
    }

    form.shar-form {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin-top: -30px;
    }

    .profile-image-uploader-outer {
      order: -1;

      .show-in-pwa {
        margin-top: 24px !important;
      }

      small {
        margin-top: 0;
      }

      .post-pic {
        width: 180px;
        border-radius: 50%;
        margin: auto;
      }

      .m-auto.upload-wrap img {
        border-radius: 50%;
        margin: 0;
        width: 100%;
        max-height: 180px;
        height: 180px !important;
      }
    }

    .form-label,
    .group-section-outer:empty {
      display: none !important;
    }

    .page-sidebar-head {
      border-bottom: none !important;

      span.font-bold.display-8.ml-2 {
        display: block;
        width: 100%;
        margin-left: 0 !important;
        text-align: center;
        font-size: 18px !important;
        // margin-top: 27px;
        margin-bottom: 24px;

        &:empty {
          margin: 0 !important;
        }
      }

      svg,
      img {
        display: none;
      }
    }

    &.direct-message-callout,
    &.pwa-account-callout,
    &.gil-conversation-callout {
      #root:not(.chat-page-main) .header-unpin ~ .px-0.h-100.container-fluid & {
        top: 20px !important;

        .page-sidebar-inner {
          min-height: calc(100vh - 20px);
        }
        .message-box-view {
          height: calc(100vh - 265px);
        }
      }

      .page-sidebar-inner {
        min-height: calc(100vh - 106px);
      }
      .message-box-view {
        height: calc(100vh - 315px);
      }

      #messageList {
        position: relative;
        top: -40px;
        height: calc(100% + 40px) !important;
      }
    }
    .create-card-pwa-outer .page-sidebar-body {
      min-height: calc(100vh - 265px);
    }
  }
}

.page-sidebar-outer,
.gil-pwa-callout {
  @media (max-width: $hd-tab) {
    &,
    & .create-call-out {
      border-radius: 15px 15px 0 0;
      top: 70px !important;
      box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.5);
      overflow: auto;
      animation: slideUp 0.3s ease-in-out;
      z-index: 2000 !important;
    }

    &-slideMore {
      .page-sidebar-inner {
        min-height: auto;
      }
    }

    &-passport {
      .passport-select-set input[type="checkbox"]:checked + .passport-item {
        background-image: linear-gradient(180deg, #4f479b 0%, #272262 100%);
      }

      .page-sidebar-inner {
        min-height: auto;
      }

      .page-sidebar-body {
        padding: 0 48px;
        padding-bottom: 24px;
      }

      .display-8.font-semi-bold {
        margin-bottom: 24px;
        font-size: 18px !important;
        font-family: $font-bold;
        margin-top: 10px;
      }

      .passport-select-set {
        margin-right: 8px;

        &:nth-child(even) {
          margin-right: 8px;
        }
      }
    }

    .show-in-pwa {
      display: block !important;
    }

    form.shar-form {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin-top: -30px;
    }

    .profile-image-uploader-outer {
      order: -1;

      .show-in-pwa {
        margin-top: 24px !important;
      }

      small {
        margin-top: 0;
      }

      .post-pic {
        width: 180px;
        border-radius: 50%;
        margin: auto;
      }

      .m-auto.upload-wrap img {
        border-radius: 50%;
        margin: 0;
        width: 100%;
        max-height: 180px;
        height: 180px !important;
      }
    }

    .form-label,
    .group-section-outer:empty {
      display: none !important;
    }

    .page-sidebar-head {
      border-bottom: none !important;

      span.font-bold.display-8.ml-2 {
        display: block;
        width: 100%;
        margin-left: 0 !important;
        text-align: center;
        font-size: 18px !important;
        // margin-top: 27px;
        margin-bottom: 24px;

        &:empty {
          margin: 0 !important;
        }
      }

      svg,
      img {
        display: none;
      }
    }

    &.direct-message-callout,
    &.pwa-account-callout,
    &.gil-conversation-callout {
      #root:not(.chat-page-main) .header-unpin ~ .px-0.h-100.container-fluid & {
        top: 20px !important;

        .page-sidebar-inner {
          min-height: calc(100vh - 20px);
        }
        .message-box-view {
          height: calc(100vh - 265px);
        }
      }

      .page-sidebar-inner {
        min-height: calc(100vh - 106px);
      }
      .message-box-view {
        height: calc(100vh - 315px);
      }

      #messageList {
        position: relative;
        top: -40px;
        height: calc(100% + 40px) !important;
      }
    }
    .create-card-pwa-outer .page-sidebar-body {
      min-height: calc(100vh - 265px);
    }
  }
}

.shar-form.search-form-pwa.sidebar-form .demo-wrapper .rdw-editor-main {
  @media (max-width: $hd-tab) {
    padding-top: 8px !important;

    .DraftEditor-root {
      margin-top: 0;
    }
  }
}

.event-page-outer {
  @media (max-width: $hd-tab) {
    .article-page-inner-body {
      order: -1;
      margin-bottom: 16px;
    }

    .article-page-members {
      order: 1 !important;
      margin: auto !important;
      margin-bottom: 40px !important;
      margin-top: 20px !important;
    }

    .community-section {
      order: 1;
    }

    .article-description {
      .word-break-word {
        margin-bottom: 0 !important;
      }
    }

    .text-center.mb-42 {
      order: 1;
    }

    .article-page-inner-head {
      h1 {
        width: calc(100% - 34px);
      }

      .btn-add-event {
        margin-right: 0 !important;
      }
    }

    .community-section-inner {
      transform: translateX(-20px);
    }
  }
}

.fill-agenda {
  fill: #58687f;
}

.event-info-icons-setter .icon-agenda {
  width: 18px;
  height: 18px;
  margin-left: 4px;
}

.btn-add-event {
  padding: 8px 0;

  svg {
    margin-right: 8px;
  }

  &:hover {
    svg {
      g {
        fill: $primary;
      }
    }
  }
}

.event-page-card {
  @media (min-width: $hd-tab) {
    .px-3.pb-3.font-14 {
      .d-flex.flex-wrap.text-muted.font-semi-bold.mb-3.ml-2 {
        min-height: 97px;
        flex-direction: column;
      }
    }
  }
}

.event-page-data {
  @media (max-width: $hd-tab) {
    display: flex;
    flex-direction: column;
    // border-top: 1px solid #DBDBDB;
    margin-top: 14px;
    //padding-top: 28px;

    .mt-3.pb-4 {
      padding-bottom: 10px !important;
    }

    input {
      margin-right: 0 !important;
      // height: 50px !important;
      // padding: 20px !important;
    }

    .d-flex.align-items-center.justify-content-center {
      order: -1;
      width: 100%;
      justify-content: space-between !important;
      padding: 0 16px;
      padding-bottom: 8px;
    }

    .event-search-icon {
      right: 50px;
      width: 24px;
      display: none;
    }
  }
}

@media (max-width: $hd-tab) {
  .pwa-todo-callout {
    .mt-n4.mx-n3 {
      overflow: auto;
      height: calc(100vh - 190px);
      margin-top: 0 !important;
    }

    .page-sidebar-head {
      display: none !important;
    }
  }

  .pwa-callout-body {
    margin: 0 -16px;
    padding: 0 16px;
    height: calc(100vh - 122px);
    overflow: auto;
    margin-bottom: -16px;
    padding-bottom: 16px;

    .home-page-padding {
      padding: 0 20px 20px;
    }
  }

  .gil-pwa-callout-passport {
    .page-sidebar-head {
      padding: 0 !important;
    }
    .page-sidebar-body {
      overflow: auto;
      height: calc(100vh - 105px);
    }
  }

  .pwa-callout-decorator-sec {
    position: fixed;
    width: 100%;
    background: #fff;
    top: 70px;
    border-radius: 15px 15px 0 0;
    z-index: 10000;
    left: 0;
    transition: 0.3s ease-in-out;

    .night-mode & {
      top: 72px;
    }

    + .page-sidebar-inner {
      margin-top: 37px;
      min-height: calc(100% - 37px);

      &.create-card-pwa-outer {
        height: calc(100vh - 107px) !important;
      }
    }

    + .pageSideBarTab {
      @include for-tablet-portrait-up {
        margin-top: 90px !important;
      } 
      
    }
  }

  .see-all-callout {
    .article-page-side-head {
      height: 45px;
    }
  }

  .article-page-side-body {
    max-height: calc(100vh - 158px);
  }

  .plus-icon-height.gil-pwa-callout-slideMore {
    .page-sidebar-head {
      padding: 0 !important;
      padding-top: 10px !important;
    }

    .pwa-callout-decorator-sec {
      top: auto !important;
    }
  }

  #root:not(.chat-page-main) .header-unpin {
    ~ .px-0.h-100.container-fluid {
      .page-sidebar-outer,
      .article-page-side-outer,
      .article-expandable-options.options-opened,
      .pwa-callout-decorator-sec {
        top: 20px !important;
      }

      .plus-icon-height.gil-pwa-callout-slideMore {
        &,
        & .pwa-callout-decorator-sec {
          top: auto !important;
        }
      }

      .pwa-callout-decorator-sec {
        .night-mode & {
          top: 22px !important;
        }
      }

      .chat-as-page {
        ~ .pwa-chat-outer,
        ~ .page-sidebar-outer:not(.gil-pwa-callout-slideMore) {
          .page-sidebar-outer,
          & {
            top: 70px !important;
            .pwa-callout-decorator-sec {
              top: 70px !important;
            }
          }
        }

        + .page-sidebar-outer:not(.gil-pwa-callout-slideMore) {
          top: 70px !important;
        }
      }

      .article-expandable-body .article-page-side-body-chat {
        height: calc(100vh - 203px) !important;
      }

      .pwa-callout-body {
        height: calc(100vh - 66px);
      }
    }
  }

  .pwa-callout-decorator-sec
    + .page-sidebar-inner
    .page-sidebar-head
    span.font-bold.display-8.ml-2,
  .pwa-callout-decorator-sec + .page-sidebar-inner .page-sidebar-body .px-3 h1,
  .pwa-callout-body h1,
  .edit-profile.direct-message h3,
  .notification-overflow h1,
  .page-sidebar-outer .mt-n4.mx-n3 .font-weight-bold.display-7 {
    color: $primary !important;
  }

  .pwa-todo-callout .mt-n4.mx-n3 {
    #Home-Community---Profile-Switcher {
      fill: $primary;
    }
  }
}

@media (min-width: 500px) and (max-width: $hd-tab) {
  .pwa-callout-decorator-sec {
    right: 0;
    left: auto;
    // width: 508px;
  }
}

.region-tab {
  @extend .scroll-y;
  &::-webkit-scrollbar {
    height: 5px;
  }
}
