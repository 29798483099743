.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default
}

.alert-data {
  max-width: calc(100% - 28px);
}

@keyframes slideLeft {
  0%{
    transform: translateX(30px);
  }
  100%{
    transform: translateX(0px)
  }
}

.receipt-table {
  td {
    min-width: 100px;
  }
}

.receipt-screen {
  border: 1px solid $border-color;
  border-radius: 4px;
}
