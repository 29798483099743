.product {
  &-table {

    @media (min-width: ($tab + 1)) {
      font-weight: bold;
    }

    tr {
      @media (min-width: ($tab + 1)) {
          border-bottom: 4px $border-color;
          border-bottom-style: double;

          &:first-child {
            border-top: 1px solid $border-color;
          }

          &:last-child {
              border-bottom: 1px solid $border-color;
          }
      }
      td {
        vertical-align: middle;
        border-top: none;
        padding: 8px 14px;
      }
    }
    thead {
      th, td{
        border-bottom: none;
        border-top: none;
      }
    }

    @media (max-width: $tab) {
      tbody {
        display: flex;
        flex-wrap: wrap;
      }
      thead {
        display: none;
      }
      tr {
        width: calc(50% - 10px);
        display: flex;
        flex-wrap: wrap;
        position: relative;
        border-radius: 6px;
        margin: 0 10px 10px 0;
        box-shadow: 1px 4px 15px 0 rgba(68, 68, 68, 0.3);
        padding: 5px;
      }

      td:not(.product-img) {
        margin-left: 100px;
        display: block;
        width: calc(100% - 80px);
        padding: 0 0 5px 0;
      }
      .product-img {
        position: absolute;
        width: 100px;
        left: 0;
        top: 0;
        display: block;
      }

      .product-name {
         font-size: 1.125rem;
         font-weight: bold;
      }

      .product-action {
        text-align: right;
        margin-right: 8px;
      }
    }

    @media (max-width: $mobile) {
      tr {
        width: 100%;
      }

    }

    &-pricing {
      font-weight: normal;
      .product-img-head {
        width: auto;
      }
      .product-img {
        img {
          width: 50px;
          height: 50px;
        }
      }

      .product-name {
        font-weight: bold;
      }

      tr {
        @media (min-width: ($tab + 1)) {
            border-bottom: 1px solid $border-color;
          }
      }

      @media (max-width: $tab) {
        .product-img {
          width: 100%;
        }

        .product-sku {
          margin-top: 50px;
        }

        td:not(.product-img) {
          margin-left: 70px;
          width: 100%;
        }

        td.product-action {
            position: absolute;
            right: 10px;
            display: flex !important;
            justify-content: flex-end;
            top: 10px;
        }
      }
    }
  }

  &-img {
    img {
      width: 90px;
      height: 90px;
      border-radius: 6px;
      object-fit: cover;
    }

    &-head {
      width: 120px;
    }
  }

  &-delete-icon {
    width: 18px;
  }

  &-action-icon {
    width: 20px;
  }

  &-head {
      .item-search {
        margin-left: 0px;
    }
  }
}

.product-hide-icon {
    width: 22px;
}
@media (min-width: 576px) {
  .table-view-fix {
    thead {
      th {
        &:nth-child(1),
        &:nth-child(2) {
          width: 40%;
        }

        &:nth-child(3) {
            width: 20%;
            min-width: 160px;
        }
      }
    }
  }
}
