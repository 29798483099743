.direct-message {
  .padding-top-direct {
    @include padding(90px, 0px, 0px, 0px);
    @media (max-width: $mobile) {
      padding-top: 30px;
    }
  }
  input {
    min-height: 40px;
  }
  .btn-link-invite {
    .btn-link {
      color: $primary;
    }
    .btn-link:hover {
      color: $primary;
    }
  }
  &-callout {
    z-index: 21;
    max-width: 95%;
    @media (min-width: 1500px) and (max-width: 1799px ){
      max-width: 96%;
    }
    @media (min-width: 1800px) and (max-width: 1999px ){
      max-width: 96.2%;
    }
    @media (min-width: 2000px){
      max-width: 97.2%;
    }
    @media (max-width: $hd-tab) {
      top: 150px;
      z-index: 1000;
      border-radius: 25px 25px 0 0;
      box-shadow: -1px -3px 4px rgba(0, 0, 0, 0.15);
      .page-sidebar-head {
        border: none !important;
        > svg {
          display: none;
        }
        .display-8 {
          font-size: 18px !important;
          margin-left: 0 !important;
          width: 100%;
          text-align: center;
        }
      }
    }
    .page-sidebar-body {
      padding: 0;
    }
    .message-box-view {
      height: calc(100vh - 210px);
      padding: 0;
    }
    #messageList {
      padding-right: 24px !important;
    }
  }
}
.messages {
  height: auto;
  min-height: calc(10% - 93px);
  max-height: calc(100% - 93px);
}
@media screen and (max-width: 735px) {
  .messages {
    max-height: calc(100% - 105px);
  }
}
.messages::-webkit-scrollbar {
  width: 20px;
  background: transparent;
}
.messages::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
}
.messages::-moz-scrollbar {
  width: 20px;
  background: transparent;
}
.messages::-moz-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
}
.messages ul li {
  display: inline-block;
  clear: both;
  float: left;
  margin: 15px 15px 5px 15px;
  width: calc(100% - 25px);
  font-size: 1.1em;
  &.sent {
    p {
      border-top-left-radius: 1px;
      background-color: rgba($primary, 0.15);
    }
  }
  &.replies {
    p {
      border-top-right-radius: 1px;
      background-color: #959595;
    }
  }
}
.messages ul li:nth-last-child(1) {
  margin-bottom: 20px;
}
.messages ul li.sent img {
  margin: 6px 8px 0 0;
}
.messages ul li.replies img {
  float: right;
  margin: 12px 0 0 8px;
}
.message-profile-picture {
  float: right;
  margin: 5px 0 0 8px;
}
.message-profile-sent {
  float: left;
  margin: 6px 8px 0 0;
}
.messages ul li.replies p {
  background: #f5f5f5;
  float: right;
  color: black;
  overflow-wrap: break-word;
}
.messages ul li img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
}
.messages ul li p {
  display: inline-block;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: calc(100% - 50px) !important;
  line-height: 130%;
  margin-top: 10px;
  width: 100%;
  // display: contents;
  word-break: break-word;
  @media (max-width: $hd-tab) {
    border-radius: 15px;
  }
}
@media screen and (min-width: 735px) {
  .messages ul li p {
    max-width: 300px;
  }
}
