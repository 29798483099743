.form-control {
  border: 1px solid $input-border-color;
  height: 50px;
  font-size: $input-text;
  border-radius: 0px;
  @media (max-width: $hd-tab) {
    border: 1px solid $pwa-input-border-color;
    font-size: $pwa-input-text;
  }
}

.form-control:focus {
  border: 1px solid $border-focus;
  box-shadow: none;
}

.form-control::placeholder {
  // font-family: $font-regular-italic;
  font-size: 18px;
  color: $border-color;
}

select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(../assets/images/down-arrow.svg) no-repeat right 5px center #fff;
  padding-right: 50px;
  background-size: 35px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

.pwa-form {
  input[type="text"]{
    height: $input-height;
    width: 100%;
    border-radius: $pwa-input-border-radius;
    box-shadow: $pwa-input-box-shadow;
    padding: 18px 16px;

    @media (min-width: $hd-tab) {
      border: 2px solid #D2D2D2;
    }
  }

  input[type="text"]::placeholder {
    font-size: 14px;
    color: #444;
    font-family: $font-placeholder;
  }

  &.article-page-side-input {
    input[type="text"] {
      padding-right: 90px ;
    }
  }
}

.shar-form {
  input[type="text"],
  input[type="number"],
  input[type="password"],
  input[type="email"],
  input[type="url"],
  input[type="tel"],
  select.form-control,
  textarea.form-control,
  #network-invitation .shar-form-element > div >div:first-child,
  .pwa-upload-field,
  .pwa-share-post {
    height: $input-height;
    width: 325px;
    border-radius: $input-border-radius;
    box-shadow: $input-box-shadow;
    padding: $input-padding-y $input-padding-x;

    @media (max-width: $hd-tab) {
      height: $pwa-input-height;
      border-radius: $pwa-input-border-radius;
      box-shadow: $pwa-input-box-shadow;
      padding: $pwa-input-padding-y $pwa-input-padding-x;
    }

    @media (max-width: $tab){
      width: 100%;
    }
    
  }

  textarea.form-control {
    height: 150px;
  }

  select.form-control {
    @media (max-width: $mobile) {
      padding-right: 35px;
    }
  }

  input[type="text"]::placeholder,
  input[type="number"]::placeholder,
  input[type="password"]::placeholder,
  input[type="email"]::placeholder,
  input[type="url"]::placeholder,
  input[type="tel"]::placeholder {
    font-size: $input-placeholder-text;
    color: #444;
    font-family: $font-light-italic;

    @media (max-width: $hd-tab) {
      font-size: $pwa-input-placeholder-text;
    }
  }

  select.round {
    background-image: url(../assets/images/chevron_down.svg);
    background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - .5em) .5em;
    background-size: 12px;
    background-repeat: no-repeat;

    @media (max-width: $hd-tab) {
      background-position: $pwa-select-arrow-position;
      background-size:  $pwa-select-arrow-size;
    }
  }
}

.shar-search {
  border: 1px solid $search-border;
  box-shadow: inset 0 2px 3px 0 rgba(0,0,0,0.10);
  height: 36px;
  border-radius: 1.25rem;

  &:focus {
    border: 1px solid $search-border;
  }
}
.shared-search {
  border: 1px solid $search-border;
  box-shadow: inset 0 2px 3px 0 rgba(0,0,0,0.10);
  height: 40px;
  border-radius: 10px;

  &:focus {
    border: 1px solid $search-border;
  }
}
.input-icons {
  top: 8px;
  right: 16px;
}

.dropdown-seprator {
  position: relative;
  margin-bottom: 20px;

  &:after {
    content: '';
    position: absolute;
    width: calc(100% - 24px);
    border-bottom: 1px dotted;
    left: 12px;
    bottom: -10px;
  }
}

.form-group {
  margin-bottom: $form-bottom-margin;
  @media (max-width: $hd-tab) {
    margin-bottom: $pwa-form-bottom-margin;
  }
}

.phone-country {
  top: 50%;
  transform: translateY(-50%);
  left: $input-phone-padding;

  .country-code-dropdown  .error,
  ~ .error {
    position: absolute;
    bottom: -10px;
    left: 0;
    transform: translateY(calc(100% + 25px));
    min-width: 250px;
  }
}

.country-flag {
  img {
    width: 30px;
    height: 30px;
  }
}

.country-code {
  font-size: $input-placeholder-text;
  color: #444;
  
  @media (max-width: $hd-tab) {
    font-size: $pwa-input-placeholder-text;
  }
}

.mobile-input {
  input {
    padding-left: calc(#{$input-padding-x} + 100px) !important;
   
    @media (max-width: $hd-tab) {
      padding-left: calc(#{$pwa-input-padding-x} + 100px) !important;
    }
  }

  .PhoneInputCountryIcon--border {
    box-shadow: none;
    width: auto;
    display: flex;
    align-items: center;
  }

  .PhoneInputCountry {
    position: absolute;
    border-right: 1px solid $input-border-color;
    padding-left: $input-padding-x;
    $phone-input-height: $input-padding-y * 2;
    height: calc(100% -  #{$input-padding-y});
    margin-top: $phoneCountry-margin;

    @media (max-width: $hd-tab) {
      border-right: 1px solid $pwa-input-border-color;
      padding-left: $pwa-input-padding-x;
      $phone-input-height: $pwa-input-padding-y * 2;
      height: calc(100% -  #{$pwa-input-padding-y});
    }
  }

  .PhoneInputCountryIconImg {
    min-width: 30px;
    max-width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50%;
  }

  .PhoneInputCountrySelectArrow {
    margin-left: 20px;
    font-size: 24px;
    margin-right: 16px;
  }

  .PhoneInputInput {
    border: 1px solid $input-border-color;
    font-size: $input-text;
    
    @media (max-width: $hd-tab) {
      font-size: $pwa-input-text;
      border: 1px solid $pwa-input-border-color;
    }
  }

  .error.errorIcon {
    position: absolute;
  }

  @media (max-width: 340px) {
    margin-bottom: 70px;
  }
}

.otp-input {
  margin-left: -20px;
  margin-right: -20px;
  .form-control {
    width: calc(32% - 60px) !important;
    height: calc(32% - 60px) !important;
    min-height: 65px;
    max-height: 65px;
    max-width: 65px;
    min-width: 65px;
    margin-right: 20px;
    margin-bottom: 10px;
    text-align: center;
    padding: 0 !important;

    &[name="otp4"] {
      margin-right: 0;
    }

    @media (max-width: 370px) {
      width: calc(34% - 34px) !important;
      height: calc(34% - 34px) !important;
      min-width: 58px;
      max-width: 58px;
      min-height: 58px;
      max-height: 58px;
      margin-right: 15px;
    }
  }

  &.form-group {
    margin-bottom: 14px;
  }

  + .display-9.text-center.mb-4 {
    margin-bottom: 30px !important;
  }

  ~ .display-9.text-center {
    margin-bottom: 54px !important;
  }

  .error {
    margin-left: 0;
  }
}

.bottom-text-position {
  @media (max-height: 800px) {
    position: relative !important;
    width: 100% !important;
  }
}

.profile-img-upload {
  width: 130px;
  height: 130px;
  box-shadow:  $input-box-shadow;
  background-color: $profile-upload-bg;

  @media (max-width: $hd-tab) {
    box-shadow: $pwa-input-box-shadow;
  }
.add-icon-lg {
  width: 100%;
  height: 100%;
}
.user-image-uploaded {
    border-radius: 50%;
    border: 8px solid $profile-upload-border;
    // background: $profile-upload-border;
    box-shadow: 0 12px 19px 0 rgba(60,128,209,0.09);
    width: 100%;
  }
}

.country-code-dropdown {
  select.form-control {
    border: none;
    background-position: calc(100% - 12px) calc(1em + 0px), calc(100% - 15px) calc(1em + 2px), calc(100% - .5em) 0.5em;
    padding: $padding-country-code;
    box-shadow: none;
    font-size: $input-placeholder-text;
    margin-top: 5px;
    height: auto !important;
    width: 95px !important;
    padding-right: 20px !important;

    @media (max-width: $hd-tab) {
      font-size: $pwa-input-placeholder-text;
      padding: $pwa-padding-country-code;
    }
  }
}

.error {
  &:not(:empty) {
    margin-bottom: 20px;
  }
}

.item-search {

  .form-control {
    border-radius: 1.25rem;
    padding: 8px 20px 8px 40px;
  }

  .form-control-1 {
    border-radius: 10px !important;
    padding: 6px 0px 0px 4px;
    border: none;
    background-color: transparent;
    color: #727272;
    font-size: 16px !important;
  }

  

  .icon-position {
    position: absolute;
    left: 15px;
    top: 1px;
    color: #848282;
    font-size: 20px;
  }

  .icon-position-1 {
    position: relative;
    color: #616161;;
    font-size: 20px;
  }
}

.selectbox-sm select.round.form-control {
  height: 36px;
  width: 150px;
  font-size: 14px;
  margin-top: 5px;
  background-position: calc(100% - 14px) 1em, calc(100% - 15px) calc(1em + 2px), calc(100% - .5em) 0.5em;
}

.toggle-switch.toggle-switch-sm {
  label {
    .toggle-track {
      height: 13px;
      width: 25px;

      &:before {
        height: 9px;
        width: 9px;
        top: 1px;
        right: 12px;
      }
    }
  }

  input[type="checkbox"]:checked  label .toggle-track:before {
    right: 12px;
  }
}

.form-element-width {
  max-width: 325px;
}

#network-invitation .shar-form-element > div > div:first-child{
  @media (max-width: $hd-tab) {
    margin: $pwa-sub-cat-margin;
  }
}

.form-field-width,
.shar-form.search-form-pwa .input-form-size {
  max-width: 315px;

  @media (max-width: $hd-tab) {
    max-width: 100%;
  }
}

.form-field-width,
.shar-form.search-form-pwa .search-input-callout {
  width: 100% !important;
  max-width: 100%;
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow,
.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
  color: $primary !important;
}