.round-circle {
  + .font-30-bold {
    font-size: 1.625rem;

    @media (max-width: $mobile) {
      font-size: 1.25rem;
    }
  }
}

.product-tab {
    border: none;
    .nav-link {
      font-weight: bold;
      color: $black;
      border: none;
      border-bottom: 2px solid $border-color;
      padding: 5px 40px;
      font-size: 14px;
      &:hover {
        border: none;
        border-bottom: 2px solid $border-color;
      }
      &.active {
        border: none;
        border-bottom: 2px solid $primary;
        color: $primary;
      }

      @media (max-width: $desktop) {
        width: 50%;
        margin-bottom: 20px;
        padding: 5px 20px;
      }
      @media (max-width: $mobile) {
        width: 100%;
      }
    }

    &-details {
       .font-18-bold {
          @media (max-width: $mobile) {
            font-size: 1rem;
          }
       }
    }
}

.publish-product {
  .custom-control-input {
    left: 2px;
    top: 6px;
    z-index: 1;
    cursor: pointer;
  }

  .custom-checkbox {
    margin-bottom: 8px;
  }
}

.img-product {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 6px;
}

.img-product-m {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.card-right-values {
    .form-control {
      width: 100px !important;
      font-size: 1.25rem;
      margin-left: auto;

      &:disabled {
        background: $primary;
        color: $white;
        font-weight: bold;
        border: none;
      }
    }
}

.values-group {
  .form-control {
    width: 80px !important;
    height: 30px !important;

    &::placeholder {
      font-size: 14px !important;
    }
  }
}

.transaction-labels {
  .custom-control-label::before {
    top: 2px;
  }
}

.mt-2p {
  margin-top: 2px;
}

.border-bottom-sm-0 {
  @media (min-width: ($mobile + 1)) {
    border-bottom: none !important;
  }
}

.invite-more {
  font-size: 2em;
  margin-top: -2px;
}

.invite-img {
  width: 35px;
  min-width: 35px;
  height: 35px;
  img {
    object-fit: cover;
  }
}

.invite-list {
  max-width: 350px;

  &-2 {
    max-width: 370px;
  }
}

.text-offline {
  #Campaigns-Need-Approval-C4 {
    fill: rgba(0, 0, 0, 0);
    stroke: rgb(119, 119, 119);
  }
}

.text-active,
.text-offline {
  @media (max-width: $mobile) {
    svg {
      min-width: 10px;
    }
  }
}

.product-searchbar {
  input.shar-search.form-control {
    min-height: 40px;

    &::placeholder {
      font-size: 16px;
    }
  }

  .icon-position {
    top: 4px;
  }
}

.product-table {
  font-weight: normal;

  tr {
    border-bottom: 1px #cacaca solid;
  }
  
  th {
    font-size: 18px;
    font-family: $font-bold;
  }

  .product-img img {
    width: 55px;
    height: 55px;
  }

  td.product-img.cursor-pointer {
    width: 80px;
    padding: 18px 0 18px 20px;
  }

  .btn-primary {
    min-width: 105px;
  }

  .actions-container {
    width: 160px;
  }
}

.shops-tab {
  .nav-tabs {
    border: none;

    .nav-link {
      font-size: 16px;
      color: #344356;
      border: none;
      border-bottom: 4px solid #e7e7e7;
      font-weight: 500;
      padding: 10px 36px;
      
      &.active {
        font-weight: bold;
        border-color: $primary;
      }
    }
  }
}

.shop-tag {
  &-outer {
    display: flex;
    border: 1px solid #d2d2d2;
    border-radius: 2px;
    overflow: hidden;
    margin-top: 20px;
    position: relative;
  }

  &-label {
    min-width: 320px;
    background-color: #f5f5f5;
    font-size: 16px;
    font-weight: 700;
    padding: 24px 25px;
    border-right: 1px solid #d2d2d2;

    @media (max-width: $hd-tab) {
      min-width: 40%;
      width: 40%;
    }
  }

  &-input {
    width: 100%;
    border: none;
    padding: 0 30px;
    font-size: 18px;
    color: #000;
  }

  &-thumb {
    background-color: #d8d8d8;
    width: 55px;
    height: 55px;
    border-radius: 5px;
    margin-right: 20px;
  }

  &-elements {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    left: 320px;
    padding: 0 30px;
    height: 100%;
    align-items: center;
  }

  &-color {
    padding: 14px;
    margin-right: 10px;
    border-radius: 15px;
    background-color: $primary;
    color: #fff;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-textarea {
    padding-top: 24px;
    padding-bottom: 24px;
    height: 185px;
  }

  &-info {
    padding: 12px 24px;
    border-radius: 15px;
    margin-right: 20px;
    margin-bottom: 20px !important;

    svg {
      width: 15px;
      height: 15px;
    }
  }
}

.lesson-img {
  background-image: linear-gradient(180deg, rgba(166, 166, 166, 0.16) 0%, rgba(0, 0, 0, 0.23) 100%);
  border-radius: 5px;
  width: 55px;
  height: 55px;
  overflow: hidden;
  
  img{
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  &-arrow {
    width: 30px;
    height: 30px;

    svg {
      width: 8px;
      height: 12px;
      transform: rotate(180deg);
    }
  }
}

.shop-color-picker-box {
  width: 100px;
  height: 100px;
  background-color: #dbdbdb;
  margin-bottom: 20px !important;

  .shar-color-picker {
    position: absolute;
    top: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
    opacity: 0;
  }

  .sketch-picker {
    position: absolute;
    left: 110px;
    top: 0;
    z-index: 1;
  }
}

.input-field-sm {
  .form-control {
    width: 185px !important;
    margin-right: 30px;
  }
}

.additional-field-details {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 45px;
    background: #f8f9fa;
    top: 0;
    left: -45px;
    z-index: -1;
  }
}

.bg-light-primary {
  background-color: rgba($primary, 0.15);
}

.shop-container {
  height: 100%;

  .tab-content {
    max-width: 1100px;
  }

  .shops-tab {
    height: calc(100% + 96px);
    overflow: auto;
    margin: -48px 0px 0px -48px;
    padding: 48px 48px !important;
    min-width: calc(100% + 96px);
    @extend .scroll-y;

    @media (max-width: $hd-tab) {
      padding: 16px !important;
    }
  }
}

.sub-product-callout {
  max-width: inherit;
  left: calc(25% + 42px);
  right: 0;
  width: auto;
  top: 58px;
  bottom: auto;
  height: 100%;

  .page-sidebar-body {
    > .py-4 {
      padding-top: 0 !important;

      .mt-3 {
        margin-top: 0 !important;
      }
    }
  }
}

.btn-sub-product {
  min-width: 20px; 
  box-shadow: none !important; 
  transition: 0.3s ease-in-out;

  &.btn-open {
    transform: rotate(180deg);
  }
}

.category-display {
  width: 5px;
  position: relative;
  top: 2px;
  right: -10px;
  cursor: pointer;
}

.table-subproduct{
  th:first-child, td:first-child {
    width: 75%;
  }
}

.table-attributes{
  th:first-child, td:first-child{
    width: 30%;
  }
  th:nth-child(2), td:nth-child(2) {
    width: 30%;
  }
  th:nth-child(3), td:nth-child(3) {
    width: 20%;
  }
}