.page-sidebar {
  &-outer {
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 21;
    max-width: 508px;
    transition: 0.3s ease-in-out;

    &-article {
      max-width: 544px;

      .shar-form.search-form-pwa.sidebar-form {
        #description.quill {
          height: 350px;
          background-color: #fff !important;
          max-width: 500px;
          width: 100%;
          box-sizing: border-box;
          box-shadow: 0 12px 19px 0 rgba(60, 128, 209, 0.09);
          border-radius: $pwa-input-border-radius;

          .ql-container.ql-snow {
            max-height: calc(100% - 43px);
            border-radius: 0 0 $pwa-input-border-radius $pwa-input-border-radius;

            .ql-editor {
              span,
              a {
                background-color: inherit !important;
                color: inherit !important;
              }
            }
          }

          .ql-toolbar.ql-snow {
            border-radius: $pwa-input-border-radius $pwa-input-border-radius 0 0;

            .night-mode & {
              span {
                color: inherit;
                background: #1d1d1d;
              }

              .ql-stroke {
                stroke: #f2f2f2;
              }
            }
          }

          @media (max-width: $hd-tab) {
            height: 250px;
            min-height: auto !important;
            margin-bottom: 24px;

            .ql-container.ql-snow {
              max-height: calc(100% - 66px);
            }
          }
        }
      }
    }

    @media (max-width: $hd-tab) {
      z-index: 1000;
    }
  }

  &-inner {
    box-shadow: 2px 9px 13px 0 rgba(39, 34, 98, 0.22);
    animation: slideLeft 0.3s ease-in-out;
    // padding-left: 45.2px;

    @media (max-width: $hd-tab) {
      animation-name: slideUp;
      min-height: calc(100vh - 100px);
    }

    .register-outer {
      margin-top: 0 !important;
      background: white;
      box-shadow: none;

      h3.mb-5.font-bold {
        font-size: 24px;
      }

      .error {
        margin: 0;

        &:not(:empty) {
          margin-bottom: 20px;
        }
      }

      input {
        transform: translateX(0) !important;
      }
    }
  }

  &-head {
    padding: 16px 20px;

    > .ml-auto.cursor-pointer {
      position: relative;
      right: 15px;
    }

    &-article {
      @media (max-width: $hd-tab) {
        padding: 20px;

        .create-post-head {
          padding: 0;
          width: 100%;
        }
      }
    }
  }

  &-body {
    padding: 36px;

    @media (max-width: $hd-tab) {
      padding: 16px;
    }

    .error {
      &:not(:empty) {
        margin-left: 10px;
        margin-top: -7px;
      }

      &:empty {
        margin-top: 0;
      }

      + .d-inline-block.text-grey.text-left.pl-18 {
        margin: -15px 0 20px 7px;
      }
    }

    &-article {
      padding: 16px 20px;
    }

    .PhoneInputCountryIcon {
      width: auto;
      height: auto;
      transform: translateX(5px);
    }

    .mobile-input .error.errorIcon {
      position: relative;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &-upload {
    width: 180px;

    .upload-wrap {
      max-width: 70%;
    }

    .post-uplodad-label small {
      margin-bottom: 15px !important;
    }

    &:not(.rounded-circle) {
      .error:not(:empty) {
        background: white;
        bottom: -18px !important;

        @media (max-width: $hd-tab) {
          bottom: -12px !important;
        }
      }
    }

    &.rounded-circle {
      @media (max-width: $hd-tab) {
        margin: auto;
      }
    }
  }
}
.EditProfile-page-sidebar {
  &-outer {
    overflow-y: auto;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 19;
    max-width: 100% !important;
    width: 100% !important;
    transition: 0.3s ease-in-out;

    &-article {
      max-width: 544px;

      .shar-form.search-form-pwa.sidebar-form {
        #description.quill {
          height: 350px;
          max-width: 500px;
          width: 100%;
          background-color: #fff !important;
          box-sizing: border-box;
          box-shadow: 0 12px 19px 0 rgba(60, 128, 209, 0.09);
          border-radius: $pwa-input-border-radius;

          .ql-container.ql-snow {
            max-height: calc(100% - 43px);
            border-radius: 0 0 $pwa-input-border-radius $pwa-input-border-radius;

            .ql-editor {
              span,
              a {
                background-color: inherit !important;
                color: inherit !important;
              }
            }
          }

          .ql-toolbar.ql-snow {
            border-radius: $pwa-input-border-radius $pwa-input-border-radius 0 0;

            .night-mode & {
              span {
                color: inherit;
                background: #1d1d1d;
              }

              .ql-stroke {
                stroke: #f2f2f2;
              }
            }
          }

          @media (max-width: $hd-tab) {
            height: 250px;
            min-height: auto !important;
            margin-bottom: 24px;

            .ql-container.ql-snow {
              max-height: calc(100% - 66px);
            }
          }
        }
      }
    }

    @media (max-width: $hd-tab) {
      z-index: 1000;
    }
  }

  &-inner {
    padding-left: 60px;
    box-shadow: 2px 9px 13px 0 rgba(39, 34, 98, 0.22);
    animation: slideLeft 0.3s ease-in-out;

    @media (max-width: $hd-tab) {
      animation-name: slideUp;
      min-height: calc(100vh - 100px);
    }

    .register-outer {
      margin-top: 0 !important;
      background: white;
      box-shadow: none;

      h3.mb-5.font-bold {
        font-size: 24px;
      }

      .error {
        margin: 0;

        &:not(:empty) {
          margin-bottom: 20px;
        }
      }

      input {
        transform: translateX(0) !important;
      }
    }
  }

  &-head {
    z-index: 1;
    background: white;
    top: 0;
    position: sticky;
    padding: 16px 26px;
    font-size: 16px;
    color: #23364a;
    > .ml-auto.cursor-pointer {
      position: relative;
      right: 15px;
    }

    &-article {
      @media (max-width: $hd-tab) {
        padding: 20px;

        .create-post-head {
          padding: 0;
          width: 100%;
        }
      }
    }
  }

  &-body {
    padding: 36px 36px 36px 36px;
    background-color: #f5f5f5;
    overflow-y: auto;
    @media (max-width: $hd-tab) {
      padding: 16px;
    }

    .error {
      &:not(:empty) {
        margin-left: 10px;
        margin-top: -7px;
      }

      &:empty {
        margin-top: 0;
      }

      + .d-inline-block.text-grey.text-left.pl-18 {
        margin: -15px 0 20px 7px;
      }
    }

    &-article {
      padding: 16px 20px;
    }

    .PhoneInputCountryIcon {
      width: auto;
      height: auto;
      transform: translateX(5px);
    }

    .mobile-input .error.errorIcon {
      position: relative;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &-upload {
    width: 180px;

    .upload-wrap {
      max-width: 70%;
    }

    .post-uplodad-label small {
      margin-bottom: 15px !important;
    }

    &:not(.rounded-circle) {
      .error:not(:empty) {
        background: white;
        bottom: -18px !important;

        @media (max-width: $hd-tab) {
          bottom: -12px !important;
        }
      }
    }

    &.rounded-circle {
      @media (max-width: $hd-tab) {
        margin: auto;
      }
    }
  }
  &-form-container {
    width: 60%;
    border-radius: 15px;
    background-color: #fff;
  }
}

.remove-profile-banner {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 10px 0px 10px;
  background-color: black;
  width: 28px;
  height: 28px;
  top: 0px; 
  right: 0px;
  border-radius: inherit;

  
}
.No-profile-container {
  width: 220px;
  height: 220px;
  background: linear-gradient(
    180deg,
    rgba(238, 238, 238, 1) 0%,
    rgba(216, 216, 216, 1) 100%
  );
  border: 4px solid white;
  border-radius: 50%;
}
.No-banner-Container {
  border-radius: 15px 15px 0px 0px;
  background: #d1d1d1;
  height: 300px;
  width: 100%;
}
.form-element-mb-20 {
  .form-control {
    margin-bottom: 20px !important;
  }
}

.form-element-mb-0 {
  .form-control {
    margin-bottom: 0 !important;
  }
}

.article-page-tabs.article-create-tabs {
  .tab-content {
    padding: 40px 20px;

    @media (max-width: $hd-tab) {
      padding: 20px 4px;
    }
  }

  @media (max-width: $hd-tab) {
    > nav.nav.nav-tabs {
      display: none;
    }
  }
}

.article-dropdown {
  > .dropdown-menu {
    transform: none !important;
    padding-bottom: 40px;
    min-height: 168px;
    flex-direction: column;

    &.show {
      display: flex;
    }

    .existing-article-body {
      width: calc(100% - 90px);
    }
  }

  .btn-none {
    min-height: $pwa-input-height;
    font-size: $pwa-web-placeholder-text;
    font-family: $font-medium;
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
    text-transform: inherit;
    svg {
      transition: 0.3s ease-in-out;
      width: $pwa-select-arrow-size;
    }

    &:after {
      display: none;
    }

    &[aria-expanded="true"] {
      svg {
        transform: rotate(180deg);
      }
    }

    .existing-article-outer {
      border-top: none !important;
      padding: 8px 0 !important;
      min-height: auto;
      max-width: calc(100% - 12px);
      flex: 1 1 auto;

      .existing-article-body {
        width: calc(100% - 70px);
      }

      .existing-article-img {
        width: 50px;
        height: 50px;
        min-width: 50px;
      }
    }
  }
}

.existing-article-avatar {
  .dropdown-menu & {
    &.sb-avatar.sb-avatar--text,
    &.sb-avatar__text {
      width: 67px !important;
      height: 67px !important;
    }
  }
}

.search-inside-dropdown {
  margin-top: 4px;
  margin-bottom: 30px;

  .form-control {
    margin-left: 0 !important;
    width: 100% !important;
  }
}

.existing-article {
  &-outer {
    min-height: 97px;
  }
  &-img {
    width: 67px;
    height: 67px;
    min-width: 67px;
    object-fit: cover;
    border-radius: 15px;
    box-shadow: 2px 3px 6px 0 rgba(39, 34, 98, 0.14);
  }

  &-body {
    .existing-article-heading span {
      max-width: calc(100% - 1px);

      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &-heading {
    text-transform: capitalize;
  }
}

.create-article-upload {
  width: 320px;

  .post-uplodad-label {
    &,
    .text-center {
      width: 100%;
    }
  }

  @media (max-width: $hd-tab) {
    max-width: 100%;
    width: 100%;
  }
}

.btn-post-size {
  min-width: 200px;
  min-height: 45px;
}

.sidebar-tabs-outer {
  .nav-tabs {
    width: fit-content;

    > .nav-item {
      min-width: 168px !important;
    }
  }
}

.sidebar-tabs-group-outer {
  margin-left: -20px;
  margin-top: -10px;

  .group-section-outer {
    margin: 0 -40px 0 -20px;
  }
}

.account-created {
  &-successful {
    padding: 24px 0 60px 0;
  }

  &-img {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
}

.sidebar-tabs-footer {
  @media (max-width: $hd-tab) {
    .nav-tabs {
      width: auto;

      .nav-link {
        min-width: auto !important;
        width: 50% !important;
      }
    }
  }
}

.post-pic {
  svg {
    path {
      fill: $primary;
    }
  }

  .m-auto.upload-wrap {
    max-width: 100%;

    img {
      object-fit: cover;
      border-radius: 50%;
    }
  }

  small {
    position: relative !important;
    transform: none;
    display: block;
    left: 0;
    margin-top: 10px;
  }

  &.create-article-upload {
    .m-auto.upload-wrap img {
      border-radius: 0;
      max-height: 180px;
      width: 100%;
      height: 180px;
      object-fit: cover;
    }

    .upload-thum-video.w-50.m-auto {
      width: 100% !important;
      height: 100%;
      video {
        max-height: 170px;
        width: 320px;
        object-fit: cover;
        position: relative;
        top: 5px;
        transform: none;
        left: 0;
      }
    }
  }

  .width-20 {
    display: none;
  }
}

.sidebar-form-width {
  max-width: 330px;
  margin: auto;

  .sidebar-tabs-outer {
    .nav.nav-tabs {
      width: 100%;

      .nav-link {
        width: 50%;
        max-width: 50%;
        min-width: auto !important;
      }
    }
  }

  .post-pic {
    margin: auto;
  }
}

.success-text-width {
  max-width: 270px;

  @media (max-width: $hd-tab) {
    max-width: 200px;
  }
}

.community-input-label,
.document-input-label {
  + input,
  + label.pwa-upload-field {
    @media (min-width: $hd-tab) {
      margin-left: 20px !important;
    }
  }
}
.position-sidebar-fixed {
  position: absolute;

  @media (max-width: $hd-tab) {
    position: fixed;
  }
}

.agenda-side-body {
  padding: 32px 40px 32px 50px;
}

.agenda-details {
  max-width: 360px;
  box-shadow: 2px 9px 13px 0 rgba(39, 34, 98, 0.22);
  border-radius: 15px;
  overflow: hidden;

  &-inner {
    padding: 11px 22px;
  }
}

.agenda-img {
  width: 100px;
  min-width: 100px;
  height: 100%;
  min-height: 90px;
  object-fit: cover;
}

.agenda-checked {
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
}

.cursor-pointer.ml-auto.active {
  g {
    opacity: 1;
    fill: $primary;
  }
}

.event-date-picker {
  .MuiInput-underline {
    &:after,
    &:before {
      display: none;
    }
  }
  input {
    box-sizing: border-box;
    border: 1px solid #cacaca !important;
    cursor: pointer;
    font-size: $pwa-web-placeholder-text;
    font-family: $font-medium;
    width: 100% !important;
  }
}

.mh-auto {
  min-height: auto !important;
}

.sidebar-form .sidebar-tabs-outer .nav.nav-tabs {
  width: 100%;

  .nav-link {
    width: 50%;
    max-width: 50%;
    min-width: auto !important;
  }
}

.create-call-out {
  max-width: 540px;
}
